import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
} from "@angular/core";
import { PricingService } from "../../../../services/pricing.service";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { ModalData } from "../../../modal/modal.service";
import { LineItemI, PricingOccurrence, LineItem } from "../../../../pojo/Pricing";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { RateService } from "../../../../services/rate.service";
import { S25Util } from "../../../../util/s25-util";
import { S25Datefilter } from "../../../s25-dateformat/s25.datefilter.service";
import { LangService } from "../../../../services/lang.service";
import { S25PricingUtil } from "../../s25.pricing.util";

@TypeManagerDecorator("s25-ng-modal-pricing-adjustment")
@Component({
    selector: "s25-ng-modal-pricing-adjustment",
    template: `@if (init) {
        <div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (initModal) {
                <div class="modal-body">
                    <div>
                        @if (!adjustment.pricingSuccess) {
                            <table align="center" cellpadding="2" cellspacing="2" class="ngPricingModalTable">
                                <tbody>
                                    @if (adjustment.adjustmentType === 1 || adjustment.adjustmentType === 2) {
                                        <tr>
                                            <th>{{ pricingLang.associated_item }}</th>
                                            <th>{{ itemName }}</th>
                                        </tr>
                                    }
                                    @if (adjustment.adjustmentType === 1 || adjustment.adjustmentType === 2) {
                                        <tr>
                                            <th>
                                                <label for="rate_schedule" class="ngBold">{{
                                                    pricingLang.rate_schedule
                                                }}</label>
                                            </th>
                                            <td>
                                                @if (adjustment.adjustmentType === 1) {
                                                    <select
                                                        class="cn-form__control"
                                                        id="rate_schedule"
                                                        name="rate_schedule"
                                                        [(ngModel)]="adjustment.selectedRateSchedule"
                                                    >
                                                        @for (item of adjustment.rateSchedules; track item) {
                                                            <option [ngValue]="item">
                                                                {{ item.rate_name }}
                                                            </option>
                                                        }
                                                    </select>
                                                }
                                                @if (adjustment.adjustmentType === 2) {
                                                    <div>{{ adjustment.rate_name }}</div>
                                                }
                                            </td>
                                        </tr>
                                    }
                                    @if (adjustment.adjustmentType === 1 || adjustment.adjustmentType === 2) {
                                        <tr>
                                            <th>
                                                <label for="rate_group" class="ngBold">{{
                                                    pricingLang.rate_group
                                                }}</label>
                                            </th>
                                            <td>
                                                @if (adjustment.adjustmentType === 1) {
                                                    <select
                                                        name="rate_schedule"
                                                        id="rate_schedule"
                                                        class="cn-form__control"
                                                        [(ngModel)]="adjustment.selectedRateGroup"
                                                    >
                                                        @for (item of adjustment.rateGroups; track item) {
                                                            <option [ngValue]="item">
                                                                {{ item.rate_group_name }}
                                                            </option>
                                                        }
                                                    </select>
                                                }
                                                @if (adjustment.adjustmentType === 2) {
                                                    <div>{{ adjustment.rate_group_name }}</div>
                                                }
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <th>
                                            <label for="adjustment_organization" class="ngBold">{{
                                                pricingLang.organization
                                            }}</label>
                                        </th>
                                        <td>
                                            @if (adjustment.adjustmentType === 1 || adjustment.adjustmentType === 99) {
                                                <select
                                                    name="adjustment_organization"
                                                    id="adjustment_organization"
                                                    class="cn-form__control"
                                                    [(ngModel)]="adjustment.selectedOrg"
                                                >
                                                    @for (item of adjustment.orgs; track item) {
                                                        <option [ngValue]="item">
                                                            {{ item.organization_name }}
                                                        </option>
                                                    }
                                                </select>
                                            }
                                            @if (adjustment.adjustmentType === 2) {
                                                <div>
                                                    {{ adjustment.selectedOrg.organization_name }}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    @if (adjustment.adjustmentType === 1 || adjustment.adjustmentType === 2) {
                                        <tr>
                                            <th>{{ pricingLang.list_price }}</th>
                                            <td>
                                                <div>{{ adjustment.list_price | currency }}</div>
                                            </td>
                                        </tr>
                                    }
                                    @if (adjustment.adjustmentType === 1 && showOccRow) {
                                        <tr>
                                            <th class="occ-header">Occurrence Adjustments</th>
                                            <td class="tooltip-cell">
                                                @for (occ of adjustment.occurrences; track occ) {
                                                    <div
                                                        tabindex="0"
                                                        class="tooltip-amount"
                                                        (mouseenter)="toggleTooltip(occ)"
                                                        (mouseleave)="toggleTooltip(occ)"
                                                        (focus)="toggleTooltip(occ)"
                                                        (blur)="toggleTooltip(occ)"
                                                    >
                                                        {{ occ.tableDisplay }}
                                                        @if (
                                                            occ.price - occ.listPrice !== 0 &&
                                                            !isNaN(occ.price - occ.listPrice)
                                                        ) {
                                                            <span class="c-margin-left--quarter">
                                                                <svg class="c-svgIcon">
                                                                    <title>Occurrence</title>
                                                                    <use
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                                                                    ></use>
                                                                </svg>
                                                            </span>
                                                        }
                                                    </div>
                                                }
                                                <div
                                                    class="tooltip-message"
                                                    [ngClass]="{ 'hide-tooltip': showTooltip }"
                                                >
                                                    {{ tooltipMessage }}
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <th class="ngVerticalTop">
                                            <label for="adjustment_amount" class="ngBold">{{
                                                pricingLang.adjustment
                                            }}</label>
                                        </th>
                                        <td>
                                            <div>
                                                <div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            id="adjustment_amount"
                                                            class="c-input"
                                                            [value]="adjustment.adjustmentValue"
                                                            [(ngModel)]="adjustment.adjustmentValue"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style="padding: 5px 0">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="adjustment"
                                                                value="dollarAmt"
                                                                class="ngAlignInput"
                                                                [(ngModel)]="adjustment.selectedAdjustment"
                                                            />
                                                            {{ pricingLang.dollar_amt }} ($)
                                                        </label>
                                                    </div>
                                                    <div style="padding-bottom: 10px">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="adjustment"
                                                                value="percentage"
                                                                class="ngAlignInput"
                                                                [(ngModel)]="adjustment.selectedAdjustment"
                                                            />
                                                            {{ pricingLang.percentage }} (%)
                                                        </label>
                                                    </div>
                                                </div>
                                                <label for="adjustment_reason">{{ pricingLang.reason }}:</label>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <textarea
                                                                class="c-input"
                                                                id="adjustment_reason"
                                                                style="width: 100%; height: 60px"
                                                                maxlength="40"
                                                                [(ngModel)]="adjustment.adjustment_name"
                                                                >{{ adjustment.adjustment_name }}</textarea
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    @if (errorMessage) {
                                        <tr class="error-message">
                                            {{
                                                errorMessage
                                            }}
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        }
                        @if (adjustment.pricingSuccess) {
                            <div align="center" class="c-margin-bottom--single">
                                <div>{{ pricingLang.pricing_updated }}</div>
                                <div>
                                    <button class="aw-button aw-button--primary c-margin-top--half" (click)="close()">
                                        Done
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            <div class="modal-footer">
                <div class="buttons">
                    @if (!adjustment.pricingSuccess) {
                        <button
                            class="aw-button aw-button--primary"
                            [disabled]="adjustment.saveDisabled"
                            (click)="savePricing()"
                        >
                            Save
                        </button>
                    }
                    @if (!adjustment.pricingSuccess && !adjustment.isNew && !adjustment.isNewLineAdjustment) {
                        <button
                            class="aw-button aw-button--danger--outline"
                            [disabled]="adjustment.saveDisabled"
                            (click)="deletePricing()"
                        >
                            Delete
                        </button>
                    }
                </div>
            </div>
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: `
        :host ::ng-deep select {
            width: 15em;
        }

        :host ::ng-deep table {
            border-collapse: revert;
        }

        :host ::ng-deep table tr:hover {
            background-color: unset;
        }

        :host ::ng-deep td {
            text-align: unset !important;
        }

        :host ::ng-deep .s25-ng {
            width: fit-content;
            margin: 0 auto;
        }

        :host ::ng-deep .error-message {
            color: red;
            font-size: 0.9em;
            position: absolute;
            padding: 5px;
            left: 50%;
            width: 24em;
            height: 3em;
            transform: translateX(-50%);
            text-align: center;
            text-wrap: balance;
        }

        :host ::ng-deep .error-outline {
            outline: 2px solid red;
            outline-offset: 2px;
        }

        :host ::ng-deep .modal-footer {
            margin-top: 1em;
        }

        .tooltip-cell {
            position: relative;
        }

        .tooltip-message {
            opacity: 0;
            transition:
                opacity 1s,
                color 0s;
            position: absolute;
            bottom: 15%;
            left: 10.9em;
            width: 15em;
            font-size: 0.9em;
            color: #fff;
            z-index: -1;
        }

        .tooltip-message > p {
            text-wrap: balance;
        }

        .tooltip-message.hide-tooltip {
            opacity: 1;
            background-color: #fff;
            z-index: 1;
            padding: 10px;
            border: 1px solid darkgrey;
            border-radius: 5px;
            position: absolute;
            bottom: 3.5em;
            left: -5em;
            width: 10em;
            font-size: 0.9em;
            color: #000;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .occ-header {
            width: 8em;
        }
    `,
})
export class S25ModalPricingAdjustmentComponent implements OnInit {
    @Input() data: ModalData & { adjustment: LineItemI & PricingOccurrence };

    init: boolean;
    initModal: boolean;
    pricingLang: any;
    eventData: any;
    itemName: string;
    origOrgId: number;
    newPricingData: any;
    showTooltip: boolean;
    tooltipMessage: string;
    showOccRow: boolean;
    errorMessage: string | null = null;
    adjustment: LineItemI & PricingOccurrence;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    async ngOnInit() {
        this.init = true;
        this.adjustment = this.data.adjustment;
        this.cd.detectChanges();

        S25LoadingApi.init(this.elementRef.nativeElement);

        this.adjustment.list_price ??= this.adjustment.listPrice ?? 0; //default list_price
        this.adjustment.pricingSuccess = false;

        try {
            const [eventData, rateSchedules, rateGroups, langData] = await Promise.all([
                this.adjustment.evBillId
                    ? PricingService.getPricingFromPricingSetId(this.adjustment.eventId, this.adjustment.evBillId)
                    : PricingService.getPricing(this.adjustment.eventId, false),
                this.adjustment.adjustmentType === 1 &&
                    RateService.getRateSchedules(this.adjustment.bill_item_type_id, this.adjustment.bill_item_id),
                this.adjustment.adjustmentType === 1 && RateService.getRateGroups(),
                LangService.getLang(),
            ]);

            this.pricingLang = langData.div.controls["s25-evdetails_pricing"].text;

            this.eventData = eventData;

            const profileName = S25Util.profileName(this.adjustment.bill_profile_name?.toString());
            this.itemName = (this.adjustment.bill_item_name ?? "") + (profileName ? " (" + profileName + ")" : "");

            if (this.adjustment.adjustmentType === 1) {
                this.adjustment.rateSchedules = [
                    { rate_id: -1, rate_name: this.pricingLang.choose_schedule },
                    ...S25Util.array.forceArray(rateSchedules),
                ];

                const rateSchedule = this.adjustment.rateSchedules.filter((obj: any) => {
                    return parseInt(obj.rate_id) === parseInt(this.adjustment.rate_id);
                });

                this.adjustment.selectedRateSchedule =
                    (rateSchedule.length > 0 && rateSchedule[0]) || this.adjustment.rateSchedules[0];

                this.adjustment.rateGroups = [
                    { rate_group_id: -1, rate_group_name: this.pricingLang.choose_group },
                    ...S25Util.array.forceArray(rateGroups),
                ];

                const rateGroup = this.adjustment.rateGroups.filter((obj: any) => {
                    return parseInt(obj.rate_group_id) === parseInt(this.adjustment.rate_group_id);
                });

                this.adjustment.selectedRateGroup =
                    (rateGroup.length > 0 && rateGroup[0]) || this.adjustment.rateGroups[0];
                this.adjustment.rateGroups = this.adjustment.rateGroups.filter((obj: any) => {
                    return obj.defn_state != 0 || obj.rate_group_id == this.adjustment.selectedRateGroup.rate_group_id;
                });

                if (this.adjustment.occurrences?.length > 0) {
                    S25PricingUtil.formatOccDates(this.adjustment.occurrences, this.adjustment.allOccurrences);

                    let occAdjustments = 0;
                    this.adjustment.occurrences.map((occ: LineItem) => {
                        occAdjustments += !occ.listPrice ? 0 : occ.price - occ.listPrice;

                        if (occ.adjustmentAmt) {
                            occ.tableDisplay = S25PricingUtil.formatCurrency(occ.adjustmentAmt);
                        } else if (occ.adjustmentPercent && occ.price - occ.listPrice > 0) {
                            occ.tableDisplay = `${occ.adjustmentPercent}.00% (${S25PricingUtil.formatCurrency(
                                occ.price - occ.listPrice,
                            )})`;
                        }

                        return occ;
                    });

                    this.showOccRow = occAdjustments !== 0;
                }
            }

            this.adjustment.orgs = [
                { organization_id: -1, organization_name: this.pricingLang.choose_org },
                ...S25Util.array.forceArray(S25Util.propertyGet(eventData, "organization")),
            ];

            const org = this.adjustment.orgs.filter((obj: any) => {
                return parseInt(obj.organization_id) === S25Util.parseInt(this.adjustment.charge_to_id);
            });

            this.adjustment.selectedOrg = (org.length > 0 && org[0]) || this.adjustment.orgs[0];
            this.origOrgId = parseInt(this.adjustment.selectedOrg.organization_id);

            this.adjustment.selectedAdjustment =
                S25Util.toStr(this.adjustment.adjustment_percent).length > 0 ? "percentage" : "dollarAmt";

            this.adjustment.adjustmentValue =
                this.adjustment.selectedAdjustment === "percentage"
                    ? parseFloat(this.adjustment.adjustment_percent) || 0
                    : parseFloat(this.adjustment.adjustment_amt) || 0;

            if (this.adjustment.adjustmentType === 1) {
                this.adjustment.isNewLineAdjustment =
                    !this.adjustment.adjustmentValue && !this.adjustment.adjustment_name;
            }

            this.initModal = true;

            S25LoadingApi.destroy(this.elementRef.nativeElement);

            this.cd.detectChanges();
        } catch (error) {
            S25Util.showError(error);
        }

        console.log("adjustment", this.adjustment);
    }

    close() {
        if (this.adjustment.pricingSuccess) {
            this.adjustment.onSave?.(this.newPricingData);
        }
    }

    validate() {
        const inputEl = this.elementRef.nativeElement.querySelector("#adjustment_amount");

        if (isNaN(parseFloat(this.adjustment.adjustmentValue))) {
            this.errorMessage = "Adjustments must be numeric";
        } else if (this.adjustment.selectedOrg.organization_id === -1) {
            this.errorMessage = "Please choose a valid organization";
        } else if (
            this.adjustment.selectedAdjustment === "dollarAmt" &&
            (parseFloat(this.adjustment.adjustmentValue) > 999999999999 ||
                parseFloat(this.adjustment.adjustmentValue) < -999999999999)
        ) {
            this.renderer.addClass(inputEl, "error-outline");
            this.errorMessage = "Please enter an amount between 999,999,999,999 and -999,999,999,999";
        } else if (
            this.adjustment.selectedAdjustment === "percentage" &&
            (parseFloat(this.adjustment.adjustmentValue) > 999 || parseFloat(this.adjustment.adjustmentValue) < -999)
        ) {
            this.renderer.addClass(inputEl, "error-outline");
            this.errorMessage = "Please enter a percentage between 999 and -999";
        } else if (this.adjustment.isNew && !parseFloat(this.adjustment.adjustmentValue)) {
            this.errorMessage = "Please enter a non zero adjustment value";
        } else {
            this.errorMessage = null;
        }

        this.cd.detectChanges();

        if (this.errorMessage) {
            const errorMessageEl = this.elementRef.nativeElement.querySelector("tr.error-message");
            errorMessageEl?.scrollIntoView({ behavior: "smooth" });
        }

        return this.errorMessage === null;
    }

    async deletePricing() {
        // If adjustment type is line item (1) or (2) then we just need to set adjustment value 0 to delete
        // if adjustment type is subtotal (99) then we have to actually delete the adjustment
        if (this.adjustment.adjustmentType === 1 || this.adjustment.adjustmentType === 2) {
            this.adjustment.adjustmentValue = 0;
            this.adjustment.adjustment_name = "";
            await this.savePricing();
            this.adjustment.pricingSuccess = true;
            this.close();
        }

        if (this.adjustment.adjustmentType !== 99) return; // Undefined path, only 1, 2, and 99 are allowed

        const { eventId, evBillId, bill_item_id } = this.adjustment;
        this.adjustment.saveDisabled = true;

        try {
            this.newPricingData = await PricingService.deletePricingAdjustment(eventId, evBillId, bill_item_id);
            this.adjustment.pricingSuccess = true;
            this.newPricingData.deleteId = bill_item_id;
            this.close();
        } catch (error) {
            console.error(error);
            S25Util.showError(error, this.pricingLang.save_problem);
        }

        this.adjustment.saveDisabled = false;
    }

    async savePricing() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        const {
            adjustmentValue,
            selectedAdjustment,
            bill_item_id,
            eventId,
            adjustment_name,
            isNew,
            evBillId,
            selectedOrg,
        } = this.adjustment;

        const rateGroupId = this.adjustment.selectedRateGroup?.rate_group_id,
            rateScheduleId = this.adjustment.selectedRateSchedule?.rate_id,
            orgId = parseInt(selectedOrg.organization_id);

        if (this.validate()) {
            this.adjustment.saveDisabled = true;
            let pricingSuccess = true;

            if (this.adjustment.adjustmentType === 1) {
                //SubmitPricing_line_item
                try {
                    this.newPricingData = await PricingService.putPricingLineItem(
                        this.eventData,
                        this.adjustment,
                        rateGroupId,
                        rateScheduleId,
                        adjustmentValue ?? 0,
                        selectedAdjustment,
                        this.adjustment.selectedOrg,
                        eventId,
                        adjustment_name,
                        null,
                        null,
                        evBillId,
                    );
                    this.newPricingData.newChargeToId = this.adjustment.selectedOrg.organization_id;
                } catch (error) {
                    console.error(error);
                    pricingSuccess = false;
                }
            } else if (this.adjustment.adjustmentType === 2) {
                // reservation adjustment
                try {
                    this.newPricingData = await PricingService.putPricingReservationItem(
                        eventId,
                        this.adjustment.profileId,
                        this.adjustment.rsrvId,
                        bill_item_id,
                        this.adjustment.bill_item_type_id,
                        selectedAdjustment,
                        adjustmentValue,
                        adjustment_name,
                        evBillId,
                        isNew,
                    );
                    this.newPricingData.newChargeToId = this.adjustment.selectedOrg.organization_id;
                } catch (error) {
                    console.error(error);
                    pricingSuccess = false;
                }
            } else if (this.adjustment.adjustmentType === 99) {
                //SubmitPricing_event (subtotal edit)

                try {
                    this.newPricingData = await PricingService.putPricingSubtotalItem(
                        this.eventData,
                        orgId,
                        isNew,
                        adjustmentValue ?? 0,
                        selectedAdjustment,
                        bill_item_id,
                        eventId,
                        adjustment_name,
                        evBillId,
                    );

                    this.newPricingData.newChargeToId = this.adjustment.selectedOrg.organization_id;
                    // this.newPricingData.billItemId = bill_item_id;
                    this.newPricingData.isNew = isNew;
                } catch (error) {
                    console.error(error);
                    pricingSuccess = false;
                }
            }

            this.adjustment.pricingSuccess = pricingSuccess;
            this.adjustment.saveDisabled = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);

            this.cd.detectChanges();

            if (!pricingSuccess) alert(this.pricingLang.save_problem);
        }
    }

    toggleTooltip(occ: LineItem) {
        this.tooltipMessage = !this.showTooltip ? occ.occurrence : "";
        this.showTooltip = !this.showTooltip;

        this.cd.detectChanges();
    }

    protected readonly S25Datefilter = S25Datefilter;
    protected readonly isNaN = isNaN;
}
