import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";
import { FormatService } from "../../services/format.service";
import {
    AccountOccurrenceSubtotal,
    AccountProfileSubTotal,
    AccountSubTotal,
    Invoice,
    LineItem,
    LineItemI,
    PricingOccurrence,
    UpdateData,
} from "../../pojo/Pricing";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { ListGeneratorService } from "../../services/list.generator.service";

export class S25PricingUtil {
    public static PricingConst = {
        cols: [
            { name: "Reference", prefname: "reference", sortable: 1, isDefaultVisible: 1 },
            { name: "Item", prefname: "item", sortable: 1, isDefaultVisible: 1 },
            { name: "List Price", prefname: "listPrice", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Adjustments", prefname: "adjust", sortable: 1, isDefaultVisible: 1, templateType: 12 },
            { name: "Price", prefname: "price", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Taxes", prefname: "tax", sortable: 1, isDefaultVisible: 1, templateType: 15 },
            { name: "Total", prefname: "total", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Invoice To", prefname: "invoice", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Charge To", prefname: "charge", sortable: 1, isDefaultVisible: 1, templateType: 13 },
            { name: "Rate Schedule", prefname: "schedule", sortable: 1, isDefaultVisible: 1 },
            { name: "Rate Group", prefname: "group", sortable: 1, isDefaultVisible: 1, templateType: 14 },
            { name: "Debit Account", prefname: "debit", sortable: 1, isDefaultVisible: 1 },
            { name: "Credit Account", prefname: "credit", sortable: 1, isDefaultVisible: 1 },
        ],
    };

    public static billItemOrgFilter(orgId: any) {
        return function (billItem: any) {
            return parseInt(billItem.charge_to_id) === parseInt(orgId);
        };
    }

    public static getBillItemsByOrg(eventPricingData: any, orgId: any) {
        orgId = parseInt(orgId);
        return (S25Util.propertyGet(eventPricingData, "bill_item") || []).filter(
            S25PricingUtil.billItemOrgFilter(orgId),
        );
    }

    public static onlyItemLines(obj: any) {
        return parseInt(obj.bill_item_type_id) > 0;
    }

    public static onlySubtotalAdj(obj: any) {
        return parseInt(obj.bill_item_type_id) === -1 && parseInt(obj.ev_dt_profile_id) === -2;
    }

    public static itemAdjustmentPercToAmt(obj: any) {
        return (parseFloat(obj.adjustment_percent) || 0) * (parseFloat(obj.list_price) || 0);
    }

    public static agg: any = {
        listPrice: function (allBillItems: any) {
            return S25Util.array.sum(allBillItems.filter(S25PricingUtil.onlyItemLines), "list_price");
        },
        adjustments: {
            subtotal: function (allBillItems: any) {
                //how much was adjusted out of list price (eg, discount)
                return (
                    S25Util.array.sum(allBillItems.filter(S25PricingUtil.onlyItemLines), "adjustment_amt") +
                    S25Util.array.sum(
                        allBillItems.filter(S25PricingUtil.onlyItemLines).map(S25PricingUtil.itemAdjustmentPercToAmt),
                    )
                );
            },
            total: function (allBillItems: any) {
                return (
                    S25PricingUtil.agg.adjustments.subtotal(allBillItems) +
                    S25Util.array.sum(allBillItems.filter(S25PricingUtil.onlySubtotalAdj), "total_charge")
                );
            },
        },
        adjustedPrice: {
            subtotal: function (allBillItems: any) {
                return (
                    S25PricingUtil.agg.adjustments.subtotal(allBillItems) + S25PricingUtil.agg.listPrice(allBillItems)
                );
            },
            total: function (allBillItems: any) {
                return S25PricingUtil.agg.adjustments.total(allBillItems) + S25PricingUtil.agg.listPrice(allBillItems);
            },
        },
        taxes: {
            subtotal: function (allBillItems: any): number {
                return S25Util.array.sum(allBillItems.filter(S25PricingUtil.onlyItemLines), "total_tax");
            },
            total: function (allBillItems: any) {
                return (
                    S25PricingUtil.agg.taxes.subtotal(allBillItems) +
                    S25Util.array.sum(allBillItems.filter(S25PricingUtil.onlySubtotalAdj), "total_tax")
                );
            },
            totalByTaxId: function (allBillItems: any, taxesMap: any) {
                let taxIds = S25Util.propertyGetAllUnique(allBillItems, "tax_id");
                return taxIds.map(function (taxId: any) {
                    taxId = parseInt(taxId);
                    let taxName = taxesMap[taxId];
                    let taxBillItems: any[] = [];

                    jSith.forEach(allBillItems, function (_, billItem) {
                        jSith.forEach(billItem.tax, function (_, tax) {
                            if (parseInt(tax.tax_id) === taxId) {
                                let taxItem: any = {};
                                taxItem = S25Util.copy(taxItem, billItem);
                                taxName = tax.tax_name || taxName;
                                taxItem.total_tax = parseFloat(tax.tax_charge || 0);
                                taxBillItems.push(taxItem);
                            }
                        });
                    });

                    let totalTaxCharge = S25PricingUtil.agg.taxes.total(taxBillItems);
                    return {
                        tax_id: taxId,
                        tax_charge: totalTaxCharge,
                        itemValue: FormatService.toDollars(totalTaxCharge),
                        itemName: taxName,
                    };
                });
            },
        },
        totalCharge: {
            subtotal: function (allBillItems: any) {
                return (
                    S25PricingUtil.agg.adjustedPrice.subtotal(allBillItems) +
                    S25PricingUtil.agg.taxes.subtotal(allBillItems)
                );
            },
            total: function (allBillItems: any) {
                return (
                    S25PricingUtil.agg.adjustedPrice.total(allBillItems) + S25PricingUtil.agg.taxes.total(allBillItems)
                );
            },
        },
    };

    public static getSubTotalObject(org: any, billItems: any) {
        return {
            charge_to_name: org.organization_name,
            charge_to_id: org.organization_id,
            list_price: S25PricingUtil.agg.listPrice(billItems),
            adjustment_amt: S25PricingUtil.agg.adjustments.subtotal(billItems),
            taxable_amt: S25PricingUtil.agg.adjustedPrice.subtotal(billItems),
            total_tax: S25PricingUtil.agg.taxes.subtotal(billItems),
            total_charge: S25PricingUtil.agg.totalCharge.subtotal(billItems),
        };
    }

    //take event data, and other data, and form model used by s25-list
    public static getPricingOrganizationListModelData(
        eventPricingData: any,
        eventId: number,
        orgId: number,
        rateGroups: any,
        taxesMap: any,
        canEditPricing: boolean,
    ) {
        var eventData: any = {};
        eventData = S25Util.copy(eventData, eventPricingData); //copy of data used for puts
        delete eventData.orgToEvents; //remove orgToEvents from eventData since it is not needed for puts

        var allOrgs = S25Util.propertyGet(eventPricingData, "organization") || [];
        allOrgs = allOrgs.filter(function (org: any) {
            var orgId = parseInt(org.organization_id);
            return (
                !eventPricingData.orgToEvents ||
                (eventPricingData.orgToEvents[orgId] && eventPricingData.orgToEvents[orgId][eventId])
            );
        });

        var ret: any = {
            canEditPricing: canEditPricing,
            canEdit: true,
            canAdjust: true,
            orgId: orgId,
            eventId: eventId,
            evBillId: eventPricingData.evBillId,
            rateGroups: rateGroups,
            taxesMap: taxesMap,
            allOrgs: allOrgs,
            rows: [],
            allBillItems: [],
            eventData: eventData, //used for puts
        };

        jSith.forEach(S25PricingUtil.getBillItemsByOrg(eventPricingData, orgId), function (_, billItem) {
            S25Util.merge(billItem, {
                canEdit: ret.canEdit,
                canEditPricing: ret.canEditPricing,
                canAdjust: ret.canAdjust,
                eventId: billItem.eventId || ret.eventId,
                evBillId: billItem.evBillId || ret.evBillId,
                eventLocator: billItem.eventLocator || eventPricingData.event_locator,
            });

            if (billItem.bill_item_id > 0 && billItem.bill_item_type_id > 0) {
                ret.rows.push(billItem);
            }
            ret.allBillItems.push(billItem);
        });
        return ret;
    }

    public static getPricingOccurrenceList(listData: any) {
        let ret: any = [];
        const resMap: any = {};

        let newRows = listData.rows.filter((row: LineItemI) => {
            row.occurrences?.forEach((occ: LineItem) => {
                const {
                    bill_item_id,
                    bill_item_name,
                    bill_item_type_id,
                    canAdjust,
                    canEdit,
                    canEditPricing,
                    evBillId,
                    charge_to_id,
                    credit_account_number,
                    debit_account_number,
                    eventId,
                    eventLocator,
                    ev_dt_profile_id,
                    rate_group_name,
                    rate_id,
                    rate_name,
                } = row;

                occ = {
                    ...occ,
                    adjustment_amt: occ.adjustmentAmt,
                    adjustment_name: occ.adjustmentName,
                    adjustment_percent: occ.adjustmentPercent,
                    bill_item_name: bill_item_name ?? row.itemName,
                    bill_item_id: bill_item_id ?? row.itemId,
                    bill_item_type_id: bill_item_type_id ?? row.itemType,
                    canAdjust,
                    canEdit,
                    canEditPricing,
                    credit_account_number: credit_account_number ?? row.creditAccountNumber,
                    debit_account_number: debit_account_number ?? row.debitAccountNumber,
                    evBillId,
                    charge_to_id: charge_to_id ?? row.chargeToId,
                    eventId,
                    eventLocator: eventLocator ?? listData.locatorMap[eventId],
                    rate_group_name: rate_group_name ?? row.rateGroupName,
                    rate_id,
                    rate_name: rate_name ?? row.rateScheduleName,
                    allOrgs: listData.allOrgs,
                    rateGroups: listData.rateGroups,
                    profileId: ev_dt_profile_id,
                    eventData: listData.eventData,
                };
                if (!resMap[occ.rsrvId]) {
                    resMap[occ.rsrvId] = {
                        occurrence: occ.occurrence ?? listData.allOccurrences[occ.rsrvId]?.occurrence,
                        occView: !!listData.occView,
                        numOccurrences: row.occurrences.length,
                        listItems: [occ],
                        subtotal: listData.occSubtotals[occ.rsrvId],
                        list_price: listData.isSummary
                            ? occ.listPrice ?? 0
                            : listData.occSubtotals[occ.rsrvId]?.occurrenceListPrice,
                        charge_to_id: charge_to_id ?? row.chargeToId,
                        bill_item_id: bill_item_id ?? row.itemId,
                        eventLocator: eventLocator ?? listData.locatorMap[eventId],
                        eventData: listData.eventData,
                        taxable_amt: listData.isSummary
                            ? occ.price
                            : listData.occSubtotals[occ.rsrvId]?.occurrenceTotalCharge,
                        rsrvId: occ.rsrvId,
                        isSummary: listData.isSummary,
                    };
                } else {
                    resMap[occ.rsrvId].listItems.push(occ);
                    if (listData.isSummary) {
                        resMap[occ.rsrvId].list_price += occ.listPrice ?? 0;
                        resMap[occ.rsrvId].taxable_amt += occ.price ?? 0;
                    }
                }
            });
            const hasOccurrences = !!row.occurrences && row.occurrences.length > 0;

            if (listData.hideNoChargeItems) {
                return !hasOccurrences && !!row.total_charge;
            }

            return !hasOccurrences;
        });

        const occurrences: any = Object.values(resMap).sort(
            (a: PricingOccurrence, b: PricingOccurrence) => +new Date(a.occurrence) - +new Date(b.occurrence),
        );

        S25PricingUtil.formatOccDates(occurrences, listData.allOccurrences);

        listData.noOccItems = [...newRows];

        newRows = [...occurrences, ...newRows];

        newRows.forEach((row: LineItemI) => {
            const taxesObj: any = { tax: [] };
            let taxes = !row.occurrence && S25Util.propertyGet(row, row.tax ? "tax" : "taxes");
            jSith.forEach(taxes, (_, tax) => {
                taxesObj.tax.push({
                    itemName: tax.tax_name ?? tax.taxName ?? listData.taxesMap[tax.tax_id],
                    itemValue: FormatService.toDollars(tax.tax_charge ?? tax.taxCharge),
                });
            });

            const itemData = {
                itemName:
                    row.occurrence ??
                    ((row.bill_item_name ?? row.itemName) || "") +
                        (row.bill_profile_name && S25Util.profileName(row.bill_profile_name.toString())
                            ? " (" + S25Util.profileName(row.bill_profile_name.toString()) + ")"
                            : ""),
                reservations: row.listItems,
                charge_to_id: row.charge_to_id ?? row.chargeToId,
                bill_item_id: row.bill_item_id ?? row.itemId,
                adjustment:
                    parseFloat((row.adjustment_amt ?? row.adjustmentAmt) || 0) +
                    parseFloat((row.adjustment_percent ?? row.adjustmentPercent) || 0) * (row.taxable_amt ?? row.price),
                total_tax: row.total_tax ?? row.tax,
                total_charge: row.total_charge ?? row.total,
                dateFormat: listData.dateFormat,
                noOccItems: listData.noOccItems,
            };

            const identifier =
                row.bill_item_type_id === 1 || row.itemType === 1
                    ? "eventType"
                    : row.rsrvId ?? row.bill_item_id ?? row.itemId;
            const setNames = !!listData.idToBillName[identifier]
                ? Array.from(listData.idToBillName[identifier]).join(", ")
                : "";

            let tableRow = [
                itemData,
                row.list_price ?? row.listPrice ?? "0",
                Object.assign({}, row, {
                    adjustmentType: 1,
                    itemName:
                        parseFloat((row.adjustment_amt ?? row.adjustmentAmt) || 0) +
                        parseFloat((row.adjustment_percent ?? row.adjustmentPercent) || 0),
                    eventData: listData.eventData,
                    occView: !!listData.occView,
                    noOccItems: listData.noOccItems,
                }), //itemName used for sorting
                row.taxable_amt ?? row.price ?? "0",
                taxesObj,
                row.total_charge ?? row.taxable_amt ?? row.total ?? "0",
                S25Util.merge({ eventData: listData.eventData }, row, {
                    allOrgs: listData.allOrgs,
                }),
                row.rate_name ?? row.rateScheduleName ?? "",
                S25Util.merge({ eventData: listData.eventData }, row, {
                    rateGroups: listData.rateGroups,
                    itemName: row.rate_group_name ?? row.rateGroupName,
                    fls: listData.fls,
                    isSummary: listData.isSummary,
                }),
                row.debit_account_number ?? row.debitAccountNumber ?? "",
                row.credit_account_number ?? row.creditAccountNumber ?? "",
            ];

            listData.combineRelatedEvents && tableRow.unshift(row.eventLocator ?? listData.locatorMap[row.eventId]); // add event locator to reference column
            listData.isSummary && tableRow.splice(listData.combineRelatedEvents ? 7 : 6, 0, setNames); // Invoice To column data if payment mode/summary view

            ret.push({
                itemName: itemData.itemName, //used for initial sort
                row: tableRow,
            });
        });

        if (ret.length === 0) {
            //at least one row (an empty one) so that list still shows since footer may have data
            ret.push({ row: ["(none)", "0", "", "0", "", "0", "", "", "", "", ""] });
        }

        return ret;
    }

    //take model supplied by getPricingOrganizationListModelData and form general s25 styled list (headers, body, footer) to simulate a pricing list for a single org
    //(the output is a "getdata" function for a list)
    public static getPricingOrganizationListFn(orgModelData: any) {
        // var colsArray: any = [];
        // colsArray = S25Util.copy(colsArray, S25PricingUtil.PricingConst.cols);
        // !orgModelData.combineRelatedEvents && S25Util.array.inplaceRemoveByProp(colsArray, "prefname", "reference");
        var rowsF = function (listData: any) {
            if (orgModelData.hideNoChargeItems) {
                if (listData.isOccurrence) {
                    listData.reservations = listData.reservations.filter((row: LineItemI) => row.total);
                } else {
                    listData.rows = listData.rows.filter((row: LineItemI) => row.total_charge);
                }
            }
            //function ran by the list generator to create list rows from input data
            var ret: any[] = [];
            if (listData) {
                var eventIds = S25Util.propertyGetAllUnique(listData.allBillItems, "eventId") || [];
                jSith.forEach(listData.rows ?? listData.reservations, function (i, obj) {
                    //set data rows
                    var taxesObj: any = { tax: [], templateType: 15 };
                    let taxes = (!obj.occurrence && S25Util.propertyGet(obj, "tax")) || [];
                    jSith.forEach(taxes, function (_, tax) {
                        taxesObj.tax.push({
                            itemName: tax.tax_name || listData.taxesMap[tax.tax_id],
                            itemValue: FormatService.toDollars(tax.tax_charge),
                        });
                    });

                    var itemData = {
                        itemName:
                            (obj.bill_item_name || "") +
                            (obj.bill_profile_name && S25Util.profileName(obj.bill_profile_name.toString())
                                ? " (" + S25Util.profileName(obj.bill_profile_name.toString()) + ")"
                                : ""),
                        occurrences: obj.occurrences,
                        charge_to_id: obj.charge_to_id,
                        bill_item_id: obj.bill_item_id,
                        adjustment:
                            parseFloat(obj.adjustment_amt || 0) +
                            parseFloat(obj.adjustment_percent || 0) * obj.taxable_amt,
                        total_tax: obj.total_tax,
                        total_charge: obj.total_charge ?? obj.total,
                        dateFormat: listData.dateFormat,
                        combineRelatedEvents: listData.combineRelatedEvents,
                    };
                    var row = [];
                    orgModelData.combineRelatedEvents && row.push(obj.eventLocator);
                    row = row.concat([
                        itemData,
                        obj.list_price ?? obj.listPrice ?? "0",
                        Object.assign({}, obj, {
                            eventData: listData.eventData ?? obj.eventData,
                            eventIds: eventIds,
                            combineRelatedEvents: listData.combineRelatedEvents,
                            adjustmentType: listData.rows ? 1 : 2, //type1 for line item adjustment, type2 for reservation adjustment
                            itemName: parseFloat(obj.adjustment_amt || 0) + parseFloat(obj.adjustment_percent || 0),
                            occView: !!listData.occView,
                            allOccurrences: listData.allOccurrences,
                            isOccurrence: listData.isOccurrence,
                            noOccItems: listData.noOccItems,
                        }), //itemName used for sorting
                        obj.taxable_amt ?? obj.price ?? "0",
                        taxesObj,
                        obj.total_charge ?? obj.total ?? "0",
                        Object.assign({ eventData: listData.eventData ?? obj.eventData }, obj, {
                            templateType: 13,
                            allOrgs: listData.allOrgs ?? obj.allOrgs,
                            isOccurrence: listData.isOccurrence,
                        }),
                        obj.rate_name,
                        Object.assign({ eventData: listData.eventData ?? obj.eventData }, obj, {
                            templateType: 14,
                            rateGroups: listData.rateGroups ?? obj.rateGroups,
                            itemName: obj.rate_group_name,
                            fls: listData.fls,
                            isOccurrence: listData.isOccurrence,
                        }),
                        obj.debit_account_number,
                        obj.credit_account_number,
                    ]);

                    listData.isSummaryView && row.splice(listData.combineRelatedEvents ? 7 : 6, 0, "");

                    ret.push({
                        itemName: itemData.itemName, //used for initial sort
                        row: row,
                    });
                });
            }
            ret.sort(S25Util.shallowSort("itemName")); //initial sort by bill item name
            if (ret.length === 0) {
                //at least one row (an empty one) so that list still shows since footer may have data
                ret.push({ row: ["(none)", "0", "", "0", "", "0", "", "", "", "", ""] });
            }
            return ret;
        };

        //list footer
        var footerF = function (listData: any): any {
            const occSubtotals = Object.values(listData?.occSubtotals ?? {});
            const taxTotal = S25PricingUtil.agg.taxes.subtotal(
                listData.occView ? listData.noOccItems : listData.allBillItems,
            );

            //same as rowsF but for the footer of the list (an optional component in general)
            var ret;
            if (listData && listData.allBillItems?.length > 0) {
                var eventIds = S25Util.propertyGetAllUnique(listData.allBillItems, "eventId") || [];
                //adjustments
                var adj = new Array(eventIds.length);
                for (var i = 0; i < adj.length; i++) {
                    var eventId = eventIds[i];
                    var eventBillItems = S25Util.propertyGetParentsWithChildValue(
                        listData.allBillItems,
                        "eventId",
                        eventId,
                    );
                    var eventLocator = eventBillItems && eventBillItems[0] && eventBillItems[0].eventLocator;
                    var adjRow = [];
                    orgModelData.combineRelatedEvents && adjRow.push(eventLocator);
                    adjRow = adjRow.concat([
                        listData.occView ? "Profile Entries:" : "Adjustments:",

                        listData.occView
                            ? {
                                  type: "listPrice",
                                  value: S25PricingUtil.formatCurrency(
                                      occSubtotals.reduce(
                                          (sum: number, item: AccountOccurrenceSubtotal) =>
                                              sum + (item.occurrenceAdjustments ?? 0),
                                          0,
                                      ),
                                  ),
                              }
                            : "",

                        //subtotal adjustments (plus add NEW adjustment)
                        {
                            allBillItems: eventBillItems,
                            eventIds: eventIds ?? eventId,
                            eventData: listData.eventData,
                            eventLocator: eventLocator,
                            occView: !!listData.occView,
                            noOccItems: listData.noOccItems,
                            profileSubtotals: listData.profileSubtotals,
                            canAdjust: listData.canAdjust,
                            canEdit: listData.canEdit,
                            canEditPricing: listData.canEditPricing,
                            eventId: eventId,
                            evBillId: listData.evBillId,
                            orgId: listData.orgId,
                            combineRelatedEvents: listData.combineRelatedEvents,
                        }, //can*, has* needed for perms while editing in the pricing modal

                        "",
                        listData.occView
                            ? {
                                  type: "tax",
                                  value: S25PricingUtil.formatCurrency(
                                      S25PricingUtil.agg.taxes.subtotal(listData.allBillItems) - taxTotal,
                                  ),
                              }
                            : "",

                        //sub total charges
                        {
                            allBillItems: eventBillItems,
                            evBillId: listData.evBillId,
                            orgId: listData.orgId,
                            occView: !!listData.occView,
                            noOccItems: listData.noOccItems,
                            profileSubtotals: listData.profileSubtotals,
                            profileTax: listData.occView
                                ? S25PricingUtil.formatCurrency(
                                      S25PricingUtil.agg.taxes.subtotal(listData.allBillItems) - taxTotal,
                                  )
                                : null,
                        },

                        "",
                        "",
                        "",
                        "",
                        "",
                    ]);
                    adj[i] = { row: adjRow };
                }

                ret = [];

                //subtotals
                var subRow = [];
                orgModelData.combineRelatedEvents && subRow.push("");

                subRow = subRow.concat([
                    "Subtotal",
                    "" +
                        (S25PricingUtil.agg.listPrice(listData.occView ? listData.noOccItems : listData.allBillItems) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + (item.occurrenceListPrice ?? 0),
                                      0,
                                  )
                                : 0)), //sticker price
                    //list price is the sticker price and taxable amt is the before-tax price to the customer. The difference is thus the total adjustment
                    //note: we could filter bill_item_type_id > 0 but negative ones (totals/sub-total rows) dont have list_price or taxable_amt so we need not filter
                    FormatService.toDollars(
                        S25PricingUtil.agg.adjustments.subtotal(
                            listData.occView ? listData.noOccItems : listData.allBillItems,
                        ) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + (item.occurrenceAdjustments ?? 0),
                                      0,
                                  )
                                : 0),
                    ), //adj col
                    "" +
                        (S25PricingUtil.agg.adjustedPrice.subtotal(
                            listData.occView ? listData.noOccItems : listData.allBillItems,
                        ) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + (item.occurrenceTotalCharge ?? 0),
                                      0,
                                  )
                                : 0)), //Price col
                    FormatService.toDollars(taxTotal), //Taxes col
                    "" +
                        (S25PricingUtil.agg.totalCharge.subtotal(
                            listData.occView ? listData.noOccItems : listData.allBillItems,
                        ) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + (item.occurrenceTotalCharge ?? 0),
                                      0,
                                  )
                                : 0)), //Total col,
                    "",
                    "",
                    "",
                    "",
                    "",
                ]);

                ret.push({ row: subRow });

                //adjustments
                ret = ret.concat(adj);

                //totals
                var totalRow = [];
                orgModelData.combineRelatedEvents && totalRow.push("");
                totalRow = totalRow.concat([
                    "Total",
                    "" + S25PricingUtil.agg.listPrice(listData.allBillItems), //sticker price
                    FormatService.toDollars(S25PricingUtil.agg.adjustments.total(listData.allBillItems)), //adj
                    "" + S25PricingUtil.agg.adjustedPrice.total(listData.allBillItems), //price
                    FormatService.toDollars(S25PricingUtil.agg.taxes.total(listData.allBillItems)), //taxes
                    "" + S25PricingUtil.agg.totalCharge.total(listData.allBillItems), //total
                    listData.paymentData ?? "",
                    "",
                    "",
                    "",
                    "",
                ]);
                ret.push({ row: totalRow });
            } else {
                ret = S25PricingUtil.getSummaryFooterRows(listData.subtotals, orgModelData.combineRelatedEvents);
            }

            return ret ?? [];
        };
        return {
            getRows: rowsF,
            getFooter: footerF,
        };
    }

    public static getSummaryFooterRows(orgSubtotals: any, combineRelatedEvents: boolean) {
        let rows: any = [];
        const {
            eventsSubtotal,
            eventTypeListPrice,
            eventTypeAdjustments,
            grandTotal,
            grossAdjustments,
            occurrenceListPrice,
            occurrenceAdjustments,
            outstandingBalance,
            profileAdjustments,
            requirementsListPrice,
            requirementsAdjustments,
            tax,
            occurrenceLineItems,
            nonOccurrenceLineItems,
        } = orgSubtotals;
        const eventTypePrice = eventTypeListPrice + eventTypeAdjustments,
            occurrencePrice = occurrenceListPrice + occurrenceAdjustments,
            requirementsPrice = requirementsListPrice + requirementsAdjustments,
            eventTypesInOccs = occurrenceLineItems?.filter((item: LineItem) => item.itemType === 1) ?? [],
            requirementsInOccs = occurrenceLineItems?.filter((item: LineItem) => item.itemType === 2) ?? [],
            locsResourcesNotInOccs =
                nonOccurrenceLineItems?.filter((item: LineItem) => item.itemType === 3 || item.itemType === 4) ?? [],
            eventTypesInOccsAdjustments =
                eventTypesInOccs?.reduce((sum: number, item: LineItem) => sum + (item.adjustmentAmt ?? 0), 0) ?? 0,
            locsResourcesNotInOccsAdjustments =
                locsResourcesNotInOccs?.reduce((sum: number, item: LineItem) => sum + (item.adjustmentAmt ?? 0), 0) ??
                0,
            requirementsNotInOccsAdjustments =
                requirementsInOccs?.reduce((sum: number, item: LineItem) => sum + (item.adjustmentAmt ?? 0), 0) ?? 0,
            orgListPrice =
                eventTypeListPrice -
                (eventTypesInOccs?.reduce((sum: number, item: LineItem) => sum + (item.listPrice ?? 0), 0) ?? 0) +
                occurrenceListPrice +
                (locsResourcesNotInOccs?.reduce((sum: number, item: LineItem) => sum + (item.listPrice ?? 0), 0) ?? 0) +
                requirementsListPrice -
                (requirementsInOccs?.reduce((sum: number, item: LineItem) => sum + (item.listPrice ?? 0), 0) ?? 0),
            orgSubtotalAdjustments =
                eventTypeAdjustments -
                eventTypesInOccsAdjustments +
                occurrenceAdjustments +
                locsResourcesNotInOccsAdjustments +
                requirementsAdjustments -
                requirementsNotInOccsAdjustments,
            netTotalAdjustments = grandTotal - tax - orgListPrice;

        const subRow = [
            "Subtotal",
            orgListPrice,
            S25PricingUtil.formatCurrency(orgSubtotalAdjustments),
            eventTypePrice -
                (eventTypesInOccs?.reduce((sum: number, item: LineItem) => sum + (item.price ?? 0), 0) ?? 0) +
                occurrencePrice +
                (locsResourcesNotInOccs?.reduce((sum: number, item: LineItem) => sum + (item.price ?? 0), 0) ?? 0) +
                requirementsPrice -
                (requirementsInOccs?.reduce((sum: number, item: LineItem) => sum + (item.price ?? 0), 0) ?? 0),
            S25PricingUtil.formatCurrency(tax),
            eventsSubtotal +
                eventTypeAdjustments -
                eventTypesInOccsAdjustments -
                profileAdjustments -
                locsResourcesNotInOccsAdjustments -
                requirementsNotInOccsAdjustments,
            ...Array(6).fill(""),
        ];
        rows.push({ row: subRow });

        const lineItemAdjRow = [
            "Occurrence Line Item Adjustments",
            "",
            S25PricingUtil.formatCurrency(netTotalAdjustments - orgSubtotalAdjustments - grossAdjustments),
            "",
            "",
            netTotalAdjustments - orgSubtotalAdjustments - grossAdjustments,
            ...Array(6).fill(""),
        ];
        rows.push({ row: lineItemAdjRow });

        const eventAdjustments = [
            "Event Adjustments",
            "",
            S25PricingUtil.formatCurrency(grossAdjustments),
            "",
            "",
            grossAdjustments,
            ...Array(6).fill(""),
        ];
        rows.push({ row: eventAdjustments });

        const total = [
            "Total",
            orgListPrice,
            S25PricingUtil.formatCurrency(netTotalAdjustments),
            grandTotal - tax,
            S25PricingUtil.formatCurrency(tax),
            grandTotal,
            "",
            { remainingBalance: outstandingBalance },
            ...Array(4).fill(""),
        ];
        rows.push({ row: total });

        combineRelatedEvents &&
            rows.map((row: any) => {
                row.row.unshift("");
                return row;
            });

        return rows;
    }

    public static formatOccDates(occs: any, occData: any) {
        const occNames = occs.map((occ: any) => occ.occurrence);
        const noDupOccNames = new Set(occNames);

        occs.map((occ: any) => {
            if (
                occNames.length === noDupOccNames.size &&
                S25Datefilter.transform(occData[occ.rsrvId].rsrvStartDt, occData.dateFormat) ===
                    S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.dateFormat)
            ) {
                // all occurrence dates different - no need to specify times
                occ.occurrence = S25Datefilter.transform(
                    occ.occurrence ?? occData[occ.rsrvId].occurrence,
                    occData.dateFormat,
                );
            } else if (
                S25Datefilter.transform(occData[occ.rsrvId].rsrvStartDt, occData.dateFormat) !==
                S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.dateFormat)
            ) {
                // multi-day occurrences
                occ.occurrence = `${S25Datefilter.transform(
                    occData[occ.rsrvId].rsrvStartDt,
                    occData.dateFormat,
                )} - ${S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.dateFormat)}`;
            } else {
                // same day - different times occurrences
                occ.occurrence = `${S25Datefilter.transform(
                    occData[occ.rsrvId].rsrvStartDt,
                    occData.dateFormat,
                )} ${S25Datefilter.transform(
                    occData[occ.rsrvId].rsrvStartDt,
                    occData.timeFormat,
                )} - ${S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.timeFormat)}`;
            }

            return occ;
        });
    }
    //take model data supplied by caller and form the pricing totals list (the output is a "getdata" function for a list)
    public static getPricingTotalsListFn(modelData: any) {
        var colsArray = [
            { name: "Item", prefname: "item", sortable: 0, isDefaultVisible: 1 },
            { name: "List Price", prefname: "list_price", sortable: 0, isDefaultVisible: 1 },
            { name: "Adjustments", prefname: "adjustments", sortable: 0, isDefaultVisible: 1 },
            { name: "Price", prefname: "price", sortable: 0, isDefaultVisible: 1 },
            { name: "Taxes", prefname: "taxes", sortable: 0, isDefaultVisible: 1 },
            { name: "Total", prefname: "total", sortable: 0, isDefaultVisible: 1, isMoney: 1 },
            { name: "Charge To", prefname: "charge_to", sortable: 0, isDefaultVisible: 1 },
        ];
        var rowsF = function (listData: any) {
            var ret: any[] = [];
            if (listData) {
                jSith.forEach(listData.rows, function (_, obj) {
                    //set data rows
                    ret.push({
                        row: [
                            !obj.isAdjustment ? "Subtotals: " : "Adjustment: " + (obj.adjustment_name || ""),
                            !obj.isAdjustment ? FormatService.toDollars(obj.list_price) : "",
                            !obj.isAdjustment
                                ? FormatService.toDollars(obj.adjustment_amt)
                                : !isNaN(parseFloat(obj.adjustment_amt))
                                  ? FormatService.toDollars(obj.adjustment_amt)
                                  : !isNaN(parseFloat(obj.adjustment_percent))
                                    ? FormatService.formatPercent(obj.adjustment_percent)
                                    : "",
                            !obj.isAdjustment ? FormatService.toDollars(obj.taxable_amt) : "",
                            !obj.isAdjustment ? FormatService.toDollars(obj.total_tax) : "",
                            obj.total_charge || "0",
                            obj.charge_to_name,
                        ],
                    });
                });
            }
            ret.sort(S25Util.shallowSort("itemName"));
            return ret;
        };

        //list footer
        var footerF = function (listData: any) {
            var ret;
            if (listData && listData.rows.length > 0) {
                ret = [
                    //grand total
                    {
                        row: [
                            "Grand Total",
                            FormatService.toDollars(listData.grandListPrice),
                            FormatService.toDollars(listData.grandAdjustmentAmt),
                            FormatService.toDollars(listData.grandTaxableAmt || "0"),
                            S25Util.merge(listData.grandTaxes, {
                                templateType: 15,
                                evBillId: listData.evBillId,
                            }),
                            listData.grandTotalCharge || "0",
                            "",
                        ],
                    },
                ];
            }
            return ret;
        };
        return ListGeneratorService.s25Generate(
            null,
            colsArray,
            rowsF,
            function () {
                return jSith.when(modelData);
            },
            footerF,
        );
    }

    //take pricing data and form invoice model used for freshbooks integration, eg form "invoicesByEvent" json
    //@eventData: data from service call: PricingService.getPricingRelatedEvents
    //@orgId: org being billed for invoice
    public static formInvoiceModelFromPricing(eventData: any, orgId: number) {
        let invoicesByEvent: any = {
            events: [],
            totalOrgCharge: 0,
        };

        //sort events by start dt
        eventData && eventData.sort(S25Util.shallowSort("start_date"));

        //populate events and compute overall total charge (for this org)
        jSith.forEach(eventData, function (_, e) {
            //for each event
            let orgBillingItems = S25PricingUtil.getBillItemsByOrg(e, orgId);
            let orgTotalCharge = S25PricingUtil.agg.totalCharge.total(orgBillingItems);
            if (orgTotalCharge !== 0) {
                invoicesByEvent.totalOrgCharge += orgTotalCharge; //keep track of total charge across events in org

                //form indiv event info with org billing info
                let event: any = {
                    eventName: e.event_name,
                    eventLocator: e.event_locator,
                    eventId: e.event_id,
                    eventStartDt: e.start_date,
                    eventEndDt: e.end_date,
                    profile: [],
                    requirements: [],
                    orgAdjustments: [],
                };

                //sort profiles by init_start_dt
                e.profile = e.profile || [];
                e.profile.sort(S25Util.shallowSort("init_start_dt"));

                //separate billing items into profiles on the event
                //these line items include any line item adjustments as well
                jSith.forEach(e.profile, function (i, p) {
                    let orgProfileBilling = orgBillingItems.filter(function (obj: any) {
                        return parseInt(obj.ev_dt_profile_id) === parseInt(p.profile_id);
                    });
                    orgProfileBilling.sort(S25Util.shallowSort("bill_item_name"));
                    if (orgProfileBilling.length) {
                        event.profile.push({
                            profileName: S25Util.coalesce(
                                S25Util.profileName(p.profile_name.toString()),
                                "Segment " + (i + 1),
                            ),
                            billingItems: orgProfileBilling,
                        });
                    }
                });

                //requirement items on event (exist outside any event profile)
                event.requirements = orgBillingItems
                    .filter(function (billItem: any) {
                        var profileId = parseInt(billItem.ev_dt_profile_id);
                        var totalCharge = parseFloat(billItem.total_charge);
                        var billItemTypeId = parseInt(billItem.bill_item_type_id);
                        var listPrice = parseFloat(billItem.list_price);

                        return (
                            profileId === 0 &&
                            billItemTypeId === 2 &&
                            !isNaN(totalCharge) &&
                            !isNaN(listPrice) &&
                            listPrice !== 0
                        );
                    })
                    .sort(S25Util.shallowSort("bill_item_name"));

                //adjustments on event (exist outside any event profile)
                event.orgAdjustments = orgBillingItems
                    .filter(function (billItem: any) {
                        var profileId = parseInt(billItem.ev_dt_profile_id);
                        var totalCharge = parseFloat(billItem.total_charge);
                        var billItemTypeId = parseInt(billItem.bill_item_type_id);

                        return profileId === -2 && billItemTypeId === -1 && !isNaN(totalCharge);
                    })
                    .sort(S25Util.shallowSort("bill_item_type_name")); //these are sorted by bill item TYPE name

                invoicesByEvent.events.push(event); //append to model array of events
            }
        });

        return invoicesByEvent;
    }

    public static formatCurrency(data: any) {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });

        return formatter.format(data);
    }

    public static processTableUpdate(data: any, model: any): UpdateData {
        const { lineItems, subtotals, totals } = data.content?.data?.items[0]?.billing;
        const adjustments = data.content?.data.items[0]?.billing?.adjustments;
        const occurrences = data.content?.expandedInfo?.occurrences;
        const { canAdjust, canEdit, canEditPricing, evBillId, eventId } = model;

        let occNames: any;
        if (occurrences) {
            occNames = S25Util.fromEntries(occurrences?.map((occ: PricingOccurrence) => [occ.rsrvId, occ.occurrence]));
        }

        const itemsMap: any = {
            subtotals: subtotals[0]?.account,
            adjustments,
            taxData: {},
            combineRelatedEvents: model.combineRelatedEvents,
        };

        const eventLocatorMap: any = {};
        model.eventData.bill_item.forEach((item: LineItemI) => {
            if (item.eventId && !eventLocatorMap[item.eventId]) {
                eventLocatorMap[item.eventId] = item.eventLocator;
            } else {
                eventLocatorMap[model.eventId] = model.eventLocator;
            }
        });

        lineItems.map((item: any) => {
            const row = {
                adjustment_amt: item.adjustmentAmt,
                adjustment_percent: item.adjustmentPercent / 100,
                adjustment_name: item.adjustmentName,
                canAdjust,
                canEdit,
                canEditPricing,
                charge_to_id: item.chargeToId,
                charge_to_name: model.orgNameMap ? model.orgNameMap[item.chargeToId] : "",
                evBillId,
                originalRateGroupId: model.rate_group_id,
                credit_account_number: item.creditAccountNumber,
                debit_account_number: item.debitAccountNumber,
                bill_item_id: item.itemId,
                bill_item_name: item.itemName,
                bill_item_type_id: item.itemType,
                list_price: item.listPrice ?? 0,
                taxable_amt: item.price,
                eventId,
                noOccItems: model.noOccItems,
                ev_dt_profile_id: item.profileId,
                rate_group_id: item.rateGroupId,
                rate_group_name:
                    model.rateGroups?.find((group: any) => group.rate_group_id === item.rateGroupId)?.rate_group_name ??
                    model.rate_group_name,
                rate_id: item.rateId,
                rate_name: model.rateNameMap ? model.rateNameMap[item.rateId] : model.rate_name,
                total_tax: item.tax,
                deleteId: data.deleteId,
                occurrences: item.occurrences?.map((occ: any) => ({ ...occ, occurrence: occNames[occ.rsrvId] })),
                isNew: data.isNew,
                prevTax: model.tax,
                tax: item.taxes.map((tax: any) => {
                    const taxObj = {
                        tax_charge: tax.taxCharge,
                        tax_id: tax.taxId,
                        tax_name: tax.taxName,
                    };

                    if (itemsMap.taxData[taxObj.tax_id]) {
                        itemsMap.taxData[taxObj.tax_id].tax_charge += taxObj.tax_charge;
                        itemsMap.taxData[taxObj.tax_id].itemValue = S25PricingUtil.formatCurrency(
                            itemsMap.taxData[taxObj.tax_id].tax_charge,
                        );
                    } else {
                        itemsMap.taxData[taxObj.tax_id] = {
                            tax_id: taxObj.tax_id,
                            tax_charge: taxObj.tax_charge,
                            itemValue: S25PricingUtil.formatCurrency(taxObj.tax_charge),
                            itemName: taxObj.tax_name,
                        };
                    }

                    return taxObj;
                }),
                total_charge: item.total,
            };

            if (itemsMap[item.chargeToId]) {
                itemsMap[item.chargeToId].rows = itemsMap[item.chargeToId].rows.filter(
                    (billItem: any) =>
                        billItem.bill_item_type_id > 0 &&
                        (billItem.bill_item_id !== item.itemId || billItem.ev_dt_profile_id !== item.profileId) &&
                        billItem.charge_to_id === item.chargeToId,
                );
                itemsMap[item.chargeToId].rows.push(row);
            } else {
                itemsMap[item.chargeToId] = {
                    rows: [
                        row,
                        ...model.eventData.bill_item.filter((billItem: any) => {
                            billItem.canAdjust = canAdjust;
                            billItem.canEdit = canEdit;
                            billItem.canEditPricing = canEditPricing;
                            billItem.evBillId = evBillId;

                            return (
                                eventLocatorMap[billItem.eventId] &&
                                (billItem.bill_item_id !== item.itemId ||
                                    billItem.ev_dt_profile_id !== item.profileId) &&
                                billItem.charge_to_id === item.chargeToId &&
                                billItem.bill_item_type_id > 0
                            );
                        }),
                    ],
                    footerData: {},
                };
            }

            return row;
        });

        subtotals[0].accountOccurrence?.map((occ: any) => {
            if (!itemsMap[occ.chargeToId].occSubtotals) {
                itemsMap[occ.chargeToId].occSubtotals = { [occ.rsrvId]: occ };
            } else {
                itemsMap[occ.chargeToId].occSubtotals[occ.rsrvId] = occ;
            }
        });

        let index: number;

        if (model.occView && !model.isOccurrence && !model.allBillItems) {
            // occurrences updated, but non-occurrence line Items have to be updated if adjusted
            let changedItem = model.noOccItems.find((item: LineItemI, i: number) => {
                index = i;
                return item.bill_item_id === model.bill_item_id;
            });
            const newValue = lineItems.find((item: LineItem) => item.itemId === changedItem.bill_item_id);

            changedItem = {
                ...changedItem,
                adjustment_amt: newValue.adjustmentAmt,
                adjustment_percent: newValue.adjustmentPercent,
                adjustment_name: newValue.adjustmentName,
                tax: newValue.taxes,
                taxable_amt: newValue.price,
                list_price: newValue.listPrice,
                total_charge: newValue.total,
            };
            model.noOccItems[index] = changedItem;
        }

        const nonOccPrice =
            model.occView &&
            model.noOccItems.reduce((sum: number, item: LineItemI) => sum + (item.taxable_amt ?? 0), 0);
        const filteredTax =
            model.occView && model.noOccItems.reduce((sum: number, item: LineItemI) => sum + (item.total_tax ?? 0), 0);

        subtotals[0].account.map((item: any) => {
            const data = {
                subtotal: {
                    id: item.chargeToId,
                    row: [
                        "Subtotal",
                        (
                            item.requirementsListPrice +
                            item.occurrenceListPrice +
                            (model.occView ? 0 : item.occurrenceAdjustments)
                        ).toString(),
                        S25PricingUtil.formatCurrency(
                            model.occView
                                ? item.occurrenceAdjustments + S25PricingUtil.agg.adjustments.subtotal(model.noOccItems)
                                : item.profileAdjustments + item.requirementsAdjustments,
                        ),
                        (model.occView ? nonOccPrice + item.occurrenceTotalCharge : item.taxableAmount) //     ? item.occurrenceTotalCharge + item.requirementsTotalCharge + noOccTotalCharge // (model.occView
                            ?.toString(),
                        S25PricingUtil.formatCurrency(model.occView ? filteredTax : item.tax),
                        (model.occView
                            ? nonOccPrice + item.occurrenceTotalCharge + filteredTax
                            : item.eventsTotalCharge
                        ).toString(),
                        ...Array(5).fill(""),
                    ],
                },
                total: {
                    id: item.chargeToId,
                    row: [
                        "Total",
                        (item.occurrenceListPrice + item.requirementsListPrice + item.occurrenceAdjustments).toString(),
                        S25PricingUtil.formatCurrency(
                            item.profileAdjustments + item.grossAdjustments + item.requirementsAdjustments,
                        ),
                        (item.taxableAmount + item.grossAdjustments).toString(),
                        S25PricingUtil.formatCurrency(item.tax),
                        item.grandTotal.toString(),
                        ...Array(5).fill(""),
                    ],
                },
                adjustments: S25PricingUtil.getAdjustmentRows(model, item, adjustments, subtotals, eventLocatorMap),
            };

            if (model.combineRelatedEvents) {
                data.total.row.unshift("");
                data.subtotal.row.unshift("");
            }
            if (itemsMap[item.chargeToId]) itemsMap[item.chargeToId].footerData = data;
            return data;
        });

        if (!itemsMap.totals) itemsMap.totals = totals;
        itemsMap.bill_item_id = model.bill_item_id;
        itemsMap.adjustmentId = data.billItemId;
        itemsMap.orgId = model.orgId ?? model.charge_to_id;
        itemsMap.newChargeToId = data.newChargeToId;
        itemsMap.profileSubtotals = subtotals[0].accountProfile;

        return itemsMap;
    }

    public static getAdjustmentRows(model: any, lineItem: any, adjustments: any, subtotals: any, eventLocatorMap: any) {
        const { canAdjust, canEdit, canEditPricing, evBillId } = model;

        const adjustmentsMap: any = {};

        if (adjustments) {
            adjustments.map((adjustment: any) => {
                const adjustmentItem = {
                    ...model,
                    adjustment_amt: adjustment.adjustmentAmt,
                    adjustment_percent: adjustment.adjustmentPercent / 100,
                    adjustment_name: adjustment.adjustmentName,
                    charge_to_id: adjustment.chargeToId,
                    charge_to_name: model.charge_to_name,
                    bill_item_id: adjustment.itemId,
                    bill_item_type_id: -1,
                    total_charge: adjustment.totalCharge,
                    isAdjustment: true,
                };
                if (!adjustmentsMap[adjustment.chargeToId]) {
                    adjustmentsMap[adjustment.chargeToId] = [adjustmentItem];
                } else {
                    adjustmentsMap[adjustment.chargeToId].push(adjustmentItem);
                }
            });
        } else {
            adjustmentsMap[lineItem.chargeToId] = model.eventData.bill_item.filter((billItem: any) => {
                billItem.canAdjust = canAdjust;
                billItem.canEdit = canEdit;
                billItem.canEditPricing = canEditPricing;
                billItem.evBillId = evBillId;

                return eventLocatorMap[billItem.eventId];
            });
        }
        let data: any = [];
        const eventsArr = model.eventIds?.length ? model.eventIds : [model.eventId];
        eventsArr.forEach((eventId: any) => {
            let adjRow = [];
            model.combineRelatedEvents && adjRow.push(eventLocatorMap[eventId]);

            const allBillItems =
                eventId === model.eventId
                    ? adjustmentsMap[lineItem.chargeToId]?.filter((adj: any) => adj.eventId === eventId)
                    : model.eventData.bill_item.filter((item: any) => item.eventId === eventId);

            const filteredTax =
                model.occView &&
                model.noOccItems.reduce((sum: number, item: LineItemI) => sum + (item.total_tax ?? 0), 0);

            const profileSubtotals =
                subtotals[0].accountProfile?.filter(
                    (profile: AccountProfileSubTotal) => profile.chargeToId === (model.charge_to_id ?? model.orgId),
                ) ?? [];

            const taxDiff =
                model.occView &&
                S25PricingUtil.formatCurrency(
                    subtotals[0].account.find(
                        (profile: AccountSubTotal) => profile.chargeToId === (model.charge_to_id ?? model.orgId),
                    ).tax - filteredTax,
                );

            const rowData = [
                model.occView ? "Profile Entries:" : "Adjustments:",
                model.occView
                    ? {
                          type: "listPrice",
                          value: S25PricingUtil.formatCurrency(
                              profileSubtotals.reduce(
                                  (sum: number, item: LineItemI) => sum + item.occurrenceAdjustments,
                                  0,
                              ),
                          ),
                      }
                    : "",
                {
                    allBillItems: allBillItems?.length > 0 ? allBillItems : model.eventData.bill_item,
                    eventData: model.eventData,
                    eventIds: model.eventIds,
                    eventLocator: model.eventLocator,
                    canAdjust: canAdjust,
                    canEdit: canEdit,
                    canEditPricing: canEditPricing,
                    combineRelatedEvents: model.combineRelatedEvents,
                    evBillId: evBillId,
                    eventId: +eventId,
                    orgId: lineItem.chargeToId,
                    occView: model.occView,
                    profileSubtotals: profileSubtotals,
                    isOccAdjustment: S25Util.isDefined(model.rsrvId),
                    noOccItems: model.noOccItems,
                },
                "",
                model.occView
                    ? {
                          type: "tax",
                          value: taxDiff,
                      }
                    : "",
                {
                    allBillItems: allBillItems?.length > 0 ? allBillItems : model.eventData.bill_item,
                    evBillId,
                    orgId: lineItem.chargeToId,
                    profileSubtotals: profileSubtotals,
                    profileTax: model.occView ? taxDiff : null,
                    isOccAdjustment: S25Util.isDefined(model.rsrvId),
                    occView: model.occView,
                    eventData: model.eventData,
                    noOccItems: model.noOccItems,
                },
                ...Array(5).fill(""),
            ];

            data.push({ id: lineItem.chargeToId, row: [...adjRow, ...rowData] });
        });

        return data;
    }

    public static getSummaryData(set: Invoice, rowMap: any) {
        // form subtotal data for each pricing set
        if (set?.billing?.subtotals[0]?.account) {
            set.billing.subtotals[0].account.reduce((map: any, subtotal: any) => {
                const orgId = subtotal.chargeToId;
                if (!map[orgId]) {
                    map[orgId] = {
                        ...subtotal,
                        eventTypeListPrice: 0,
                        eventTypeAdjustments: 0,
                        eventTypeTotalCharge: 0,
                        occurrenceLineItems: [],
                        nonOccurrenceLineItems: [],
                    };
                } else {
                    const notAddedAttrs = ["chargeToId", "invoiceId", "occurrenceLineItems", "nonOccurrenceLineItems"];
                    for (let item in map[orgId]) {
                        if (!notAddedAttrs.includes(item)) map[orgId][item] += subtotal[item] ?? 0;
                    }
                }
                return map;
            }, rowMap.subtotals);

            // get totals of all line items for each pricing set
            set.billing.lineItems.reduce((map: any, lineItem: any) => {
                const hasOccurrences = !!lineItem.occurrences && lineItem.occurrences.length > 0;
                // unique identifier for each type of row (ie org, has occurrences and should display in occ totals or not, and itemId specific to the itemType) - also stores data to be extracted for occurrence sorting in sortOccurrenceItems
                const row = `${lineItem.itemId}-${lineItem.itemType}-${lineItem.chargeToId}-${hasOccurrences ? "occ" : "noOcc"}`;
                const rateScheduleName = rowMap.rateSchedules?.find(
                    (rate: any) => rate.rateId === lineItem.rateId,
                )?.rateName;
                const rateGroupName = rowMap.rateGroups?.find(
                    (group: any) => group.rateGroupId === lineItem.rateGroup_id,
                )?.rateGroupName;

                if (lineItem.itemType === 1) {
                    // get event type subtotals
                    rowMap.subtotals[lineItem.chargeToId].eventTypeListPrice += lineItem.listPrice ?? 0;
                    rowMap.subtotals[lineItem.chargeToId].eventTypeAdjustments =
                        (lineItem.adjustmentAmt ?? 0) + ((lineItem.adjustmentPercent / 100) * lineItem.listPrice || 0);
                    rowMap.subtotals[lineItem.chargeToId].eventTypeTotalCharge +=
                        (lineItem.total ?? 0) + (lineItem.tax ?? 0);
                }
                lineItem.listPrice ??= 0;
                lineItem.adjustmentAmt =
                    (lineItem.adjustmentAmt ?? 0) + ((lineItem.adjustmentPercent / 100) * lineItem.listPrice || 0);
                lineItem.price ??= 0;
                lineItem.tax ??= 0;
                lineItem.total ??= 0;
                // line items with occurrence data are rendered within occurrence row expansion, those not associated with occurrences display as a separate row
                if (
                    !map[row] ||
                    (!map[row].occurrences?.length && lineItem.occurrences?.length) ||
                    (map[row].occurrences?.length && !lineItem.occurrences?.length)
                ) {
                    // for total data, just use adjustmentAmt to store the sum - no need to list each adjustment in summary
                    if (hasOccurrences) {
                        lineItem.occurrences.map((occ: any) => {
                            occ.listPrice ??= 0;
                            occ.adjustmentAmt =
                                (occ.adjustmentAmt ?? 0) + ((occ.adjustmentPercent / 100) * occ.listPrice || 0);
                            return occ;
                        });
                    }
                    lineItem.tax ??= 0;
                    map[row] = { ...lineItem, rateGroupName, rateScheduleName };
                } else {
                    map[row] = {
                        ...map[row],
                        listPrice: (map[row].listPrice ?? 0) + (lineItem.listPrice ?? 0),
                        tax: map[row].tax + lineItem.tax,
                        price: map[row].price + lineItem.price,
                        total: map[row].total + lineItem.total,
                        adjustmentAmt:
                            (map[row].adjustmentAmt ?? 0) +
                            (lineItem.adjustmentAmt ?? ((lineItem.adjustmentPercent / 100) * lineItem.listPrice || 0)),
                    };
                    if (lineItem.taxes) {
                        lineItem.taxes.forEach((tax: any) => {
                            const taxMatch = map[row]?.taxes?.find((item: any) => item.taxId === tax.taxId);
                            if (taxMatch) {
                                taxMatch.taxCharge += tax.taxCharge ?? 0;
                            } else {
                                if (!map[row].taxes) map[row].taxes = [];
                                map[row].taxes.push(tax);
                            }
                        });
                    }
                    if (hasOccurrences) {
                        lineItem.occurrences.forEach((occ: any) => {
                            const occMatch = map[row]?.occurrences?.find((item: any) => item.rsrvId === occ.rsrvId);
                            if (occMatch) {
                                occMatch.price += occ.price ?? 0;
                                occMatch.listPrice += (occ.listPrice ?? 0) || 0;
                                occMatch.total += occ.total ?? 0;
                                occMatch.adjustmentAmt =
                                    (occMatch.adjustmentAmt ?? 0) +
                                    (occ.adjustmentPercent
                                        ? (occ.adjustmentPercent / 100) * occ.listPrice
                                        : occ.adjustmentAmt ?? 0);
                            } else {
                                if (!map[row].occurrences) map[row].occurrences = [];
                                occ.adjustmentAmt =
                                    (occ.adjustmentAmt ?? 0) +
                                    ((occ.adjustmentPercent / 100) * (occ.listPrice ?? 0) || 0);
                                map[row].occurrences.push(occ);
                            }
                        });
                    }
                }
                return map;
            }, rowMap.lineItems);
        }
    }

    public static sortOccurrenceItems(invoiceData: any) {
        Object.entries(invoiceData.lineItems).forEach(([key, value]: any) => {
            // key stores data for orgId and whether the item is an occurrence or not - set in getSummaryData
            const keyData = key.split("-");
            const isOcc = keyData[3] === "occ";
            const orgId = keyData[2];

            if (isOcc) {
                invoiceData.subtotals[orgId].occurrenceLineItems.push(value);
            } else {
                invoiceData.subtotals[orgId].nonOccurrenceLineItems.push(value);
            }
        });
    }
}
