import { Table } from "../../s25-table/Table";
import Cell = Table.Cell;
import { S25Util } from "../../../util/s25-util";
import { GenericTableRenderHTMLComponent } from "../../s25-table/generics/generic.table.render.html.component";
import { MatchProfile } from "../student.housing.service";
import Column = Table.Column;

export class ProfileListUtil {
    public static getQuestionAnswerCell(profile: MatchProfile, column: Column, cell: Cell): Cell {
        let answer = profile.answers.find((a) => a.questionId === S25Util.parseInt(column.id));
        let text = "";
        if (answer?.longAnswer) {
            text = answer.longAnswer;
        } else if (answer?.answer) {
            text = `<pre>${answer.answer}</pre>`;
        }
        cell.component = GenericTableRenderHTMLComponent;
        cell.inputs = {
            isHtml: true,
            text: text,
        };
        return cell;
    }

    public static getQuestionCols(questions: any[]): Column[] {
        return questions
            .filter(
                (q) =>
                    (q.questionType === "SELECT" || q.questionType === "TEXT") && q.questionLevel === "SEARCH_COLUMN",
            )
            .map((q) => {
                return {
                    id: q.questionId + "",
                    header: q.question,
                    sortable: true,
                };
            });
    }
}
