import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    Renderer2,
    ViewContainerRef,
    ViewChild,
    EventEmitter,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25EventOccurrencesService } from "./s25.event.occurrences.service";
import { S25ProfileUtil } from "../utils/s25.profile.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { Table } from "../../s25-table/Table";
import { UserprefService } from "../../../services/userpref.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { Bind } from "../../../decorators/bind.decorator";
import { OccurrenceAdditionalDetailsComponent } from "./s25-event-occurrence/occ-table-components/occurrence-additional-details/occurrence.additional.details.component";
import { OccurrenceDetailsComponent } from "./s25-event-occurrence/occ-table-components/occurrence.details.component";
import { OccurrenceActionComponent } from "./s25-event-occurrence/occ-table-components/occurrence.action.component";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { OccurrenceSegmentComponent } from "./s25-event-occurrence/occ-table-components/occurrence.segment.component";
import { OccurrenceDateComponent } from "./s25-event-occurrence/occ-table-components/occurrence.date.component";
import { S25Reservation } from "../ReservationI";
import { S25Event } from "../EventMicroI";
import { S25EventShareDataService } from "./s25.event.shared.data.service";
import { S25ReservationUtil } from "../utils/s25.reservation.util";
import { OccurrenceTimeComponent } from "./s25-event-occurrence/occ-table-components/occurrence.time.component";

/**
 * Parent component for event occurrences view
 */
@TypeManagerDecorator("s25-ng-event-occurrences-list")
@Component({
    selector: "s25-ng-event-occurrences-list",
    template: `
        @if (init) {
            @if (occurrencesList.length > 0 && (!multiProfiles || isSeparatedView) && editMode) {
                <occurrence-set-for-all
                    #setForAll
                    [occurrencesList]="occurrencesList"
                    [dateFormat]="dateFormat"
                    [timeFormat]="timeFormat"
                    (selectedRsrv)="selectedRsrv($event)"
                >
                </occurrence-set-for-all>
            }
            <div class="occ-list-wrapper">
                <s25-ng-table
                    [dataSource]="tableConfig"
                    [columnSortable]="true"
                    [pivotThresholdColumn]="pivotThreshold"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        ::ng-deep .occ-list-wrapper .s25ngTable.pivoted td {
            align-items: flex-start;
        }

        ::ng-deep .occ-list-wrapper .s25ngTable.pivoted td.detail-cell {
            display: block !important;
        }

        ::ng-deep .occ-list-wrapper .s25ngTable .s25-ng-table--table .detail-row {
            box-shadow:
                inset 0px 11px 8px -10px #ccc,
                inset 0px -11px 8px -10px #ccc;
        }

        ::ng-deep .occ-list-wrapper .s25ngTable .s25-ng-table--table .detail-row:hover {
            background-color: unset;
        }

        ::ng-deep .occ-list-wrapper .s25ngTable .s25-ng-table--table th[col="actions"] {
            width: 240px;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventOccurrencesListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    @Input() occurrencesList: S25Reservation[];
    @Input() editMode?: boolean = false;
    @Input() isSeparatedView?: boolean;
    @Input() canEdit?: boolean;

    @Output() refreshF = new EventEmitter<void>();

    event: S25Event;

    pivotThreshold: null;
    init = false;
    dateFormat: string;
    timeFormat: string;
    columns: Table.Column[] = [
        { id: "segment", header: "Segment", sortable: true },
        { id: "date", header: "Date", sortable: true },
        { id: "start", header: "Start Time", sortable: false },
        { id: "end", header: "End Time", sortable: false },
        { id: "details", header: "Additional Details", sortable: false },
        { id: "actions", header: "Actions", sortable: false },
    ];
    tableConfig: Table.DataSource;
    cellClassName: string;
    multiProfiles: boolean = false;
    selectedItems: number[] = [];

    constructor(
        private occurrencesService: S25EventOccurrencesService,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private shareDataService: S25EventShareDataService,
    ) {}

    async ngOnInit() {
        const [dateFormat, timeFormat] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]);

        this.event = this.occurrencesService.S25Event;

        this.shareDataService.resetItems();

        //if multiProfile add/remove segment col
        const findIndex = this.columns.findIndex((column) => column.id === "segment");
        if (this.event.profile.length > 1) {
            this.multiProfiles = true;
            if (findIndex !== -1 && this.isSeparatedView) this.columns.splice(findIndex, 1);
        } else {
            if (findIndex !== -1) this.columns.splice(findIndex, 1);
        }

        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        const getData = async () => {
            let data = this.occurrencesList;
            if (data && data.length > 0)
                data = data.sort(
                    (a: any, b: any) =>
                        (S25Util.date.parse(a.eventStart) as any) - (S25Util.date.parse(b.eventStart) as any),
                ); // data order multiple profiles
            return {
                rows: data.map(this.mapToRows),
            };
        };

        this.tableConfig = {
            type: "unpaginated",
            dataSource: getData,
            columns: this.columns,
        };

        this.toggleActionsColumn(); // add/remove action col depends on editMode and canEdit
        this.init = true;
        this.cd.detectChanges();
    }

    async refresh() {
        this.init = false;
        this.cd.detectChanges();
        this.init = true;
        this.cd.detectChanges();
    }

    @Bind
    mapToRows(item: any): Table.Row {
        item.state === "cancelled" || this.event.state === 99 || this.event.state === 98
            ? (this.cellClassName = "ngStrikethrough")
            : (this.cellClassName = "");
        return {
            id: item.itemId,
            name: item.eventEnd, //TODO: on pivot this displays a full date object as string, consider a more human friendly name
            cells: {
                segment: {
                    component: OccurrenceSegmentComponent,
                    inputs: {
                        segmentName: this.getSegmentName(item.itemId),
                        occ: item,
                        eventState: this.event.state,
                    },
                    outputs: {
                        toggleDetailsRow: (detailsData: Table.NewRowModel) => {
                            this.handleDetailsRow(detailsData);
                        },
                    },
                    textValue: this.getSegmentName(item.itemId),
                },
                date: {
                    component: OccurrenceDateComponent,
                    inputs: {
                        occ: item,
                        eventState: this.event.state,
                        eventId: this.event.itemId,
                        canEdit: this.canEdit && this.editMode,
                        multiProfiles: this.multiProfiles,
                        isSeparatedView: this.isSeparatedView,
                    },
                    outputs: {
                        toggleDetailsRow: (detailsData: Table.NewRowModel) => {
                            this.handleDetailsRow(detailsData);
                        },
                    },
                    className: this.cellClassName,
                    textValue: S25Datefilter.transform(item.eventStart, this.dateFormat),
                },
                start: {
                    component: OccurrenceTimeComponent,
                    inputs: {
                        occ: item,
                        isEndDate: false,
                    },
                    className: this.cellClassName,
                    textValue: S25Datefilter.transform(item.eventStart, this.timeFormat),
                },
                end: {
                    component: OccurrenceTimeComponent,
                    inputs: {
                        occ: item,
                        isEndDate: true,
                    },
                    className: this.cellClassName,
                    textValue: S25Datefilter.transform(item.eventEnd, this.timeFormat),
                },
                details: {
                    component: OccurrenceDetailsComponent,
                    inputs: {
                        occ: item,
                        eventState: this.event.state,
                        eventId: this.event.itemId,
                        canEdit: this.canEdit && this.editMode,
                        selectedItems: this.selectedItems,
                    },
                    outputs: {
                        toggleDetailsRow: (detailsData: Table.NewRowModel) => {
                            this.handleDetailsRow(detailsData);
                        },
                        refresh: () => {
                            this.refreshF.emit();
                        },
                    },
                },
                actions: {
                    component: OccurrenceActionComponent,
                    inputs: {
                        selectedItems: this.selectedItems,
                        occ: item,
                        eventState: this.event.state,
                        eventId: this.event.itemId,
                        canEdit: this.canEdit && this.editMode,
                        profileId: this.getProfileId(item.itemId),
                        singleRsrv:
                            this.occurrencesList.length <= 1 ||
                            this.getProfile(this.getProfileId(item.itemId))?.reservations?.length <= 1,
                    },

                    outputs: {
                        refreshF: () => {
                            this.refreshF.emit();
                        },
                    },
                },
            },
        };
    }

    handleDetailsRow(detailsData: Table.NewRowModel) {
        let initOcc = S25Util.deepCopy(detailsData.data.occ);
        const tableEl = detailsData.table;
        if (tableEl instanceof HTMLTableElement) {
            // Check if it's an HTMLTableElement
            if (detailsData.action === "create") {
                const detailsRow = tableEl.insertRow(detailsData.rowIndex + 2);

                detailsRow.style.borderBottom = "unset";
                detailsRow.style.borderInline = "1px solid #e5e5e5";
                detailsRow.role = "listitem";
                this.renderer.addClass(detailsRow, "detail-row");

                const cell = this.renderer.createElement("td");
                cell.colSpan = this.columns.length;
                this.renderer.addClass(cell, "detail-cell");

                this.renderer.appendChild(detailsRow, cell);

                const componentRef = this.viewContainerRef.createComponent(OccurrenceAdditionalDetailsComponent);

                this.renderer.appendChild(cell, componentRef.location.nativeElement);

                componentRef.instance.occ = initOcc; //detailsData.data.occ;
                componentRef.instance.isCopy = detailsData.data.isCopy;
                componentRef.instance.canEdit = this.canEdit && this.editMode;
                componentRef.instance.eventId = this.event.itemId;
                componentRef.instance.selectedItems = this.selectedItems;
                componentRef.instance.eventState = this.event.state;
                componentRef.instance.profileId = this.getProfileId(detailsData.data.occ.itemId);
                componentRef.instance.editMode = this.editMode;
                return componentRef.instance.ngOnInit();
            } else {
                tableEl.deleteRow(detailsData.rowIndex + 2);
                detailsData.row.style.borderBottom = "1px solid #e5e5e5";
            }
        }
    }

    toggleActionsColumn() {
        const actionsIndex = this.columns.findIndex((column) => column.id === "actions");
        if (this.canEdit && this.editMode && actionsIndex === -1) {
            this.columns.push({ id: "actions", header: "Actions", sortable: false });
        } else if ((!this.canEdit || !this.editMode) && actionsIndex !== -1) {
            this.columns.splice(actionsIndex, 1);
        }
        this.cd.detectChanges();
    }

    // if profile name is Rsrv_*   display Segment #{{profile_id}}
    getSegmentName(itemId: number): string {
        const getProfile = S25ProfileUtil.getProfileByRsrvId(this.event.profile, itemId);
        if (getProfile?.itemName) {
            if (getProfile?.itemName.indexOf("Rsrv_") === -1) {
                return getProfile?.itemName;
            } else {
                return "Segment #" + getProfile?.itemId;
            }
        } else {
            return null;
        }
    }

    getProfileId(itemId: number) {
        return S25ProfileUtil.getProfileByRsrvId(this.event.profile, itemId)?.itemId;
    }

    getProfile(itemId: number) {
        return S25ProfileUtil.getProfileByProfileId(this.event.profile, itemId);
    }

    selectedRsrv(rsrvItemIds: number[]) {
        this.selectedItems = rsrvItemIds;
        this.shareDataService.upateItems(rsrvItemIds);
        this.cd.detectChanges();
    }
}
