import { BpeVariableData } from "./bpe.vars.component";
import { TableBuilder } from "./s25.table.builder.component";

export namespace BpeVarsUtil {
    export function setVariable(state: BpeVariableData, codeMode: boolean) {
        if (!state?.variable) return;

        let varStr = state.variable.val;
        state.var = null;
        state.preVar = null;

        if (state.variable.options) {
            let optionsPresent = false;
            const variable = state.variable.vars?.find((variable) => {
                for (let option of state.variable.options) {
                    if (option.substitute) continue; // Ignore substitutions here
                    optionsPresent = true;

                    if (option.type === "checkbox" || option.type === "toggle") {
                        const chosenValue = !!state.options[option.id];
                        const variableValue = !!variable.options[option.id as keyof typeof variable.options];
                        if (chosenValue !== variableValue) return false;
                    }
                }
                return true;
            });

            if (optionsPresent && !variable) {
                // No matching variable for the selected options
                return;
            }
            if (variable) varStr = variable.var;

            for (const option of state.variable.options) {
                if (!option.substitute) continue;

                const optionValue = state.options[option.id];
                let value = option.prop ? optionValue?.[option.prop] : optionValue;

                if (!value && varStr.includes(option.substitute)) return;
                varStr = varStr.replace(option.substitute, value);
            }
        }

        if (codeMode) {
            varStr = varStr.replace(/\.(-?\d+)/g, "[$1]"); // For code mode switch ".123" index to "[123]"
        }

        if (state.variable.hasFormat && state.format) {
            varStr += ` | ${state.format.val}`;
        }

        const isWs = varStr?.startsWith("ws");
        state.var = wrapVar(isWs ? varStr : `$pro.vars.${varStr}`, codeMode);
        state.preVar = isWs ? "" : wrapVar(`$pro.vars.${convertToPre(varStr)}`, codeMode);
        if (state.variable.noPre) state.preVar = null;
    }

    function convertToPre(val: string) {
        return "pre" + val.charAt(0).toUpperCase() + val.substring(1);
    }

    function wrapVar(variable: string, codeMode: boolean) {
        if (codeMode) return `${variable}`;
        return `{{${variable}}}`;
    }

    export function newVariableData() {
        return { options: {} };
    }

    export function getTableBuilderColumnsStr(table: TableBuilder.Column[], isDocument: boolean) {
        return table
            ?.filter((column) => !!column.variable?.val)
            .map((column) => {
                const { header, variable, format, width, data } = column;
                const formatString = format ? ` | ${format.val}` : "";
                const columnWidth = isDocument ? ` : ${width}` : "";
                let val = isDocument ? variable.wsVal || variable.val : variable.val;
                if (val.includes("$data")) {
                    val = val.replace(/\$data/gi, String(data?.itemId ?? data?.val));
                }

                return `${val}${formatString} : ${header}${columnWidth}`;
            })
            .join(" || ");
    }
}
