import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Proto } from "../../pojo/Proto";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25ItemGenericComponent } from "../s25-item/item-object/s25.item.generic.component";
import { Item } from "../../pojo/Item";
import ISODateString = Proto.ISODateString;
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Const } from "../../util/s25-const";
import { S25ObjectConflictComponent } from "./s25.object.conflict.component";
import { S25ObjectAddButtonComponent } from "./s25.object.add.button.component";
import { FlsService } from "../../services/fls.service";
import { Fls } from "../../pojo/Fls";
import { S25EventOccurrencesService } from "../s25-event/s25-event-occurrences/s25.event.occurrences.service";
import { S25OjectSearchUtil, AvailLocationItems, AvailResourceItems, UrlParams } from "./s25.object.search.util";
import { Eval25Service } from "../../services/eval25.service";
import { S25ObjectMap } from "./s25.object.search.config";
import { SearchService } from "../../services/search/search.service";
import { EventMicroService } from "../../services/event.micro.service";
import { EventDataMicroI } from "../s25-event/EventMicroI";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25Event } from "../s25-event/EventMicroI";
import { S25Reservation } from "../s25-event/ReservationI";
import { TelemetryService } from "../../services/telemetry.service";
import { NotificationService } from "../../services/notification.service";
type AddItemType = AvailLocationItems | AvailResourceItems;
//checkAvailability
const stickyColumnPreference = {
    [Item.Ids.Location]: "25L_space_search_columns",
    [Item.Ids.Resource]: "25L_resource_search_columns",
} as any;

@TypeManagerDecorator("s25-ng-object-search-results-list")
@Component({
    selector: "s25-ng-object-search-results-list",
    template: `
        @if (isInit) {
            <div class="buttons c-margin-top--single">
                <button
                    class="aw-button aw-button--primary c-margin-right--single"
                    (click)="saveMulitSelectedItems()"
                    [disabled]="!isMultiSelected"
                >
                    {{ newAddedItemsList.length > 1 ? addSelectedButton + "s" : addSelectedButton }}
                </button>
            </div>
            <div>
                <s25-ng-table
                    [columnSortable]="true"
                    [dataSource]="tableData"
                    [stickyColumnSortPreference]="S25Const.itemId2Name[itemTypeId]"
                    [unlimitedWidth]="true"
                    [pivotThreshold]="0"
                ></s25-ng-table>
                <div class="buttons c-margin-top--single c-margin-bottom--single">
                    <button class="aw-button aw-button--primary c-margin-right--single" (click)="onCancel()">
                        Close
                    </button>
                </div>
            </div>
        }
    `,
    styles: `
        ::ng-deep s25-ng-object-search-results-list tbody tr td input[type="checkbox"] {
            height: 16px !important;
        }

        ::ng-deep s25-ng-object-search-results-list s25-item-generic {
            color: rgb(37, 115, 167);
        }

        ::ng-deep s25-ng-object-search-results-list s25-item-generic s25-popover > .ngInlineBlock {
            display: block !important;
        }

        ::ng-deep s25-ng-object-search-results-list .s25-ng-table--cell.stateTentative {
            font-style: italic;
        }

        ::ng-deep s25-ng-object-search-results-list .s25-ng-table--cell.stateCancelled {
            color: #d62000;
            font-weight: 400;
        }

        ::ng-deep .nm-party--on s25-ng-object-search-results-list .s25-ng-table--cell.stateCancelled {
            color: #ff837a;
        }
        ::ng-deep s25-ng-object-search-results-list s25-ng-table .italic {
            font-style: italic;
        }

        ::ng-deep s25-ng-object-search-results-list s25-ng-table thead th.s25TableColumn {
            color: #000000cc !important;
            font-size: 14px;
            padding: 1em 0.5em !important;
        }

        ::ng-deep .nm-party--on s25-ng-object-search-results-list s25-ng-table thead th.s25TableColumn {
            color: #fff !important;
        }

        ::ng-deep s25-ng-object-search-results-list s25-ng-table .s25-ng-table--table {
            border: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-object-search-results-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-object-search-results-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep s25-ng-object-search-results-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid #e5e5e5;
        }

        ::ng-deep .nm-party--on s25-ng-object-search-results-list s25-ng-table .s25-ng-table--table {
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-object-search-results-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-object-search-results-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep .nm-party--on s25-ng-object-search-results-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .s25-ng-table {
            max-height: 200px !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ObjectSearchResultsListComponent implements OnInit {
    @Input() itemTypeId: Item.Id;
    @Input() isTempSearch?: boolean;
    @Input() cacheId?: number;
    @Input() profileId?: number;
    @Input() eventId?: number;
    @Input() occ?: S25Reservation;
    @Input() params?: UrlParams = {};
    @Input() showLocationScore?: boolean;
    @Input() hideConflicts?: boolean;
    @Input() selectedItems?: number[] = [];

    @Output() refreshF = new EventEmitter();
    @Output() cancelled = new EventEmitter<void>();

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    isInit = false;
    fls: Fls;
    S25Const = S25Const;
    data: any = [];
    sortColumn: string;
    sortOrder: "asc" | "desc";
    chosenModels: S25ItemI[] = [];
    scrollHasMorePages: boolean = false;
    pageSize: number = 500;
    page: number = 1;
    event: S25Event;
    displayLocationScore: boolean = true; // Eval25 global View  for showLocstionScroe
    namePlural: string;
    nameSingular: string;
    nodeId: string;
    nodeName: string;
    isItemAdded: boolean = false;

    failedList: string[] = [];

    newAddedItemsList: AddItemType[] = [];
    addSelectedButton: string = "Add Selected Location";
    addItemsList: AddItemType[] = [];
    isMultiSelected: boolean = false;

    private debounceTimer: ReturnType<typeof setTimeout>;

    tableData: Table.DataSource = {
        type: "infinite scroll",
        dataSource: this.getRows,
        columns: [],
        hasMorePages: this.hasMorePages,
        customRowCount: this.getRowCount,
    };

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private occurrencesService: S25EventOccurrencesService,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }
    async ngOnInit() {
        this.namePlural = S25ObjectMap[this.itemTypeId].name.plural;
        this.nameSingular = S25ObjectMap[this.itemTypeId].name.singular;
        this.nodeId = S25ObjectMap[this.itemTypeId].nodeId;
        this.nodeName = S25ObjectMap[this.itemTypeId].nodeName;

        this.fls = await FlsService.getFls();
        this.event = this.occurrencesService.S25Event;
        this.displayLocationScore = await Eval25Service.displayLocationScore();
        if (this.itemTypeId === Item.Ids.Resource) this.addSelectedButton = "Add Selected Resource";
        this.isInit = true;
        this.cd.detectChanges();
    }

    @Bind
    async getRows(query: Table.PaginatedQuery): Promise<Table.DataSourceResponse> {
        let data = (await this.getData(query)) || [];
        let searchQuerydata = S25Util.array.forceArray(data || []);
        return {
            rows: searchQuerydata.map((row: AvailLocationItems | AvailResourceItems) => this.mapToRow(row)),
            totalRows: data?.[this.namePlural]?.total_results,
        };
    }

    async getData(tableQuery: Table.PaginatedQuery) {
        if (tableQuery.forceRefresh) this.cacheId = undefined; // If refresh is forced, don't reuse cache
        if (tableQuery.sortColumn.id !== this.sortColumn || tableQuery.sortColumn.order !== this.sortOrder) {
            // If sorting changed, reset pagination
            this.cacheId = undefined;
            this.tableComponent.currentPage = 0;
            tableQuery.page = 0;
        }

        this.sortColumn = tableQuery.sortColumn.id;
        this.sortOrder = tableQuery.sortColumn.order;

        this.params.page = "&page=" + this.page;
        this.params.page_size = "&page_size=" + this.pageSize;

        this.data?.[this.namePlural]?.paginate_key
            ? (this.params.paginate = "&paginate=" + this.data?.spaces?.paginate_key + "&page=" + this.page)
            : (this.params.paginate = "&paginate");
        let urlParmas = `${this.params.criteria}${this.params.include}${this.params.page}${this.params.paginate}${this.params.page_size}`;
        if (this.params.inputParam !== "") urlParmas = urlParmas + `${this.params.inputParam}`;
        if (parseInt(this.params.query) !== 0) urlParmas = urlParmas + `${this.params.query}`;
        this.data = await S25ObjectMap[this.itemTypeId].searchQuery(urlParmas);

        let searchQuerydata = S25Util.array.forceArray(this.data?.[this.namePlural]?.[this.nameSingular] || []);

        searchQuerydata.map((item: AvailLocationItems | AvailResourceItems) => {
            item.itemTypeId = this.itemTypeId;
            item.itemId = item.space_id || item.resource_id;
            //assume sharing, if they can, bc on add we will auto-share for them anyway...
            //got this logic from s25-object-search-list-availability.js
            item.share = this.fls.EVENT_SHARE === "F" ? "T" : "F";
        });

        let availItems = [];

        if (searchQuerydata.length > 0) {
            let availData: any = await S25OjectSearchUtil.getAvailData(
                S25ObjectMap[this.itemTypeId].objectType,
                this.profileId,
                this.eventId,
                searchQuerydata,
                this.event,
                this.selectedItems,
            );

            let itemMap = new Map();

            searchQuerydata.forEach((item: AvailLocationItems | AvailResourceItems) => {
                itemMap.set(item[this.nodeId], item);
            });

            availItems = availData;
        }

        //item has conflicts, remove it
        if (this.hideConflicts) {
            for (let i = availItems.length - 1; i >= 0; i--) {
                if (availItems[i]?.hasConflicts) availItems.splice(i, 1);
            }
        }

        // this.scrollHasMorePages = this.pageSize * this.data?.spaces?.page_num < this.data?.spaces?.total_results;
        this.scrollHasMorePages = false;

        this.cacheId = this.data?.[this.namePlural]?.paginate_key;
        let columns = S25ObjectMap[this.itemTypeId].columns;

        if (this.itemTypeId === Item.Ids.Location) {
            const ratingIndex = columns.findIndex((column: Table.Column) => column.id === "rating_avg");
            if (this.showLocationScore && this.displayLocationScore && ratingIndex === -1) {
                columns.push({ id: "rating_avg", header: "Location Satisfaction" });
            } else if ((!this.showLocationScore || !this.displayLocationScore) && ratingIndex !== -1) {
                columns.splice(ratingIndex, 1);
            }
        }

        this.tableComponent.setColumns(columns);
        if (this.isTempSearch) this.deleteTempSearch();
        this.cd.detectChanges();

        return availItems;
    }

    @Bind
    mapToRow(row: AvailLocationItems | AvailResourceItems): Table.Row {
        const cells: Table.Row["cells"] = {};
        const { id, itemName } = this.getIdAndName(row);

        for (let i = 0; i < this.tableData.columns.length; i++) {
            const [key, val] = this.getCell(this.tableData.columns[i], row);
            cells[key] = val;
        }

        return {
            id: id,
            name: itemName,
            cells,
        };
    }

    getCell(column: any, row: any): [string, Table.Cell] {
        let capacity = "";
        if (column.id === "max_capacity") {
            capacity = row?.max_capacity;
            if (row?.layout) {
                let defaultLayout = S25Util.propertyGetParentWithChildValue(row?.layout, "default_layout", "T");
                if (defaultLayout?.layout_capacity) capacity = defaultLayout?.layout_capacity;
            }
        }

        switch (column.id) {
            case "add":
                return [
                    "add",
                    {
                        component: S25ObjectAddButtonComponent,
                        inputs: { data: row, occ: this.occ, itemTypeId: this.itemTypeId },
                        outputs: {
                            onButtonClick: (data, row) => {
                                this.addItem(data.data, data.isAdd, data.multiSelected, row.name);
                            },
                        },
                    },
                ];

            case "space_name":
                return [
                    "space_name",
                    {
                        component: S25ItemGenericComponent,
                        inputs: {
                            modelBean: {
                                itemTypeId: Item.Ids.Location,
                                itemId: row.space_id,
                                itemName: row.space_name,
                            },
                        },
                    },
                ];

            case "conflicts":
                return [
                    "conflicts",
                    {
                        component: S25ObjectConflictComponent,
                        inputs: { hasConflict: S25Util.toBool(row?.hasConflicts), data: row },
                    },
                ];

            case "formal_name":
                return ["formal_name", { text: row.formal_name }];

            case "max_capacity":
                return ["max_capacity", { text: capacity }];

            case "available_dates":
                return [
                    "available_dates",
                    {
                        text: row.checkedObj.availableDates + " / " + row.checkedObj.totalDatesChecked,
                    },
                ];

            case "rating_avg":
                return [
                    "rating_avg",
                    { text: row?.room_rating?.rating_avg ? row?.room_rating?.rating_avg + " / 5.0" : "-" },
                ];

            case "building_id":
                return ["building_id", { text: row?.building_name ? row.building_name : "-" }];

            case "resource_name":
                return [
                    "resource_name",
                    {
                        component: S25ItemGenericComponent,
                        inputs: {
                            modelBean: {
                                itemTypeId: Item.Ids.Resource,
                                itemId: row.resource_id,
                                itemName: row.resource_name,
                            },
                        },
                    },
                ];

            case "stock_level":
                return [
                    "stock_level",
                    {
                        text: row.availability,
                    },
                ];
        }
    }

    @Bind
    onRefresh() {
        this.page = 1; // reset page number
        return this.tableComponent.refresh(true);
    }

    @Bind
    getIdAndName(item: any) {
        let id, itemName;
        if ("space_id" in item) {
            id = item.space_id;
            itemName = item.space_name;
        } else if ("resource_id" in item) {
            id = item.resource_id;
            itemName = item.resource_name;
        }

        return { id, itemName };
    }

    @Bind
    getRowCount(counts: Table.RowCounts) {
        const type = S25Util.firstCharToUpper(S25Const.itemId2Name[this.itemTypeId]) + (counts.total === 1 ? "" : "s");
        return `${counts.total} Matching ${type}`;
    }

    @Bind
    hasMorePages() {
        return this.scrollHasMorePages;
    }

    deleteTempSearch() {
        let query = this.params.query;
        if (query !== "" && query.indexOf("query_id") > -1) {
            const regex = /query_id=(\d+)/;
            const queryId: number = parseInt(query.match(regex)[1]);
            return SearchService.deleteTempSearchByQueryId(queryId, this.itemTypeId);
        }
    }

    async addItem(data: AddItemType, isAdd: boolean, multiSelect: boolean, itemName: string) {
        this.isMultiSelected = multiSelect;
        if (multiSelect) {
            isAdd ? this.newAddedItemsList.push(data[0]) : this.newAddedItemsList.splice(data[0], 1);
        } else {
            this.newAddedItemsList.push(data[0]);
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer); // Clear the previous timer if the user clicked again quickly
            }
            this.debounceTimer = setTimeout(async () => {
                if (this.newAddedItemsList.length > 0) await this.onSave(this.newAddedItemsList, true); // Save the selected items
            }, 1000); // Delay the call by 1000 ms
        }
    }

    async onSave(saveData: AddItemType | AddItemType[], multiSelected: boolean, itemName?: string) {
        this.setLoading(true);
        const reservations = [];
        for (const item of this.selectedItems) {
            reservations.push({
                rsrvId: item, //rsrvId
                [this.namePlural]: saveData,
            });
        }

        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: reservations,
                        },
                    ],
                },
            ],
        };
        this.setLoading(true);
        TelemetryService.sendWithSub(
            "EventDetails",
            "EventDetailsOcc",
            this.itemTypeId === Item.Ids.Location ? "OccAddLoc" : "OccAddRes",
        );
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        this.setLoading(false);
        if (ok) {
            if (ok?.content?.errors || ok?.content?.messages) {
                for (let error of ok?.content?.errors || ok?.content?.messages) {
                    NotificationService.post(error.message);
                }
            } else {
                alert("Added successfully!");
            }
            this.isItemAdded = true;
            this.newAddedItemsList = [];
            this.refreshF.emit(this.isItemAdded);
        }
        if (error) {
            this.refreshF.emit(this.isItemAdded);
            return S25Util.showError(error, "There was an error while attempting to add an item.");
        }
    }

    async saveMulitSelectedItems() {
        await this.onSave(this.newAddedItemsList, true);
    }

    onCancel() {
        this.refreshF.emit(this.isItemAdded);
    }

    setLoading(yes: boolean) {
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }
}
