import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import {
    Answer,
    DBContact,
    MatchProfile,
    MatchStudentHousing,
    Question,
    StudentHousingService,
} from "../student.housing.service";
import { Season, SeasonsService } from "../seasons/seasons.service";
import { ImageActionDataI, ImageUploadModelI } from "../../s25-image-upload/s25.image.upload.component";
import { AttachmentService } from "../../../services/attachment.service";
import { Rule, RuleTreeService } from "../../../services/rule.tree.service";
import { EventFormRuleUtil } from "../../s25-event-creation-form/s25.event.form.rule.util";
import { S25RuleTreeUtil } from "../../s25-rule-tree/s25.rule.tree.util";
import { S25Const } from "../../../util/s25-const";

@TypeManagerDecorator("s25-ng-match-profile")
@Component({
    selector: "s25-ng-match-profile",
    template: `
        @if (init) {
            @if (!isPwSet) {
                <label
                    >Set Password
                    <input type="password" [(ngModel)]="password" />
                </label>
                <button class="aw-button aw-button--primary" [disabled]="savingPassword" (click)="initPassword()">
                    Submit
                </button>
            } @else if (needPw) {
                <label
                    >Enter Password
                    <input type="password" [(ngModel)]="password" />
                </label>
                <button class="aw-button aw-button--primary" [disabled]="loggingIn" (click)="login()">Login</button>
                <button class="aw-button aw-button--outline" [disabled]="unforgetting" (click)="forgotPassword()">
                    Forgot Password
                </button>
            } @else {
                <div class="c-margin-left--single c-margin-top--single">
                    <h2 class="c-margin-bottom--single">
                        {{ profile.contact.firstName }} {{ profile.contact.familyName }}
                    </h2>

                    @if (profile.status !== "questions") {
                        <div class="ngInlineBlock c-margin-bottom--single">
                            <div class="tab">
                                <button
                                    [ngClass]="{ active: mode === 'profile' }"
                                    class="c-textButton"
                                    (click)="setMode('profile')"
                                >
                                    Profile
                                </button>

                                <button
                                    [ngClass]="{ active: mode === 'desiredBldgAndCap' }"
                                    class="c-textButton"
                                    (click)="setMode('desiredBldgAndCap')"
                                >
                                    Desired Buildings and Capacities
                                </button>

                                @if (profile.status !== "buildings") {
                                    <button
                                        [ngClass]="{ active: mode === 'friendSearch' }"
                                        class="c-textButton"
                                        (click)="setMode('friendSearch')"
                                    >
                                        Friend Search
                                    </button>
                                }
                            </div>
                        </div>
                    }

                    @if (mode === "profile") {
                        @if (profile?.roomId) {
                            <div class="c-margin-bottom--single">
                                <span>Assigned Room:&nbsp;</span>
                                <s25-item-space class="s25-object-space" [itemId]="profile.roomId"></s25-item-space>
                            </div>
                        }

                        @if (roommates?.length) {
                            <div class="c-margin-bottom--single">
                                <span>Roommates:&nbsp;</span>
                                @for (roommate of roommates; track roommate.contId; let last = $last) {
                                    <span>{{ roommate.firstName }} {{ roommate.familyName }}</span>
                                    @if (!last) {
                                        <span>;&nbsp;</span>
                                    }
                                }
                            </div>
                        }

                        <label class="c-margin-bottom--single ngBlock" for="profilePicture">Profile Picture</label>
                        <s25-ng-image-upload
                            id="profilePicture"
                            [imageUri]="imageObj.imageData"
                            [hasCropper]="true"
                            [(model)]="imageModel"
                            [options]="{ hasRotate: true, hasZoom: true }"
                            [minWidth]="100"
                            [maxWidth]="320"
                            [minHeight]="100"
                            [maxHeight]="320"
                        ></s25-ng-image-upload>

                        <button
                            class="aw-button aw-button--outline c-margin-bottom--half"
                            (click)="clearProfilePicture()"
                        >
                            Clear Profile Picture
                        </button>

                        <h3 class="c-margin-bottom--single">Questions</h3>
                        <div class="c-margin-bottom--single">
                            @for (question of questions; track question) {
                                <div class="c-margin-bottom--half" [hidden]="question.hidden">
                                    <div>{{ question.question }}</div>

                                    @if (question.questionType === "SELECT") {
                                        <select
                                            class="c-selectInput"
                                            [(ngModel)]="question.singleAnswer"
                                            (ngModelChange)="runRules()"
                                        >
                                            @for (opt of question.options; track opt) {
                                                <option [ngValue]="opt">
                                                    {{ opt.option }}
                                                </option>
                                            }
                                        </select>
                                    }

                                    @if (question.questionType === "MULTISELECT") {
                                        <select
                                            class="c-selectInput multiSelect"
                                            [(ngModel)]="question.answers"
                                            multiple="multiple"
                                            (ngModelChange)="runRules()"
                                        >
                                            @for (opt of question.options; track opt) {
                                                <option [ngValue]="opt">
                                                    {{ opt.option }}
                                                </option>
                                            }
                                        </select>
                                    }

                                    @if (question.questionType === "TEXT") {
                                        <s25-ng-rich-text-editor
                                            [rteLabel]="'Answer:'"
                                            [(modelValue)]="question.longAnswer"
                                        ></s25-ng-rich-text-editor>
                                    }
                                </div>
                            }
                        </div>

                        <label class="ngBlock c-margin-bottom--single"
                            >Custom Message for Friends (include your contact info so you can chat):
                            <textarea class="cn-form__control" [(ngModel)]="profile.friendMsg"></textarea>
                        </label>

                        <s25-ng-button [type]="'primary'" [onClick]="saveProfile">Save Profile</s25-ng-button>
                    }

                    @if (mode === "desiredBldgAndCap") {
                        <h3 class="c-margin-bottom--single">Building Order</h3>
                        <div class="sortContainer c-margin-bottom--single">
                            <div>Order the buildings in which you'd prefer to live.</div>
                            @if (season?.buildingMsg) {
                                <div>Additional Building Info: {{ season.buildingMsg }}</div>
                            }
                            <ul s25-ng-dnd-sortable [items]="buildings">
                                @for (building of buildings; track building.bldgId; let i = $index) {
                                    <li s25-ng-dnd-sortable-item [index]="i">
                                        <s25-ng-drag-handle></s25-ng-drag-handle>
                                        <div>
                                            {{ building.building.bldgName }}
                                            @if (building.building.bldgCode) {
                                                <span>&nbsp;({{ building.building.bldgCode }})</span>
                                            }
                                            @if (building.buildingMsg) {
                                                <span class="ngFinePrint">&nbsp;{{ building.buildingMsg }}</span>
                                            }
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>

                        <h3 class="c-margin-bottom--single">Capacity Order</h3>
                        <div class="sortContainer c-margin-bottom--single">
                            <div>Order the number of roommates with whom you'd prefer to live.</div>
                            <ul s25-ng-dnd-sortable [items]="capacities">
                                @for (capacity of capacities; track capacity; let i = $index) {
                                    <li s25-ng-dnd-sortable-item [index]="i">
                                        <s25-ng-drag-handle></s25-ng-drag-handle>
                                        <div>
                                            {{ capacity }}
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                        <s25-ng-button [type]="'primary'" [onClick]="saveDesiredBldgAndCap"
                            >Save Preferences</s25-ng-button
                        >
                    }

                    @if (mode === "friendSearch") {
                        <s25-ng-profile-list [profile]="profile"></s25-ng-profile-list>
                    }

                    <s25-ng-button
                        [buttonClass]="'ngInlineBlock c-margin-left--single'"
                        [type]="'outline'"
                        [onClick]="logout"
                        >Log Out</s25-ng-button
                    >
                </div>
            }
        }
    `,
    styles: `
        .multiSelect {
            height: auto;
        }

        s25-ng-drag-handle {
            margin: auto 0;
        }

        .sortContainer ul {
            padding-inline-start: 0;
        }

        .sortContainer ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }

        .tab > button.active {
            color: #2573a7 !important;
            border-bottom: 3px solid #2573a7;
        }

        .tab > button {
            padding: 0.5rem 1.5rem;
            color: rgba(0, 0, 0, 0.8) !important;
        }

        ::ng-deep s25-ng-match-profile s25-item-space .s25-item-label {
            display: none !important;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
    @Input() inviteHash: string;

    static profilePictureFileName = "profile_base64";

    init = false;
    loggingIn = false;
    unforgetting = false;
    isPwSet = false;
    needPw = false;
    password: string;
    savingPassword = false;
    profile: MatchProfile;
    questions: Question[] = [];
    buildings: MatchStudentHousing[] = [];
    capacities: number[] = [];
    roommates: DBContact[] = [];
    season: Season;
    imageUrl: string;
    imageModel: ImageUploadModelI = {};
    imageObj: ImageActionDataI = {};
    hasImage = false;
    mode: "none" | "profile" | "desiredBldgAndCap" | "friendSearch" = "profile";
    rules: Rule[] = [];
    origQuestionHiddenState = new Map<number, boolean>();
    prevMatchGroup: string;

    constructor(private cd: ChangeDetectorRef) {}

    refresh = async () => {
        this.isPwSet = await StudentHousingService.isPwSet(this.inviteHash);
        if (!this.isPwSet) {
            this.init = true;
            this.cd.detectChanges();
            return;
        }

        if (!S25Const.jwt && !this.password) {
            this.needPw = true;
            this.init = true;
            this.cd.detectChanges();
            return;
        }

        if (!S25Const.jwt && this.password) {
            await this.login();
            return;
        }

        this.profile = await StudentHousingService.getProfile(this.inviteHash);
        if (!this.profile.status) {
            this.profile.status = "questions";
        }

        let resp = await S25Util.all({
            season: SeasonsService.getSeason(this.profile.seasonId),
            questions: StudentHousingService.getQuestions(this.profile.seasonId),
            profileImage: AttachmentService.getFileIfExists(
                3,
                this.profile.contId,
                ProfileComponent.profilePictureFileName,
                "inline",
            ),
            rules: RuleTreeService.getRules("matchForm", true, this.profile.seasonId),
            roommates: StudentHousingService.getRoommates(this.inviteHash),
        });
        if (resp.profileImage) {
            this.imageObj.imageData = await resp.profileImage.text();
            this.hasImage = !!this.imageObj.imageData;
        }
        this.rules = resp.rules;
        this.profile.desiredBuildings ??= [];
        this.profile.desiredCaps ??= [];
        this.profile.desiredRoommates ??= [];
        this.profile.desiredBuildings.sort(S25Util.shallowSort("sortOrder", true));
        this.profile.desiredCaps.sort(S25Util.shallowSort("sortOrder", true));
        this.profile.desiredRoommates.sort(S25Util.shallowSort("sortOrder", true));
        this.roommates = resp.roommates;

        if (this.profile.status !== "questions") {
            // questions already submitted
            await this.getInitialDesiredBldgAndCap();
            if (this.profile.status !== "buildings") {
                // buildings and cap prefs already submitted
                let desiredIdxMap = new Map<number, number>();
                for (let i = 0; i < this.profile.desiredBuildings.length; i++) {
                    desiredIdxMap.set(
                        this.profile.desiredBuildings[i].bldgId,
                        this.profile.desiredBuildings[i].sortOrder,
                    );
                }
                this.buildings = this.buildings.sort((a: MatchStudentHousing, b: MatchStudentHousing) => {
                    if (desiredIdxMap.has(a.bldgId) && desiredIdxMap.has(b.bldgId)) {
                        return desiredIdxMap.get(a.bldgId) - desiredIdxMap.get(b.bldgId);
                    } else if (desiredIdxMap.has(a.bldgId)) {
                        return -1;
                    } else if (desiredIdxMap.has(b.bldgId)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                desiredIdxMap.clear();
                for (let i = 0; i < this.profile.desiredCaps.length; i++) {
                    desiredIdxMap.set(this.profile.desiredCaps[i].capacity, this.profile.desiredCaps[i].sortOrder);
                }
                this.capacities = this.capacities.sort((a: number, b: number) => {
                    if (desiredIdxMap.has(a) && desiredIdxMap.has(b)) {
                        return desiredIdxMap.get(a) - desiredIdxMap.get(b);
                    } else if (desiredIdxMap.has(a)) {
                        return -1;
                    } else if (desiredIdxMap.has(b)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                // desiredBldgAndCap: bc buildings and caps might be different for the different matchGroup and need sorting again
                if (this.prevMatchGroup && this.prevMatchGroup !== this.profile.matchGroup) {
                    this.setMode("desiredBldgAndCap");
                } else {
                    this.setMode("friendSearch");
                }
            } else {
                this.setMode("desiredBldgAndCap");
            }
        }
        this.season = resp.season;
        this.questions = resp.questions;
        this.questions.forEach((question) => {
            this.origQuestionHiddenState.set(question.questionId, question.hidden);
            this.profile.answers.forEach((answer) => {
                if (answer.questionId === question.questionId) {
                    question.answerId = answer.answerId;
                    if (answer.longAnswer) {
                        question.longAnswer = answer.longAnswer;
                    } else {
                        for (let opt of question.options) {
                            if (opt.option === answer.answer) {
                                if (question.questionType === "MULTISELECT") {
                                    question.answers = question.answers || [];
                                    question.answers.push(opt);
                                } else {
                                    question.singleAnswer = opt;
                                    break;
                                }
                            }
                        }
                    }
                }
            });
        });
        this.runRules();
        this.init = true;
        this.cd.detectChanges();
    };

    saveProfile = async () => {
        this.prevMatchGroup = this.profile.matchGroup;
        if (this.hasImage) {
            await AttachmentService.delFile(3, this.profile.contId, ProfileComponent.profilePictureFileName);
        }
        let croppedImageData = await this.imageModel.getImageData();
        if (croppedImageData) {
            // save cropped image using profile id
            AttachmentService.postFile(
                3,
                this.profile.contId,
                croppedImageData,
                ProfileComponent.profilePictureFileName,
            );
        }

        let answers: Answer[] = [];
        this.questions.forEach((q) => {
            let answer: Answer = {
                answerId: q.answerId,
                contId: this.profile.contId,
                questionId: q.questionId,
            };
            if (q.questionType === "TEXT") {
                answer.longAnswer = q.longAnswer ?? "";
                answers.push(answer);
            } else if (q.questionType === "SELECT" && q.singleAnswer?.option) {
                answer.answer = q.singleAnswer.option;
                answers.push(answer);
            } else if (q.questionType === "MULTISELECT") {
                for (let opt of q.answers || []) {
                    let answerOpt = Object.assign({}, answer);
                    answerOpt.answer = opt.option;
                    answers.push(answerOpt);
                }
            }
        });
        await StudentHousingService.setQuestionAnswers(this.profile.seasonId, this.inviteHash, answers);

        await StudentHousingService.updateProfile(this.inviteHash, this.profile.contId, this.profile.friendMsg);

        this.init = false;
        this.cd.detectChanges();
        await this.refresh();
    };

    getInitialDesiredBldgAndCap = async () => {
        let bldgPromise = StudentHousingService.getStudentBuildings(this.profile.seasonId, this.profile.matchGroup);
        let capPromise = StudentHousingService.getCapacities(this.profile.seasonId, this.profile.matchGroup);
        this.buildings = await bldgPromise;
        this.capacities = await capPromise;
    };

    saveDesiredBldgAndCap = async () => {
        const desiredBldgPromise = StudentHousingService.setProfileDesiredBuildings(
            this.inviteHash,
            this.buildings.map((b, idx) => {
                return {
                    contId: this.profile.contId,
                    bldgId: b.bldgId,
                    sortOrder: idx + 1,
                };
            }),
        );
        const desiredCapPromise = StudentHousingService.setProfileDesiredCap(
            this.inviteHash,
            this.capacities.map((c, idx) => {
                return {
                    contId: this.profile.contId,
                    capacity: c,
                    sortOrder: idx + 1,
                };
            }),
        );
        await desiredBldgPromise;
        await desiredCapPromise;
        this.profile.status = "search";
        this.setMode("friendSearch");
    };

    setMode = (mode: "none" | "profile" | "desiredBldgAndCap" | "friendSearch" = "profile") => {
        this.mode = mode;
        this.cd.detectChanges();
    };

    clearProfilePicture = () => {
        this.imageObj.imageData = null;
        this.cd.detectChanges();
    };

    runRules = () => {
        let satRules = EventFormRuleUtil.getSatisfiedRules({
            rules: this.rules,
            matchQuestions: this.questions,
        });

        let questionsWithAction = new Set<number>();
        satRules.sync.forEach((r) => {
            const rule = S25RuleTreeUtil.parseRule(r);
            for (let [action, items] of Object.entries(rule.targets)) {
                // show matching questions
                if (action === "showQuestion") {
                    items.forEach((item) => {
                        this.questions.forEach((q) => {
                            if (q.questionId === item.itemId && !questionsWithAction.has(q.questionId)) {
                                questionsWithAction.add(q.questionId);
                                q.hidden = false;
                            }
                        });
                    });
                } else if (action === "hideQuestion") {
                    // hide matching questions
                    items.forEach((item) => {
                        this.questions.forEach((q) => {
                            if (q.questionId === item.itemId && !questionsWithAction.has(q.questionId)) {
                                questionsWithAction.add(q.questionId);
                                q.hidden = true;
                            }
                        });
                    });
                }
            }
        });

        this.questions.forEach((q) => {
            if (!questionsWithAction.has(q.questionId)) {
                q.hidden = this.origQuestionHiddenState.get(q.questionId);
            }
        });

        this.cd.detectChanges();
    };

    initPassword = async () => {
        if (this.savingPassword) {
            return;
        }
        this.savingPassword = true;
        this.cd.detectChanges();
        await StudentHousingService.pwInit(this.inviteHash, this.password);
        this.savingPassword = false;
        this.init = false;
        this.cd.detectChanges();
        return this.refresh();
    };

    forgotPassword = async () => {
        if (this.unforgetting) {
            return;
        }
        this.unforgetting = true;
        this.cd.detectChanges();
        await StudentHousingService.pwForgotByInviteHash(this.inviteHash);
        alert("Password reset email sent to your email on file");
        this.unforgetting = false;
        this.cd.detectChanges();
    };

    login = async () => {
        if (this.loggingIn) {
            return;
        }
        this.loggingIn = true;
        this.cd.detectChanges();
        S25Const.jwt = await StudentHousingService.getJwt(this.inviteHash, this.password);
        if (!S25Const.jwt) {
            this.password = "";
            alert("Invalid password");
        } else {
            S25Util.localStorageSet("S25-SH-jwt", S25Const.jwt);
            this.needPw = false;
        }
        this.loggingIn = false;
        return this.refresh();
    };

    logout = async () => {
        S25Const.jwt = null;
        S25Util.localStorageSet("S25-SH-jwt", null);
        this.loggingIn = false;
        this.unforgetting = false;
        this.isPwSet = false;
        this.needPw = false;
        this.password = "";
        this.savingPassword = false;
        await this.refresh();
        return;
    };

    async ngOnInit() {
        let s25SHJwt = S25Util.localStorageGet("S25-SH-jwt");
        if (s25SHJwt) {
            S25Const.jwt = s25SHJwt;
        }
        await this.refresh();
    }
}
