import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ChangeDetectorRef,
    ViewContainerRef,
    ElementRef,
    computed,
} from "@angular/core";
import { S25ReservationUtil } from "../../../utils/s25.reservation.util";
import { Table } from "../../../../s25-table/Table";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25EventOccurrencesService } from "../../s25.event.occurrences.service";
import { S25Reservation } from "../../../ReservationI";
import { S25Event } from "../../../EventMicroI";
import { Event } from "../../../../../pojo/Event";
@TypeManagerDecorator("occurrence-details")
@Component({
    selector: "occurrence-details",
    template: `@if (init) {
        <div (click)="showDetails($event)" class="details-wrapper">
            @if (eventState === 99 || eventState === 98) {
                <div>This occurrence has been cancelled</div>
            }
            @if (eventState !== 99 && eventState !== 98) {
                <div>
                    @if (occurrence()?.locations?.length > 0) {
                        <div>
                            @if (occurrence().locations[0].reserved.length > 0) {
                                <div>
                                    <span class="s25-item-type-icon-holder-spaces">
                                        <span class="icon-space s25item-type-icon"></span>
                                    </span>
                                    {{
                                        occurrence().locations[0].reserved.length > 1
                                            ? occurrence().locations[0].reserved.length + " locations"
                                            : occurrence().locations[0].reserved[0]?.itemName || "1 location"
                                    }}
                                </div>
                            }
                            @if (occurrence().locations[0].draft.length > 0 && eventState === 0) {
                                <div>
                                    <span class="s25-item-type-icon-holder-spaces">
                                        <span class="icon-space-pending s25item-type-icon"></span>
                                    </span>
                                    (
                                    {{
                                        occurrence().locations[0].draft.length > 1
                                            ? occurrence().locations[0].draft.length + " locations"
                                            : occurrence().locations[0].draft[0]?.itemName || "1 location"
                                    }})
                                </div>
                            }
                            @if (occurrence().locations[0].requested.length > 0) {
                                <div>
                                    <span class="s25-item-type-icon-holder-spaces">
                                        <span class="icon-space-pending s25item-type-icon"></span>
                                    </span>
                                    ({{
                                        occurrence().locations[0].requested.length > 1
                                            ? occurrence().locations[0].requested.length +
                                              " locations awaiting approval"
                                            : occurrence().locations[0].requested[0]?.itemName ||
                                              "1 location" + " is awaiting approval"
                                    }})
                                </div>
                            }
                        </div>
                    }
                    @if (occurrence()?.resources?.length > 0) {
                        <div>
                            @if (occurrence().resources[0].reserved.length > 0) {
                                <div>
                                    <span class="s25-item-type-icon-holder-spaces">
                                        <span class="icon-resource s25item-type-icon"></span>
                                    </span>
                                    {{
                                        occurrence().resources[0].reserved.length > 1
                                            ? occurrence().resources[0].reserved.length + " resources "
                                            : occurrence().resources[0].reserved[0]?.itemName +
                                                  " [" +
                                                  occurrence().resources[0].reserved[0].quantity +
                                                  "]" ||
                                              "1 resource" + " [" + occurrence().resources[0].reserved[0].quantity + "]"
                                    }}
                                </div>
                            }
                            @if (occurrence().resources[0].draft.length > 0 && eventState === 0) {
                                <div>
                                    <span class="s25-item-type-icon-holder-resources">
                                        <span class="icon-resource-pending s25item-type-icon"></span>
                                    </span>
                                    ({{
                                        occurrence().resources[0].draft.length > 1
                                            ? occurrence().resources[0].draft.length + " resources"
                                            : "1 resource"
                                    }})
                                </div>
                            }
                            @if (occurrence().resources[0].requested.length > 0) {
                                <div>
                                    <span class="s25-item-type-icon-holder-resources">
                                        <span class="icon-resource-pending s25item-type-icon"></span>
                                    </span>
                                    (
                                    {{
                                        occurrence().resources[0].requested.length > 1
                                            ? occurrence().resources[0].requested.length +
                                              " resources awaiting approval"
                                            : occurrence().resources[0].requested[0]?.itemName ||
                                              "1 resource" + " is awaiting approval"
                                    }})
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }`,
    styles: [
        `
            .details-wrapper:hover {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceDetailsComponent {
    @Input() occ: S25Reservation;
    @Input() eventState?: Event.State.Ids;
    @Input() canEdit?: boolean = false;
    @Input() eventId?: number;
    init: boolean = false;

    @Output() toggleDetailsRow: EventEmitter<Table.NewRowModel> = new EventEmitter<Table.NewRowModel>();
    @Output() refresh = new EventEmitter<void>();
    toggleShowDetails: boolean = false;

    event = this.occurrencesService.S25Event;
    occurrence = computed(() => {
        return this.occurrencesService.getOccurrences().find((rsrv) => rsrv.itemId === this.occ.itemId);
    });

    constructor(
        private occurrencesService: S25EventOccurrencesService,
        private cd: ChangeDetectorRef,
        private viewContainerRef: ViewContainerRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    showDetails(event: any) {
        const table = event.target.offsetParent.offsetParent;
        const row = event.target.offsetParent.parentElement;
        const tableData: Table.NewRowModel = {
            table: table,
            row: row,
            rowIndex: Array.from(table.children[2].children).indexOf(row),
            action: this.toggleShowDetails ? "delete" : "create",
            data: { occ: this.occurrence(), isCopy: false, canEdit: this.canEdit, eventId: this.eventId },
        };

        this.toggleShowDetails = !this.toggleShowDetails;
        this.toggleDetailsRow.emit(tableData);
    }

    refreshF() {
        this.refresh.emit(); // get refresh get the latest update info
    }

    onChangeActive(e: any) {
        S25ReservationUtil.setState(this.occurrence(), e.target.value);
    }
}
