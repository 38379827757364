import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { EventService } from "./event.service";
import { PreferenceService } from "./preference.service";
import { S25Util } from "../util/s25-util";
import { UserprefService } from "./userpref.service";
import { StateService } from "./state.service";
import { S25Const } from "../util/s25-const";
import { DataAccess } from "../dataaccess/data.access";
import { ReservationService } from "./reservation.service";
import { SpaceAssignPermPage, SpaceService } from "./space.service";
import { ResourceService } from "./resource.service";
import { Item } from "../pojo/Item";
import { Proto } from "../pojo/Proto";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { Event } from "../pojo/Event";
import { Security } from "../pojo/Security";
import { ContextMenuService } from "../modules/s25-context-menu/context.menu.service";
import NumericalBoolean = Proto.NumericalBoolean;
import ISODateString = Proto.ISODateString;
import NumericalString = Proto.NumericalString;
import Perm = Security.Perm;

interface EventSubject {
    create_event: 0 | 1;
    endTime: string;
    isFav: 0 | 1;
    isInactive: boolean;
    isPrivate: boolean;
    itemId: number;
    itemName: string;
    itemTypeId: number;
    item_date: string;
    startTime: string;
}

export interface ExceptionDate {
    assignPerm: Perm;
    durationExceededRequest: boolean;
    endDt: string;
    name: string;
    startDt: string;
    versionNbr: number;
}

export interface ExceptionDay {
    assignPerm: Perm;
    dayOfWeek: string;
    durationExceededRequest: boolean;
    endDt: string;
    endTm: string;
    startDt: string;
    startTm: string;
}

export interface ExceptionWindow {
    duration: string;
    assignPerm: Perm;
}

export interface EventPerm {
    assignOverride: boolean;
    assignPerm: Perm;
    assignPermOls: string;
    assignable: boolean;
    enabled: boolean;
    groupId: number;
    unassignPerm: string;
    dateBuffer: ExceptionWindow;
    exceptionDateList: ExceptionDate[];
    exceptionDays: ExceptionDay[];
}

export class AvailService {
    public static AssignPermPriority = {
        notSet: 0,
        noRequest: 1,
        request: 2,
        unassign: 3,
        assign: 4,
        unassignApprove: 5,
        assignApprove: 6,
        requestUnassign: 7,
        requestAssign: 8,
    };

    @Timeout
    public static _getData<T = any>(url: string) {
        return DataAccess.get<T>(url);
    }

    @Timeout
    public static getData(params: any) {
        let queryString = params.modelBean.searchQuery || "";
        let objectType: Item.Id;
        switch (params.compsubject) {
            case "location":
                objectType = Item.Ids.Location;
                break;
            case "resource":
                objectType = Item.Ids.Resource;
                break;
            case "event":
                if (params.comptype !== "availability_schedule") break; // We are targeting the schedule on event item page
                objectType = Item.Ids.Location;
                queryString = params.modelBean.searchQuery; // e.g. "&event_id=297196"
                break;
        }
        const defaultParams = AvailService.defaultParams(params, params.modelBean.optBean.cacheIdOverride);
        const ngParams = AvailService.angularParams(params, true);
        // Converting data==null to "" because null causes errors and "" is what the JS equivalent returned
        return S25Util.all({
            availData: AvailService._getData<RawAvailData>(
                DataAccess.injectCaller(defaultParams + ngParams, "AvailService.getData"),
            ),
            perms: AvailService.getPermsPage(objectType, ngParams + queryString + "&page_size=100"),
        }).then(function (resp) {
            return { ...resp.availData, perms: resp.perms };
        });
    }

    @Timeout
    public static async getData2(options: {
        view: AvailCompType;
        itemType: Item.Id;
        query?: string;
        startDate: string | Date;
        cacheId?: number;
        useCache?: boolean;
        includeRequested?: boolean;
        editable?: boolean;
        multiQueryString?: string;
        pageSize?: number;
        page?: number;
        lastId?: number;
        locationKey?: number;
        resourceKey?: number;
    }) {
        options.query ??= "";
        options.pageSize ??= options.view === "availability_schedule" ? 30 : 100;
        options.page ??= 0;
        const { itemType, query, pageSize, locationKey, resourceKey, page } = options;
        const defaultParams = AvailService.defaultParams2(options);
        const permsQuery = query + `&page_size=${pageSize}`;
        const locPerms = [1, 4].includes(itemType) && AvailService.getPermsPage(4, permsQuery, locationKey, page + 1);
        const resPerms = [1, 6].includes(itemType) && AvailService.getPermsPage(6, permsQuery, resourceKey, page + 1);
        const [availData, perms] = await Promise.all([
            AvailService._getData<RawAvailData>(DataAccess.injectCaller(defaultParams, "AvailService.getData")),
            S25Util.all({ location: locPerms, resource: resPerms }),
        ]);
        return {
            ...availData,
            perms: {
                location: perms.location.items || new Map(),
                resource: perms.resource.items || new Map(),
                locationKey: perms.location.paginateKey,
                resourceKey: perms.resource.paginateKey,
            },
        };
    }

    @Timeout
    @Cache({ immutable: true, targetName: "AvailService" })
    public static getDataCached(params: any) {
        return AvailService.getData(params);
    }

    public static scopeToParams(scope: any) {
        //helper fn to transform scope to a smaller object with items needed for the dao routine
        //note: we do not just pass scope to the dao routine bc then the cache routine cannot properly cache due to circular references in scope
        return {
            comptype: scope.comptype,
            compsubject: scope.compsubject,
            pageSize: scope.pageSize,
            modelBean: {
                searchQuery: scope.modelBean.searchQuery,
                optBean: {
                    includeRequested: scope.modelBean.optBean.includeRequested,
                    cacheIdOverride: scope.modelBean.optBean.cacheIdOverride,
                    editable: scope.modelBean.optBean.editable,
                    useCache: scope.modelBean.optBean.useCache,
                    useServiceCache: scope.modelBean.optBean.useServiceCache,
                    date: scope.modelBean.optBean.date,
                    chosen: scope.modelBean.optBean.chosen,
                    multiQueryStr: scope.modelBean.optBean.multiQueryStr,
                },
            },
        };
    }

    @Timeout
    public static _getDataPage(page: number, last_id: number, url: string) {
        const queries = `&page=${page}&last_id=${last_id}`;
        return AvailService._getData(DataAccess.injectCaller(url + queries, "AvailService._getDataPage"));
    }

    @Timeout
    public static getDataPage(scope: any) {
        const defaultParams = AvailService.defaultParams(scope, scope.modelBean.data.root.obj_id);
        const ngParams = AvailService.angularParams(scope, true);

        let objectType: Item.Id;
        switch (scope.compsubject) {
            case "location":
                objectType = Item.Ids.Location;
                break;
            case "resource":
                objectType = Item.Ids.Resource;
                break;
        }
        const queryString = scope.modelBean.searchQuery || "";
        const paginateKey = scope.modelBean.data.root.perms.paginateKey;
        return S25Util.all({
            availData: AvailService._getDataPage(
                scope.modelBean.page,
                scope.modelBean.data.root.last_id,
                defaultParams + ngParams,
            ),
            perms: AvailService.getPermsPage(
                objectType,
                ngParams + queryString + "&page_size=100",
                paginateKey,
                scope.modelBean.page + 1, // Avail page starts at 0
            ),
        }).then(function (resp) {
            resp.availData.perms = resp.perms;
            return resp.availData;
        });
    }

    @Timeout
    public static getPermsPage(itemType: Item.Id, query: string, paginateKey?: number, page: number = 1) {
        switch (itemType) {
            case Item.Ids.Location:
                return SpaceService.getSpaceAssignPermsPage(query, paginateKey, page);
            case Item.Ids.Resource:
                return ResourceService.getResourceAssignPermsPage(query, paginateKey, page);
            default:
                return Promise.resolve({} as Partial<SpaceAssignPermPage>);
        }
    }

    @Timeout
    @Invalidate({ serviceName: "ReservationService", methodName: "getRsvdetail" })
    public static updateRsrvCheckConflict(updateData: {
        event_id: number;
        reservation_id: number;
        start_dt: ISODateString;
        end_dt: ISODateString;
        space_id?: number;
        space_id_orig?: number;
        update_all_occ?: 0 | 1 | 2; // 0 = only this occ; 1 = this and future occ; 2 = all occ
        update_res?: any;
        resource_id?: number;
        resource_id_orig?: number;
    }) {
        //service that updates a rsrv with new date/times
        const {
            reservation_id,
            space_id,
            start_dt,
            end_dt,
            space_id_orig,
            update_all_occ,
            update_res,
            resource_id,
            resource_id_orig,
        } = updateData;
        let url = `/availability/updatersrvconflict.json?reservation_id=${reservation_id}&start_dt=${start_dt}&end_dt=${end_dt}`;
        if (space_id) url += `&space_id=${space_id}`;
        if (space_id_orig) url += `&space_id_orig=${space_id_orig}`;
        if (resource_id) url += `&resource_id=${resource_id}`;
        if (resource_id_orig) url += `&resource_id_orig=${resource_id_orig}`;
        if (update_all_occ !== undefined) url += `&update_all_occ=${update_all_occ}`;
        if (update_res !== undefined) url += `&update_res=${update_res}`;
        return AvailService._getData(DataAccess.injectCaller(url, "AvailService.updateRsrvCheckConflict")).then(
            (resp) => {
                if (resp.ret === 0) {
                    //success: so update pricing on event
                    EventService.setEventsNeedingRefresh(updateData.event_id); //update pricing as changing events can impact pricing
                }
                return resp; //return data to caller
            },
        );
    }

    @Timeout
    public static deleteReservation(rsrvId: any, eventId: any) {
        const url = `/availability/deletereservation.json?reservation_id=${rsrvId}`;
        return DataAccess.delete(DataAccess.injectCaller(url, "AvailService.deleteReservation")).then(function (resp) {
            EventService.setEventsNeedingRefresh(eventId);
            return resp;
        });
    }

    @Timeout
    public static copyReservation(
        spaceId: NumericalString | number,
        spaceIdOrig: NumericalString | number,
        rsrvId: any,
        rsrvStartDate: string,
        rsrvEndDate: string,
        eventId: number,
        resourceId: NumericalString | number,
        resourceIdOrig: NumericalString | number,
    ) {
        //copies a rsrv and all of its spaces/resources and places it at some new date/times
        let url = `/availability/copyreservation.json?reservation_id=${rsrvId}&reservation_start_dt=${rsrvStartDate}&reservation_end_dt=${rsrvEndDate}`;
        if (spaceId) url += `&space_id=${spaceId}`;
        if (spaceIdOrig) url += `&space_id_orig=${spaceIdOrig}`;
        if (resourceId) url += `&resource_id=${resourceId}`;
        if (resourceIdOrig) url += `&resource_id_orig=${resourceIdOrig}`;
        return DataAccess.put(DataAccess.injectCaller(url, "AvailService.copyReservation")).then(function (resp) {
            EventService.setEventsNeedingRefresh(eventId);
            return resp;
        });
    }

    @Timeout
    public static setReservationState(rsrvId: any, state: any) {
        return DataAccess.put(
            DataAccess.injectCaller(
                `/availability/changereservationstate.json?reservation_id=${rsrvId}&state=${state}`,
                "AvailService.setReservationState",
            ),
        );
    }

    @Timeout
    public static setOfficeHours(starts_with: any, ends_with: any) {
        //sets displayed hours in avail
        return PreferenceService.getPreferences(["OfficeHours"]).then(function (officeHours) {
            return PreferenceService.setPreference(
                "OfficeHours",
                PreferenceService.formOfficeHours(starts_with, ends_with, 0, S25Util.propertyGet(officeHours, "value")),
            );
        });
    }

    public static col2HeaderIdx(col: number) {
        return Math.floor(col / 2);
    }

    public static col2StartTime(col: number, headers: any) {
        //avail column number to start-time helper fn
        let header = headers[AvailService.col2HeaderIdx(col)];
        if (S25Util.isUndefined(header)) {
            console.error("col2StartTime", col, headers);
        }

        return header.header_id + ":" + (col % 2 > 0 ? "30" : "00"); //2 cols per header, if first header (even) its an hour, if second (odd) its 30 min into hour;
    }

    public static col2EndTime(col: number, headers: any) {
        let header = headers[AvailService.col2HeaderIdx(col)];
        if (S25Util.isUndefined(header)) {
            console.error("col2EndTime", col, headers);
        }

        let endTime = header.header_id + (col % 2 > 0 ? 1 : 0) + ":" + (col % 2 > 0 ? "00" : "30"); //end time adds an hour or half-hour
        endTime = endTime === "24:00" ? "23:59" : endTime; //end  time cannot be 24
        return endTime;
    }

    // public static row2Subject(row: number, subjects: any) {
    //helper fn to convert a data row to a data-subject (space (home or avail loc), resource (avail resource), or date (avail schedule))
    //   return subjects[row - 1];
    //}

    public static cell2Subject(cell: any, headers: any, subjects: any, lastCell?: any) {
        //looks at row and cell to add start/ end time to subject
        //helper fn to convert a data row to a data-subject (space (home or avail loc), resource (avail resource), or date (avail schedule))
        let subject = subjects[cell.row - 1];
        subject.endTime = lastCell ? this.col2EndTime(lastCell.col, headers) : this.col2EndTime(cell.col, headers);
        subject.startTime = this.col2StartTime(cell.col, headers);
        return subject;
    }

    public static coalesceDateExceptions(
        base: Perm,
        exceptions: ExceptionDate[],
        start: Date,
        end: Date,
        timeZone: { instance: string; user: string },
    ): { coverage: "none" | "partial" | "full"; perm: Perm } {
        let result: { coverage: "none" | "partial"; perm: Perm } = { coverage: "none", perm: base };
        for (let exception of exceptions) {
            // Date exceptions dates are annotated with timezone offset, so we can just parse them using native Date API
            const exceptionStart = new Date(exception.startDt);
            const exceptionEnd = new Date(exception.endDt);

            // If requested time falls entirely within exception perms can be elevated or restricted
            if (start >= exceptionStart && end <= exceptionEnd) {
                return { coverage: "full", perm: exception.assignPerm };
            }
            // If any overlap, pick the lowest perm
            const isLowerPerm =
                AvailService.AssignPermPriority[exception.assignPerm] < AvailService.AssignPermPriority[result.perm];
            if (isLowerPerm && start < exceptionEnd && end > exceptionStart) {
                result = { coverage: "partial", perm: exception.assignPerm };
            }
        }
        return result;
    }

    public static coalesceDowExceptions(
        base: Perm,
        exceptions: ExceptionDay[],
        start: Date,
        end: Date,
        timeZone: { instance: string; user: string },
    ): { coverage: "none" | "partial" | "full"; perm: Perm } {
        // We need the day of week in the instance's time zone!
        const dow = start.toLocaleDateString("en-US", { weekday: "long", timeZone: timeZone.instance }).toLowerCase();

        let result: { coverage: "none" | "partial"; perm: Perm } = { coverage: "none", perm: base };
        for (let exception of exceptions) {
            if (exception.dayOfWeek !== dow) continue;

            // Getting start and end dates in instance's time zone
            const startDate = S25Util.date.timezone.parse(exception.startDt, timeZone.instance);
            const endDate = S25Util.date.timezone.parse(exception.endDt, timeZone.instance);
            if (end < startDate || start > endDate) continue; // No overlap

            // We need to get start and end times of the exception on the specific day in instance's time zone
            const [startHour, startMin, startSec] = exception.startTm.split(":").map((num) => parseInt(num));
            const startTime = S25Util.date.timezone.toTime(start, timeZone.instance, startHour, startMin, startSec, 0);
            const [endHour, endMin, endSec] = exception.endTm.split(":").map((num) => parseInt(num));
            const endTime = S25Util.date.timezone.toTime(end, timeZone.instance, endHour, endMin, endSec, 0);

            // If requested time falls entirely within exception, then perms can be elevated or restricted
            if (start >= startTime && end <= endTime) {
                return { coverage: "full", perm: exception.assignPerm };
            }
            // If any overlap, pick the lowest perm
            const isLowerPerm =
                AvailService.AssignPermPriority[exception.assignPerm] < AvailService.AssignPermPriority[result.perm];
            if (isLowerPerm && start < endTime && end > startTime) {
                result = { coverage: "partial", perm: exception.assignPerm };
            }
        }
        return result;
    }

    public static coalesceWindowException(
        base: Perm,
        window: ExceptionWindow,
        start: Date,
        timeZone: { instance: string; user: string },
    ): { coverage: "none" | "partial"; perm: Perm } {
        if (!window) return { coverage: "none", perm: base };
        // Since we have the start date in our timezone we can use Date.now()!
        const msUntilEvent = start.getTime() - Date.now();
        const windowMs = S25Util.parseXmlDuration(window.duration);
        if (msUntilEvent < windowMs && msUntilEvent > 0) {
            return { coverage: "partial", perm: window.assignPerm };
        }
        return { coverage: "none", perm: base };
    }

    public static coalescePerms(
        base: Perm,
        dateExceptions: ExceptionDate[],
        dowExceptions: ExceptionDay[],
        windowException: ExceptionWindow,
        start: Date,
        end: Date,
        timezone: { instance: string; user: string },
    ): Perm {
        // Adjust end dates on top of hours to be xx:59 on previous hour so to account for exceptions defaulting to 59
        if (end.getMinutes() === 0) end.setMinutes(-1);

        // Get perms
        const datePerm = AvailService.coalesceDateExceptions(base, dateExceptions, start, end, timezone);
        const dowPerm = AvailService.coalesceDowExceptions(base, dowExceptions, start, end, timezone);
        const windowPerm = AvailService.coalesceWindowException(base, windowException, start, timezone);

        // THIS ORDER IS SPECIFIED IN ANG-3899

        if (datePerm.coverage === "full") return datePerm.perm;
        // DOW exception fully covering AND window covering start of reservation (choose lower)
        if (dowPerm.coverage === "full" && windowPerm.coverage === "partial") {
            if (AvailService.AssignPermPriority[dowPerm.perm] < AvailService.AssignPermPriority[windowPerm.perm]) {
                return dowPerm.perm;
            }
            return windowPerm.perm;
        }
        if (dowPerm.coverage === "full") return dowPerm.perm;
        if (windowPerm.coverage === "partial") return windowPerm.perm;
        if (datePerm.coverage === "partial") return datePerm.perm;
        if (dowPerm.coverage === "partial") return dowPerm.perm;
        return base;
    }

    public static hasEventPerms(
        mod: "create" | "delete" | "assign" | "unassign" | "request",
        base: Perm,
        dateExceptions: ExceptionDate[],
        dowExceptions: ExceptionDay[],
        windowException: ExceptionWindow,
        start: Date,
        end: Date,
        timezone: { instance: string; user: string },
    ): boolean {
        const finalPerms = AvailService.coalescePerms(
            base,
            dateExceptions || [],
            dowExceptions || [],
            windowException,
            start,
            end,
            timezone,
        );
        return AvailService.isPermEnoughTo(mod, finalPerms);
    }

    public static isPermEnoughTo(desired: "create" | "delete" | "assign" | "unassign" | "request", perm: Perm) {
        let requiredPerm: number;
        switch (desired) {
            case "create":
            case "request":
                requiredPerm = AvailService.AssignPermPriority.request;
                break;
            case "delete":
            case "unassign":
                requiredPerm = AvailService.AssignPermPriority.unassign;
                break;
            case "assign":
                requiredPerm = AvailService.AssignPermPriority.assign;
                break;
        }
        return AvailService.AssignPermPriority[perm] >= requiredPerm;
    }

    @Timeout
    public static createEvent(newEvent: any, type: number) {
        // TODO: When possible switch to the TS ModalService
        const ModalService = window.angBridge.$injector.get("s25ModalService");
        //service call to spawn rose with details to create a new event
        const timeArr = newEvent.startTime.split(":"); //get hour/min time from time string like HH:MM
        const newStartDt = S25Util.date.addMinutes(
            S25Util.date.parse(newEvent.startDt),
            parseInt(timeArr[0].trim()) * 60 + parseInt(timeArr[1].trim()),
        );
        return UserprefService.getTZName().then(function (tzName) {
            const dateComp = (tzName && S25Util.date.timezone.convert(new Date(), tzName)) || new Date();
            if (S25Util.date.diffMinutes(dateComp, newStartDt) < 0) {
                const modalData: any = {
                    title: "Past Event Creation Confirmation",
                    msg: "Warning: You are creating an event that begins in the past.",
                };

                ContextMenuService.contextMenuInstance?.destroy();
                ContextMenuService.contextMenuInstance = null;

                return ModalService.modal("dialog", ModalService.dialogType("Continue Cancel", modalData)).then(
                    function () {
                        return modalData.answer > 0 && StateService.createEvent(newEvent, type);
                    },
                );
            } else {
                return StateService.createEvent(newEvent, type);
            }
        });
    }

    public static getItemUID(item: any) {
        //form unique id from an item
        return item && item.itemId + "&" + item.itemId2 + "&" + item.type_id;
    }

    //helper fn to form weekly availability model from generic api data
    public static formWeeklyAvailabilityModel(
        apiData: any,
        displayStartTime: number,
        displayEndTime: number,
        isItemNameTitle: boolean,
    ) {
        displayEndTime += 1; //display end time represents an hour *duration* so +1...
        let ret = [],
            reservations = [],
            reservationsLength,
            i,
            j;
        const rawReservations = apiData && apiData.reservations && apiData.reservations.reservation;
        if (rawReservations) {
            const rsrvHash: any = {};

            for (i = 0; i < rawReservations.length; i++) {
                const rsrv = rawReservations[i];
                const isClosedBlackoutPend =
                    ["closed", "blackout", "pending", "draft", "requested"].indexOf(
                        rsrv.event && rsrv.event.event_type_name,
                    ) > -1;
                const rsrvType = parseInt(rsrv.reservation_type);

                //add closed, blackouts, and normal, direct reservations
                if (isClosedBlackoutPend || [0, 1].indexOf(rsrvType) > -1) {
                    rsrvHash[rsrv.reservation_id] = true;
                    reservations.push(rsrv);
                }
            }

            //add any indirect / related reservations but NOT for the same rsrv id as a normal one that was already added...
            for (i = 0; i < rawReservations.length; i++) {
                const rsrv = rawReservations[i];
                if (rsrv.reservation_id && !rsrvHash[rsrv.reservation_id]) {
                    reservations.push(rsrv);
                }
            }

            reservationsLength = reservations.length;
            for (i = 0; i < reservationsLength; i++) {
                let itemName,
                    itemId,
                    startDt,
                    endDt,
                    preDtEnd,
                    postDtStart,
                    totalMinuteDur = 0,
                    evType,
                    prePerc = 0,
                    evPerc = 0,
                    postPerc = 0;
                itemName =
                    reservations[i].event &&
                    (isItemNameTitle
                        ? reservations[i].event.event_title || reservations[i].event.event_name
                        : reservations[i].event.event_name);
                itemId = reservations[i].event && parseInt(reservations[i].event.event_id);
                startDt = reservations[i].reservation_start_dt;
                endDt = reservations[i].reservation_end_dt;
                evType = reservations[i].event && reservations[i].event.event_type_name;
                if (
                    ((itemName && itemId) ||
                        ["closed", "blackout", "pending", "draft", "requested"].indexOf(evType) > -1) &&
                    startDt &&
                    endDt
                ) {
                    preDtEnd =
                        reservations[i].event.event_start_dt &&
                        S25Util.date.parseDropTZ(reservations[i].event.event_start_dt); //END date of pre/setup time
                    postDtStart =
                        reservations[i].event.event_end_dt &&
                        S25Util.date.parseDropTZ(reservations[i].event.event_end_dt); //START date of post/setup time

                    let preDtEndDisplay = preDtEnd && new Date(preDtEnd.getTime());
                    if (preDtEnd) {
                        if (preDtEnd.getHours() >= displayEndTime) {
                            //if pre time ENDING is after view times...
                            preDtEndDisplay = S25Util.date.getDate(preDtEndDisplay);
                            preDtEndDisplay.setHours(displayEndTime); //set it to last view time
                        } else if (preDtEnd.getHours() <= displayStartTime) {
                            //if pre time ENDING is not even in view times
                            preDtEndDisplay = null; //set to null
                        }
                    }

                    let postDtStartDisplay = postDtStart && new Date(postDtStart.getTime());
                    if (postDtStart) {
                        if (postDtStart.getHours() >= displayEndTime) {
                            //if post time STARTING is past view times
                            postDtStartDisplay = null; //set to null
                        } else if (postDtStart.getHours() < displayStartTime) {
                            //if post time STARTING is before view times
                            //NOTE: its a < check above not <= bc if its = then minutes could take it after view start time so it would be visible
                            postDtStartDisplay = S25Util.date.getDate(postDtStartDisplay);
                            postDtStartDisplay.setHours(displayStartTime); //set it to first view time
                        }
                    }

                    //deal with reservations spanning multiple days by making an item for each day it encompasses, such that we have NO items spanning mult days
                    //if (!s25Util.date.equalDate(startDt, endDt)) { //if mult day rsrv
                    const totalDays = S25Util.date.diffDays(startDt, endDt) + 1; //total days if mult day rsrv
                    let jStartDt, jEndDt; //variables to hold each new item where jStartDt and jEndDt are on the same day
                    for (j = 0; j < totalDays; j++) {
                        //make a separate item for each day
                        prePerc = 0;
                        evPerc = 0;
                        postPerc = 0;

                        //form view start date based on startDt, j and view start hour
                        const displayStartDt = S25Util.date.getDate(S25Util.date.addDays(startDt, j));
                        displayStartDt.setHours(displayStartTime);

                        //form view end date based on startDt, j, and view end hour
                        const displayEndDt = S25Util.date.getDate(S25Util.date.addDays(startDt, j));
                        displayEndDt.setHours(displayEndTime);

                        //form start date for this date in the occurrence
                        jStartDt = S25Util.date.addDays(startDt, j); //add day to start dt, starting with 0, then 1, 2..
                        if (j > 0) {
                            //ie, not the first iteration
                            jStartDt = S25Util.date.getDate(jStartDt); //spanning days start at 00:00:00 bc the orig start was in the past...
                        }

                        //form end date for this date in the occurrence
                        if (j + 1 < totalDays) {
                            //ie, not the last iteration
                            jEndDt = S25Util.date.toEndOfDay(jStartDt); //spanning days end at 23:59:59 bc there are more days to come
                        } else {
                            //last iteration, so use real end dt time
                            jEndDt = S25Util.date.clone(jStartDt);
                            jEndDt.setHours(S25Util.date.parseDropTZ(endDt).getHours());
                            jEndDt.setMinutes(S25Util.date.parseDropTZ(endDt).getMinutes());
                        }

                        //if the formed end date is past vieweable end time, set it to the last viewable time
                        if (jEndDt > displayEndDt) {
                            jEndDt = displayEndDt;
                        }

                        //if the formed start date is before the viewable start time AND the viewable start time falls within the occurrence
                        //then set it to the first viewable time
                        if (jStartDt < displayStartDt && jEndDt > displayStartDt) {
                            jStartDt = displayStartDt;
                        }

                        //total minute duration of new item on single day
                        //we use totalMinuteDur to calculate the percentage of time an item is in pre/setup, actual event, or post/takedown time
                        totalMinuteDur = S25Util.date.diffMinutes(jStartDt, jEndDt);

                        //find percent time this item is in pre time
                        //note: preDt is the END of pre time. pre time goes from START -> preDt
                        //if (end of) pre is between new start and ORIG end (as we iterate to larger startDt, pre should eventually go away)
                        if (preDtEndDisplay && S25Util.date.isBetween(preDtEndDisplay, jStartDt, endDt)) {
                            //Ex: day1: pre, day2: pre, day3: event and END. So preDt is END of pre time and is day2.
                            //1: jStartDt is day1. preDt between day1 (jStartDt) and day3 (endDt). preDt is 2 days larger than jStartDt. prePerc = 1
                            //2: jStartDt is day2. preDt between day2 and day3. preDt is 0 days larger than jStartDt (they are on the same day). prePerc computed
                            //3: jStartDt is day3. preDt is NOT between day3 and day3. prePerc stays as 0
                            if (S25Util.date.diffDays(jStartDt, preDtEndDisplay) > 0) {
                                prePerc = 1; //entire day is pre/setup
                            } else {
                                prePerc = S25Util.date.diffMinutes(jStartDt, preDtEndDisplay) / totalMinuteDur;
                            }
                        }

                        //find percent time this item is in post time
                        //note: postDt is the START of post time. post time goes from postDt -> END
                        //if (start of) post is between ORIG start and new end (as we iterate, once we hit a post, all others will be post too, if any)
                        if (postDtStartDisplay && S25Util.date.isBetween(postDtStartDisplay, startDt, jEndDt)) {
                            if (S25Util.date.diffDays(postDtStartDisplay, jEndDt) > 0) {
                                postPerc = 1; //entire day is post/setup
                            } else {
                                postPerc = S25Util.date.diffMinutes(postDtStartDisplay, jEndDt) / totalMinuteDur;
                            }
                        }

                        evPerc = 1 - (prePerc + postPerc); //ev percent is leftover from pre and post

                        //form item that exists only on 1 day
                        const reservation = reservations[i];
                        ret.push({
                            hasPerm: reservation.event?.event_locator,
                            event_type_id: reservation.event?.event_type_id,
                            cur_event_state: reservation.event?.state,
                            itemTypeId: 1,
                            itemId: itemId,
                            itemId2: reservation.reservation_id,
                            rsrvType: reservation.reservation_type,
                            prePerc: prePerc * 100,
                            evPerc: evPerc * 100,
                            postPerc: postPerc * 100,
                            itemName: evType === "closed" ? "Closed" : evType === "pending" ? "Pending" : itemName,
                            start: S25Util.date.dateToHours(jStartDt),
                            end: S25Util.date.dateToHours(jEndDt),
                            dow: S25Util.date.getDayOfWeek(jStartDt),
                            date: S25Util.date.getDate(jStartDt),
                            expectedHeadcount: reservation.event?.expected_count,
                            registeredHeadcount: reservation.event?.registered_count,
                            setup_dt: reservation.reservation_start_dt,
                            pre_dt: reservation.event?.pre_event_dt,
                            start_dt: reservation.event?.event_start_dt,
                            end_dt: reservation.event?.event_end_dt,
                            post_dt: reservation.event?.post_event_dt,
                            takedown_dt: reservation.reservation_end_dt,
                        });
                    }
                }
            }
        }

        return ret; //return array of items
    }

    //service to return weekly availability model
    @Timeout
    public static getWeeklyAvailability(params: { compsubject?: Item.Subject; [key: string]: any }) {
        const { compsubject, itemId, numWeeks, includeRequested, weekStart } = params;
        const startDt = S25Util.date.firstDayOfWeek(S25Util.date.getDate(params.startDt), weekStart);
        const endDt = S25Util.date.toEndOfDay(
            S25Util.date.lastDayOfWeek(S25Util.date.addWeeks(startDt, numWeeks - 1), weekStart),
        ); //-1 bc we go to the end of the current week already
        const itemTypeId = params.itemType ?? S25Const.itemName2Id[compsubject];
        const includes = itemTypeId === 4 ? ["closed", "blackouts", "pending", "related", "empty"] : [];
        if (itemTypeId === 4 && includeRequested) includes.push("requests", "draft");
        return ReservationService.getReservations(itemTypeId, itemId, startDt, endDt, includes).then((data) => {
            data = S25Util.prettifyJson(
                data, //massage data to a single parent node of "reservations" so we can reuse this routine for sp and rs...
                {
                    space_reservations: "reservations",
                    space_reservation: "reservation",
                    resource_reservations: "reservations",
                    resource_reservation: "reservation",
                },
                { reservation: true, spaces: true, resources: true },
            ); //ensure some branches are arrays
            return AvailService.formWeeklyAvailabilityModel(
                data,
                params.displayStartTime,
                params.displayEndTime,
                params.isItemNameTitle,
            );
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "AvailService" })
    public static getWeeklyAvailabilityCached(params: any) {
        return AvailService.getWeeklyAvailability(params);
    }

    @Timeout
    public static getPerms(objectType: number, queryString: string) {
        if (objectType === 4) {
            return SpaceService.getSpaceAssignPerms(queryString);
        } else if (objectType === 6) {
            return ResourceService.getResourceAssignPerms(queryString);
        }
    }

    @Timeout
    @Cache({ targetName: "AvailService" })
    public static async getSeparatedPref() {
        const pref = await PreferenceService.getGenericPreference("availability", "isSeparated");
        return !!(pref?.isSeparated ?? 1);
    }

    @Timeout
    @Invalidate({ serviceName: "AvailService", methodName: "getSeparatedPref" })
    public static async setSeparatedPref(separated: boolean) {
        await PreferenceService.setGenericPreference("availability", "isSeparated", separated ? "1" : "0");
    }

    public static defaultParams(scope: any, objIdOverride: any) {
        const editing = scope.modelBean.optBean.editable && scope.modelBean.optBean.editable.value;

        if (scope.modelBean.optBean.multiQueryStr) {
            scope.modelBean.searchQuery = S25Util.getMultiQuery(
                scope.modelBean.searchQuery,
                scope.modelBean.optBean.multiQueryStr,
            );
        }

        //obj_cache_accl is the server-side cacheId to use, or 0 to indicate not to use any cache
        const startDt = S25Util.date.toS25ISODateTimeStr(S25Util.date.getDate(scope.modelBean.optBean.date));
        const override = objIdOverride ?? scope.modelBean.optBean.useCache === false ? "force" : "0";
        let url = `/availability/availabilitydata.json?obj_cache_accl=${override}&start_dt=${startDt}&comptype=${scope.comptype}&compsubject=${scope.compsubject}`;
        if (editing && scope.comptype !== "availability_schedule") url += "&mode=edit";
        if (scope.pageSize) url += `&page_size=${scope.pageSize}`;
        if (scope.modelBean.searchQuery) url += scope.modelBean.searchQuery;
        if (scope.compsubject === "location" && scope.comptype !== "availability_schedule") {
            url += "&include=closed+blackouts+pending+related+empty";
            if (!editing && scope.modelBean.optBean.includeRequested) url += "+requests+draft";
        }
        if (scope.compsubject === "resource") {
            url += `&include=empty`;
            if (!editing && scope.modelBean.optBean.includeRequested) url += "+requests+draft";
        }
        return url;
    }

    public static defaultParams2(options: {
        view: AvailCompType;
        itemType: Item.Id;
        query?: string;
        startDate: string | Date;
        cacheId?: number;
        useCache?: boolean;
        includeRequested?: boolean;
        editable?: boolean;
        multiQueryString?: string;
        pageSize?: number;
        page?: number;
        lastId?: number;
    }) {
        let {
            view,
            itemType,
            query,
            startDate,
            cacheId,
            useCache,
            includeRequested,
            editable,
            multiQueryString,
            pageSize,
            page,
        } = options;
        const itemTypeName = S25Const.itemId2Name[itemType];
        if (multiQueryString) query = S25Util.getMultiQuery(query, multiQueryString);

        const startDt = S25Util.date.toS25ISODateTimeStr(S25Util.date.getDate(startDate));
        const override = cacheId ?? useCache === false ? "force" : "0"; //obj_cache_accl is the server-side cacheId to use, or 0 to indicate not to use any cache
        let url = `/availability/availabilitydata.json?obj_cache_accl=${override}&start_dt=${startDt}&comptype=${view}&compsubject=${itemTypeName}`;
        if (editable && view !== "availability_schedule") url += "&mode=edit";
        if (pageSize) url += `&page_size=${pageSize}`;
        if (query) url += query;
        if (itemType === Item.Ids.Location && view !== "availability_schedule") {
            url += "&include=closed+blackouts+pending+related+empty";
            if (!editable && includeRequested) url += "+requests+draft";
        }
        if (itemType === Item.Ids.Resource) {
            url += `&include=empty`;
            if (!editable && includeRequested) url += "+requests+draft";
        }
        if (page) url += `&page=${options.page}&last_id=${options.lastId}`;
        return url;
    }

    public static angularParams(scope: any, forceObjType = false) {
        if (scope.comptype === "availability_home") {
            let queryParam = "&" + scope.modelBean.optBean.chosen.obj.val; //this is the user-chosen saved search (from a drop-down)

            //hack to ensure that availability_home always includes the object type in the query param
            if (scope.compsubject && forceObjType) {
                queryParam = queryParam.replace(/&&query_id=/g, "&" + S25Util.getObjQueryId(scope.compsubject));
            }

            return queryParam;
        } else {
            return "";
        }
    }

    public static getCacheParams(scope: any) {
        let editing = scope.modelBean.optBean.editable && scope.modelBean.optBean.editable.value;
        let startDt = S25Util.date.toS25ISODateTimeStr(S25Util.date.getDate(scope.modelBean.optBean.date));

        let endDt = S25Util.date.clone(startDt);
        if (["availability_home", "availability", "availability_daily"].indexOf(scope.comptype) > -1) {
            endDt = S25Util.date.addSeconds(S25Util.date.addDays(endDt, 1), -1);
        }

        if (scope.comptype === "availability_daily") {
            endDt = S25Util.date.addMonths(endDt, 1);
        }

        return {
            query_id: scope.modelBean.query_id,
            compsubject: scope.compsubject,
            start_dt: startDt,
            end_dt: endDt,
            include:
                (scope.compsubject === "location" && scope.comptype !== "availability_schedule"
                    ? "closed+blackouts+pending+related+empty" +
                      (!editing && scope.modelBean.optBean.includeRequested ? "+requests+draft" : "")
                    : "") +
                (scope.compsubject === "resource"
                    ? "&include=empty" + (!editing && scope.modelBean.optBean.includeRequested ? "+requests+draft" : "")
                    : ""),
        };
    }

    public static async availDataService(options: AvailDataServiceOptions): Promise<AvailData> {
        const dateFormat = await UserprefService.getS25Dateformat(); // Technically async, but should be cached
        const data = await AvailService.getData2({
            ...options,
            startDate: options.startDate || new Date(),
            editable: options.mode === "edit",
        });

        return {
            hasStar: !!data.canStar,
            headers: data.headers.data.map((header) => ({
                time: header.header_id,
                text: header.header_text,
                isPm: !!header.is_pm,
            })),
            rows: (data.subjects || []).map((subject) => {
                // Split items into tracks of non-overlapping events, if mode not overlapping
                const items = (subject.items || []).map(AvailService.cookItem).sort((a, b) => a.start - b.start);
                const tracks =
                    options.mode === "overlapping"
                        ? AvailService.getOverlappingTrack(items)
                        : AvailService.getTracksOverlayConflicts(items);

                const dateBased = subject.itemTypeId === -999;
                return {
                    fav: !!subject.isFav,
                    id: !dateBased ? subject.itemId : subject.item_date,
                    text: !dateBased ? subject.itemName : S25Datefilter.transform(subject.item_date, dateFormat),
                    itemId: subject.itemId,
                    itemType: subject.itemTypeId,
                    date: subject.item_date,
                    tracks,
                };
            }),
            pages: data.page_count,
            lastId: data.last_id,
            perms: data.perms,
            lastUpdate: data.lastupdate,
        };
    }

    public static getOverlappingTrack(items: AvailItem[]) {
        items = items.sort((a, b) => a.start - b.start);
        const conflicts = AvailService.getConflictItems(items);
        const nonConflicts = AvailService.getNonConflictItems(items);
        let blocks = nonConflicts.filter((item) => [2, 3].includes(item.type)); // Blackouts and closed hours go at the top
        const events = nonConflicts.filter((item) => ![2, 3].includes(item.type));
        blocks = blocks.sort((a, b) => b.type - a.type);

        return [[...conflicts, ...blocks, ...events]];
    }

    public static getNonConflictItems(items: AvailItem[]) {
        return (items || []).filter((item) => item.itemType !== 999);
    }

    public static getConflictItems(items: AvailItem[]) {
        const nonConflicts = AvailService.getNonConflictItems(items);
        const itemTimes = new Set(nonConflicts.map((item) => item.start + item.end));
        return (items || []).filter((item) => {
            if (item.itemType !== 999) return false; // Only conflicts
            if (itemTimes.has(item.start + item.end)) return false; // Ignore duplicates of visible events
            return true;
        });
    }

    public static getTracksOverlayConflicts(items: AvailItem[]) {
        const conflicts = AvailService.getConflictItems(items);
        const tracks = AvailService.getTracks(AvailService.getNonConflictItems(items));
        tracks[0]?.unshift(...conflicts); // Add all conflicts to first track
        return tracks;
    }

    public static getTracks(items: AvailItem[]) {
        const tracks: AvailItem[][] = [];
        items = items.sort((a, b) => a.start - b.start);
        let blocks = items.filter((item) => [2, 3].includes(item.type)); // Blackouts and closed hours go at the top
        const events = items.filter((item) => ![2, 3].includes(item.type));
        // Fill gaps between blocks with events if possible
        for (let i = 0; i < blocks.length; i++) {
            const minStart = blocks[i - 1]?.end || 0;
            const maxEnd = blocks[i].start;
            for (let [j, event] of Object.entries(events)) {
                if (event.start >= maxEnd) break;
                if (event.start >= minStart && event.end < maxEnd) {
                    blocks.splice(i, 0, event);
                    events.splice(Number(j), 1);
                    break;
                }
            }
        }

        if (blocks.length) tracks.push(blocks); // Push blocks track here so that if they fit we can add more events after blackouts/closed hours
        for (let item of events) {
            const track = tracks.find((track) => track[track.length - 1].end <= item.start);
            if (!!track) track.push(item);
            else tracks.push([item]);
        }

        if (!tracks.length) tracks.push([]); // Ensure we always have one track, even if it's empty

        // Make sure first track has blackouts on top of closed hours
        tracks[0].sort((a, b) => b.type - a.type);

        return tracks;
    }

    public static cookItem(item: RawAvailSubjectItem): AvailItem {
        return {
            id: item.itemId,
            reservationId: item.itemId2,
            itemType: item.itemTypeId,
            text: item.itemName,
            start: Number(item.start),
            end: Number(item.end),
            eventType: item.event_type_id,
            type: item.type_id,
            eventState: item.cur_event_state,
            eventStart: Number(item.ev_start || 0),
            eventEnd: Number(item.ev_end || 0),
            eventStartTime: Number(item.ev_start_time || Number(item.start)),
            eventEndTime: Number(item.ev_end_time || Number(item.end)),
            initHidden: !!item.initHidden,
            editable: item.editable && ![2, 3, 4, 5].includes(item.type_id), // Blackouts, closed, pending, related events, are not editable
            candidateId: item.candidateId,
            blockedCandidateId: item.blockedCandidateId,
            outsideRange: !!item.outsideRange,
            hasPerm: !!item.has_perm,
            expectedHeadCount: item.exp_head_count,
            registeredHeadCount: item.reg_head_count,
            utilization: {
                "RHC/EHC": item.rhc_ehc && Number(item.rhc_ehc),
                "RHC/CAP": item.rhc_cap && Number(item.rhc_cap),
                "EHC/CAP": item.ehc_cap && Number(item.ehc_cap),
            },
        };
    }

    public static lowestPerm(perms: Perm[]) {
        return perms.reduce(
            (low, perm) => (AvailService.AssignPermPriority[perm] < AvailService.AssignPermPriority[low] ? perm : low),
            "requestAssign",
        );
    }
}

export type AvailCompType = "availability" | "availability_schedule" | "availability_home" | "availability_daily";

type RawAvailData = {
    canStar: NumericalBoolean;
    comptype: AvailCompType;
    last_id: number;
    obj_id: number;
    page_count: number;
    lastupdate: ISODateString;
    headers: { data: RawAvailHeader[] };
    subjects: RawAvailSubject[];
};

type RawAvailHeader = {
    header_id: number;
    header_text: number | string;
    is_pm?: NumericalBoolean;
};

type RawAvailSubject = {
    create_event: NumericalBoolean;
    isFav: NumericalBoolean;
    itemId: number;
    itemName: string;
    itemTypeId: Item.Id | -999;
    item_date: ISODateString;
    items?: RawAvailSubjectItem[];
};

type RawAvailSubjectItem = {
    itemId: number;
    itemId2: number;
    itemName: string;
    itemTypeId: Item.Id;
    type_id: number;
    event_type_id?: number;
    cur_event_state?: Event.State.Id;
    has_perm: NumericalBoolean;
    start: NumericalString;
    end: number;
    ev_start?: NumericalString; // Percentage from start of total reservation
    ev_end?: NumericalString; // Percentage from end of total reservation
    ev_start_time?: NumericalString; // Start of the actual event
    ev_end_time?: NumericalString; // End of the actual event
    initHidden?: NumericalBoolean; // For conflicts in edit mode
    editable?: NumericalBoolean; // Editable in edit mode
    candidateId?: number; // On events in edit mode
    blockedCandidateId?: number; // On conflicts in edit mode
    outsideRange?: NumericalBoolean; // On items spanning midnight or exceeding business horus in edit mode
    exp_head_count?: number;
    reg_head_count?: number;
    rhc_ehc?: NumericalString; // Utilization rate: registered to expected
    rhc_cap?: NumericalString; // Utilization rate: registered to capacity
    ehc_cap?: NumericalString; // Utilization rate: expected   to capacity
};

export type AvailDataService = (options: AvailDataServiceOptions) => Promise<AvailData>;

type AvailDataServiceOptions = {
    view: AvailCompType;
    itemType: Item.Id;
    query: string;
    startDate: Date;
    includeRequested: boolean;
    mode: AvailMode;
    page?: number;
    lastId?: number;
    locationKey?: number;
    resourceKey?: number;
};

export type AvailData = {
    hasStar?: boolean;
    headers: {
        time: number;
        text: string | number;
        isPm: boolean;
    }[];
    rows: AvailRow[];
    pages: number;
    lastId: number;
    lastUpdate: ISODateString;
    perms: { location: Map<number, EventPerm>; resource: Map<number, EventPerm> };
};

export type AvailRow = {
    fav?: boolean;
    id: number | string;
    text: string;
    itemId?: number;
    itemType?: Item.Id | -999;
    date?: ISODateString;
    tracks: AvailItem[][];
};

export type AvailItem = {
    id: number;
    reservationId: number;
    text: string;
    itemType: Item.Id;
    start: number;
    end: number;
    eventType?: number;
    type?: number;
    eventState?: Event.State.Id;
    eventStart?: number;
    eventEnd?: number;
    eventStartTime?: number;
    eventEndTime?: number;
    initHidden?: boolean;
    editable?: boolean;
    candidateId?: number;
    blockedCandidateId?: number;
    outsideRange?: boolean;
    hasPerm?: boolean;
    expectedHeadCount?: number;
    registeredHeadCount?: number;
    utilization: {
        "RHC/EHC": number;
        "RHC/CAP": number;
        "EHC/CAP": number;
    };
    gridData?: {
        // Computed in this component
        row?: AvailRow;
        itemId?: number;
        canEdit?: boolean;
        isCopy?: boolean;
        ariaLabel?: string;
        style?: {
            setupWidth: string;
            takedownWidth: string;
            itemLeft: string;
            itemTop: string;
            itemWidth: string;
            eventWidth: string; // Width of actual event
            pattern?: string;
            backgroundColor: string;
            color: string;
            colorName?: string;
        };
        timeLabels?: {
            pre: string;
            start: string;
            end: string;
            post: string;
        };
        ap: {
            hasOverride?: boolean;
            canUnassign?: boolean;
            canRequest?: boolean;
            assignPerm?: Perm;
            exceptionWindow?: ExceptionWindow;
            exceptionDateList?: ExceptionDate[];
            exceptionDays?: ExceptionDay[];
            canDelete?: boolean;
            canCopy?: boolean;
            canMove?: boolean;
        };
    };
};

export type AvailMode = "overlapping" | "separated" | "edit";
