import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SeasonsComponent } from "./seasons/s25.seasons.component";
import { SeasonComponent } from "./seasons/s25.season.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { SeasonInviteComponent } from "./admin/season.invite.component";
import { SpreadsheetModule } from "../s25-spreadsheet/s25.spreadsheet.module";
import { QuestionComponent } from "./admin/question.component";
import { ProfileComponent } from "./profile/profile.component";
import { ProfileListComponent } from "./profile/profile.list.component";
import { StudentHousingAdminComponent } from "./admin/student.housing.admin.component";
import { SeasonProcessComponent } from "./admin/season.process.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25DndSortableModule } from "../s25-dnd-sortable/s25.dnd.sortable.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25RuleTreeModule } from "../s25-rule-tree/s25.rule.tree.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25ImageUploadModule } from "../s25-image-upload/s25.image.upload.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { StudentHousingEmailComponent } from "./admin/student.housing.email.component";
import { StudentHousingBuildingsComponent } from "./admin/student.housing.buildings.component";
import { AdminProfileListComponent } from "./profile/admin.profile.list.component";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { S25IfShowDirective } from "../../standalone/s25.if.show.directive";
import { S25PipesModule } from "../../pipes/s25.pipes.module";

@NgModule({
    declarations: [
        SeasonsComponent,
        SeasonComponent,
        SeasonInviteComponent,
        QuestionComponent,
        ProfileComponent,
        ProfileListComponent,
        StudentHousingAdminComponent,
        SeasonProcessComponent,
        StudentHousingEmailComponent,
        StudentHousingBuildingsComponent,
        AdminProfileListComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        S25TableModule,
        S25DatepickerModule,
        SpreadsheetModule,
        S25MultiselectModule,
        S25DndSortableModule,
        S25DropdownPaginatedModule,
        S25RuleTreeModule,
        S25CheckboxModule,
        S25ImageUploadModule,
        S25ItemModule,
        S25RichTextEditorModule,
        S25ButtonComponent,
        S25IfShowDirective,
        S25PipesModule,
    ],
    providers: [
        SeasonsComponent,
        SeasonComponent,
        SeasonInviteComponent,
        QuestionComponent,
        ProfileComponent,
        ProfileListComponent,
        StudentHousingAdminComponent,
        SeasonProcessComponent,
        StudentHousingEmailComponent,
        StudentHousingBuildingsComponent,
        AdminProfileListComponent,
    ],
})
export class StudentHousingModule {
    constructor() {}
}
