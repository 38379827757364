import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    signal,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { ImageService } from "../../../services/image.service";
import { Image } from "../../../pojo/Image";
import ImagesI = Image.ImagesI;
import { S25ImageListPreviewComponent } from "./s25.image.list.preview.component";
import { Bind } from "../../../decorators/bind.decorator";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { S25TableUtil } from "../../s25-table/s25.table.util";
import { OptUtil } from "../../s25-opt/s25.opt.util";
import { OptBean } from "../../s25-opt/s25.opt.component";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";

@TypeManagerDecorator("s25-ng-image-list")
@Component({
    selector: "s25-ng-image-list",
    template: `
        <div class="images-list-wrapper">
            <h2 class="c-margin-bottom--single">Images</h2>
            <button class="aw-button aw-button--primary c-margin-bottom--single" (click)="initModal()">
                Create New
            </button>
            @if (init()) {
                <s25-ng-opt [modelBean]="optBean"></s25-ng-opt>
                <s25-loading-inline [model]="{}"></s25-loading-inline>
                @if (tableInit()) {
                    <s25-ng-table
                        [dataSource]="tableConfig"
                        [hasFilter]="true"
                        [columnSortable]="true"
                        [showHeaderWhenNoData]="true"
                    ></s25-ng-table>
                }
            }
        </div>

        <s25-ng-modal #imageForm [title]="action + ' Image'" [size]="'sm'">
            <ng-template #s25ModalBody>
                <s25-ng-image-form
                    [itemId]="actionId"
                    [hasCropper]="false"
                    [cancel]="closeModal"
                    [onSave]="refresh"
                ></s25-ng-image-form>
            </ng-template>
        </s25-ng-modal>
    `,
    styles: `
        ::ng-deep .images-list-wrapper {
            position: relative;
        }

        ::ng-deep .images-list-wrapper s25-ng-opt {
            position: absolute;
            right: 10px;
            top: 70px;
        }

        ::ng-deep .images-list-wrapper .s25ngTable .tableWrapper.infiniteScroll {
            width: 100%;
        }

        ::ng-deep th[col="desc"] {
            width: 300px !important;
        }

        ::ng-deep td[data-label="Description"] s25-ng-table-cell {
            width: 100% !important;
        }

        ::ng-deep ng-component {
            width: fit-content;
            margin: auto;
        }

        ::ng-deep .s25loading-inline {
            width: fit-content;
            margin: auto;
        }

        ::ng-deep .s25ngTable .s25-ng-table--table > thead {
            position: sticky;
        }

        @media screen and (width < 530px) {
            ::ng-deep .images-list-wrapper s25-ng-opt {
                top: 20px;
            }

            ::ng-deep ng-component {
                margin: unset;
                width: fit-content !important;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25ImageListComponent implements OnInit {
    @ViewChild(S25TableComponent) imageTable: S25TableComponent;
    @ViewChild("imageForm") imageForm: S25ModalComponent;

    init = signal(false);
    tableInit = signal(false);
    tableConfig: Table.DataSource;
    columns: Table.Column[] = [
        { id: "name", header: "Name", width: 150 },
        { id: "type", header: "Type" },
        { id: "desc", header: "Description", sortable: false },
        { id: "prev", header: "Preview", sortable: false },
        GenericTableButtonComponent.Column("Edit", this.initModal, "outline", 100),
        GenericTableButtonComponent.Column("Delete", this.delete, "danger--outline", 100),
    ];
    colData: { name: string; prefname: string; isVisible: boolean }[] = [
        { name: "Name", prefname: "name", isVisible: true },
        { name: "Type", prefname: "type", isVisible: true },
        { name: "Description", prefname: "desc", isVisible: true },
        { name: "Preview", prefname: "prev", isVisible: true },
        { name: "Edit", prefname: "edit", isVisible: true },
        { name: "Delete", prefname: "delete", isVisible: true },
    ];
    images: ImagesI[];
    optBean: OptBean;
    actionId: number;
    action: "Add" | "Edit";

    constructor(private elementRef: ElementRef) {}

    async ngOnInit() {
        this.optBean = Object.assign(await OptUtil.formImageListBean(this.refresh, null), {
            autoInit: false,
            colChooseBean: { colList: this.colData, updateCallback: this.updateColumns },
        });
        await this.initTableConfig();
        this.init.set(true);
    }

    async initTableConfig() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.images = (await ImageService.getImages()) ?? [];
        this.tableConfig = S25TableUtil.emulateInfiniteScroll({
            columns: this.columns,
            rows: this.getRows(),
            pageSize: 100,
        });
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.tableInit.set(true);
    }

    getRows() {
        return this.images?.map((image: ImagesI) => {
            return {
                id: image.image_id,
                name: image.image_name,
                cells: {
                    name: { text: image.image_name },
                    type: { text: image.image_type_name },
                    desc: { text: image.image_desc },
                    prev: { component: S25ImageListPreviewComponent, inputs: { imageId: image.image_id } },
                },
            };
        });
    }

    @Bind
    async delete(row: Table.Row, button: GenericTableButtonComponent) {
        button.isLoading = true;
        await ImageService.deleteImage(row.id as number);
        return this.refresh();
    }

    @Bind
    async refresh() {
        if (this.imageForm?.isOpen) await this.imageForm.close();
        this.tableInit.set(false);
        return this.initTableConfig();
    }

    @Bind
    updateColumns() {
        if (this.imageTable) {
            this.imageTable.visibleColumns = this.imageTable.dataSource.columns.filter(
                (col) => this.colData.find((selectedCol) => selectedCol.prefname === col.id)?.isVisible,
            );
            this.imageTable.detectChanges();
        }
    }

    @Bind
    async initModal(row?: Table.Row) {
        if (row) {
            this.action = "Edit";
            this.actionId = row.id as number;
        } else {
            this.action = "Add";
            this.actionId = null;
        }
        this.imageForm.open();
        return;
    }

    @Bind
    closeModal() {
        return this.imageForm.close();
    }
}
