import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    signal,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { FormsModule } from "@angular/forms";
import { Message } from "./s25.chatbot.service";

@TypeManagerDecorator("s25-ng-ai-chatbot")
@Component({
    selector: "s25-ng-ai-chatbot",
    template: `
        <div class="chat-container">
            <div class="chat-window" #chatWindow>
                @for (message of messages; track $index) {
                    <div class="{{ message.role }}-message">
                        {{ message.content }}
                    </div>
                }
            </div>
            <div class="chat-input">
                <input
                    class="c-input"
                    type="text"
                    [value]="inputMessage()"
                    placeholder="Type your message..."
                    (input)="onInputChange($event.target.value, $event)"
                    (keydown.enter)="onSend()"
                    autocomplete="off"
                />
                <button (click)="onSend()" [disabled]="isLoading" id="send-message-button">Send</button>
            </div>
        </div>
    `,
    styles: [
        `
            /* Container for chat */
            .chat-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: start;
                min-height: 300px;
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                border: 1px solid #ccc;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                padding: 10px;
            }

            /* Chat window for messages */
            .chat-window {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow-y: scroll;
                padding: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid #e0e0e0;
                max-height: 360px;
            }

            /* Any messages */
            .user-message,
            .assistant-message {
                padding: 10px;
                border-radius: 15px;
                margin-bottom: 5px;
                max-width: 80%;
                word-wrap: break-word;
                white-space: pre-wrap;
            }

            /* User message */
            .user-message {
                align-self: flex-end;
                background-color: #007bff;
                color: white;
                border-radius: 15px 15px 1px 15px;
            }

            /* AI response */
            .assistant-message {
                align-self: flex-start;
                background-color: #e1e1e1;
                color: #333;
                border-radius: 15px 15px 15px 1px;
            }

            /* Input section */
            .chat-input {
                display: flex;
                align-items: center;
            }

            /* In order to avoid send button leaving column bounds */
            @media only screen and (max-width: 1060px) {
                .chat-input {
                    flex-wrap: wrap;
                }

                .chat-input input {
                    margin-bottom: 10px;
                }
            }

            /* Text input */
            .chat-input input {
                flex-grow: 1;
                margin-right: 10px;
                font-size: 16px;
            }

            #send-message-button {
                background: #3273a0;
                border-color: #3273a0;
                color: #fff;
                border: 1px solid rgba(0, 0, 0, 0.08);
                border-radius: 2px;
                box-sizing: border-box;
                display: inline-block;
                font: inherit;
                font-size: 0.8em;
                font-weight: 400;
                font-style: initial;
                letter-spacing: 0.04em;
                margin: 0;
                padding: 0.5em 1em;
                position: relative;
                text-decoration: none;
                transition:
                    background-color 0.1s linear,
                    border-color 0.1s linear,
                    color 0.1s linear,
                    box-shadow 0.1s linear;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule],
    standalone: true,
})
export class S25ChatbotComponent implements OnInit {
    @Input() welcomeMessage: string = "Hello! How can I assist you?";
    @Input() isLoading: boolean = false;
    // This will change whether the component emits the entire message 'thread', or single messages - to be used by the parent component
    @Input() singleMessageMode: boolean = false;

    @Output() messageSent = new EventEmitter<string>();
    @Output() messageThreadSent = new EventEmitter<Message[]>();
    @Output() responseReceived = new EventEmitter<string>();

    @ViewChild("chatWindow") chatWindow!: ElementRef;

    messages: Message[] = [];
    inputMessage = signal<string>("");

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.welcomeMessage) {
            this.messages.push({ role: "assistant", content: this.welcomeMessage });
        }
    }

    onInputChange(value: string, event: Event) {
        this.inputMessage.set(value);
    }

    onSend() {
        if (this.singleMessageMode) {
            this.sendMessage();
        } else {
            this.sendMessageThread();
        }
    }

    sendMessage() {
        if (!this.inputMessage().trim()) {
            return this.cd.detectChanges();
        }

        this.messages.push({ role: "user", content: this.inputMessage() });
        this.messageSent.emit(this.inputMessage()); // Emit user message to parent component
        this.inputMessage.set(""); // Clear input field
        this.cd.detectChanges();
        this.scrollToBottom();
    }

    sendMessageThread() {
        if (!this.inputMessage().trim()) {
            return this.cd.detectChanges();
        }

        this.messages.push({ role: "user", content: this.inputMessage() });
        this.messageThreadSent.emit(this.messages); // Emit message thread to parent component
        this.inputMessage.set(""); // Clear input field
        this.cd.detectChanges();
        this.scrollToBottom();
    }

    addResponse(response: string) {
        this.messages.push({
            role: "assistant",
            content: response || "An error has occurred. Please refresh and try again.",
        });
        this.responseReceived.emit(response);
        this.cd.detectChanges();
        this.scrollToBottom();
    }

    scrollToBottom() {
        try {
            this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
        } catch (err) {}
    }
}
