import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { LooseAutocomplete } from "../pojo/Util";
import { S25LoadingInlineModule } from "../modules/s25-loading/s25.loading.inline.module";

export type ButtonClassSuffix =
    | "primary"
    | "outline"
    | "danger"
    | "danger--outline"
    | "danger--transparent"
    | "flat"
    | "success"
    | "warning"
    | "none";

@Component({
    selector: "s25-ng-button",
    template: `
        <button
            class="aw-button aw-button--{{ type }} {{ buttonClass }}"
            (click)="clickHandler($event)"
            [disabled]="disabled || isLoading"
            [attr.isLoading]="isLoading"
            [title]="disabled ? disabledReason ?? '' : ''"
            [attr.aria-label]="ariaLabel"
        >
            @if (isLoading) {
                <s25-ng-loading-inline-static [text]="loadingText"></s25-ng-loading-inline-static>
            }
            @if (!isLoading) {
                {{ buttonText }}
                <ng-content></ng-content>
            }
        </button>
    `,
    styles: `
        :host {
            display: inline-flex;
            align-items: center;
        }

        button {
            height: 100%;
            width: 100%;
        }

        s25-ng-loading-inline-static {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
        }

        ::ng-deep s25-ng-button > button.aw-button--primary > s25-ng-loading-inline-static .c-loading__inner {
            stroke: white;
        }

        ::ng-deep s25-ng-button > button > s25-ng-loading-inline-static .s25-loading {
            background-color: transparent !important;
            padding: 0;
        }

        .aw-button--flat {
            border: none;
            color: currentColor;
        }
    `,
    standalone: true,
    imports: [S25LoadingInlineModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25ButtonComponent implements OnChanges, OnInit {
    @Input({ required: true }) type: LooseAutocomplete<ButtonClassSuffix>;
    @Input() buttonText?: string = "";
    @Input() disabled: boolean = false;
    @Input() disabledReason: string; // Will display on hover if button is disabled
    @Input() isLoading: boolean = false;
    @Input() loadingText: string;
    @Input() ariaLabel: string;
    @Input() buttonClass?: string = "";
    @Input() onClick?: () => Promise<unknown> | void;

    @Output() click = new EventEmitter<MouseEvent>();
    @Output() clickPromise = new EventEmitter<Promise<unknown>>();
    @Output() isLoadingChange = new EventEmitter<boolean>();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isLoading) {
            if (changes.isLoading.currentValue) this.startLoading();
            else this.stopLoading();
        }
    }

    ngOnInit() {
        if (!this.ariaLabel && this.buttonText) {
            this.ariaLabel = this.buttonText;
        }
    }

    startLoading() {
        // We don't want the button's size to change when we change its contents,
        // so we need to set a fixed height and width here
        const width = this.elementRef.nativeElement.offsetWidth;
        const height = this.elementRef.nativeElement.offsetHeight;
        this.renderer.setStyle(this.elementRef.nativeElement, "width", `${width}px`);
        this.renderer.setStyle(this.elementRef.nativeElement, "height", `${height}px`);
    }

    stopLoading() {
        // In startLoading() we set a fixed height and width,
        // so we need to remove that here
        this.renderer.removeStyle(this.elementRef.nativeElement, "width");
        this.renderer.removeStyle(this.elementRef.nativeElement, "height");
    }

    clickHandler($event: MouseEvent) {
        this.click.emit($event);
        $event?.stopImmediatePropagation();
        if (this.onClick) {
            this.startLoading();
            this.isLoading = true;
            this.isLoadingChange.emit(this.isLoading);
            this.cd.detectChanges();
            let clickPromise = (this.onClick() || Promise.resolve()).then(
                () => {
                    this.clickPromiseHandler(clickPromise);
                },
                () => {
                    this.clickPromiseHandler(clickPromise);
                },
            );
        }
    }

    clickPromiseHandler(clickPromise: Promise<unknown>) {
        this.stopLoading();
        this.isLoading = false;
        this.isLoadingChange.emit(this.isLoading);
        this.clickPromise.emit(clickPromise);
        this.cd.detectChanges();
    }
}
