import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    signal,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Item } from "../../pojo/Item";
import { S25Const } from "../../util/s25-const";
import { Report } from "../../pojo/Report";
import { Bind } from "../../decorators/bind.decorator";
import { DocumentListItem, DocumentService } from "../../services/document.service";
import ObjectType = Report.ObjectType;
import Context = Report.Context;
import { EventMicroService } from "../../services/event.micro.service";
import { Organization } from "../s25-event/EventMicroI";
import { Doc } from "../system-settings/document-management/s25.document.const";
import ScopeId = Doc.ScopeId;

@TypeManagerDecorator("s25-ng-print-object-report")
@Component({
    selector: "s25-ng-print-object-report",
    template: `
        <div class="moreActionsSection">
            @if (itemTypeId === Event) {
                <label class="c-margin-bottom--half">
                    <span class="c-margin-right--single">Choose your report type</span>
                    <select
                        [ngModel]="reportType()"
                        (ngModelChange)="onReportTypeChange($event)"
                        class="cn-form__control c-margin-top--quarter"
                    >
                        @for (type of reportTypes; track type) {
                            <option [value]="type.value">{{ type.label }}</option>
                        }
                    </select>
                </label>
            }
            @if (!reports?.length) {
                <div class="ngBold no-reports">No reports available for this {{ itemTypeName }}</div>
            }
            <div class="moreActionsPrint">
                <div class="moreActionsReports">
                    @switch (reportType()) {
                        @case ("reservation") {
                            <div class="reservationsContainer">
                                <div>
                                    <p class="ngBold c-margin-bottom--quarter">Select the Report Reservation</p>
                                    <s25-ng-document-reservation-list
                                        class="ng-isolate-scope"
                                        [eventId]="itemId"
                                        (selected)="reservationChosen($event)"
                                    ></s25-ng-document-reservation-list>
                                </div>
                                <div class="c-margin-right--half">
                                    @if (reservationId()) {
                                        <p class="ngBold c-margin-bottom--quarter">Select Report to Run</p>
                                        @for (report of reservationReports; track report) {
                                            <s25-ng-report [report]="report" [context]="reportContext"></s25-ng-report>
                                        }
                                    }
                                </div>
                            </div>
                        }

                        @case ("invoice") {
                            <div class="invoiceContainer">
                                <div>
                                    <p class="ngBold c-margin-bottom--quarter">Select the Report Invoice</p>
                                    <s25-ng-document-invoice-list
                                        [eventId]="itemId"
                                        (selected)="invoiceSelected($event)"
                                    ></s25-ng-document-invoice-list>
                                </div>
                                <div>
                                    @if (invoiceId()) {
                                        <label
                                            >Choose Organization:
                                            <select
                                                [ngModel]="organization()"
                                                (ngModelChange)="setOrganization($event)"
                                                class="cn-form__control c-margin-top--quarter"
                                            >
                                                <option value="undefined">All Organizations</option>
                                                @for (org of organizations(); track org) {
                                                    <option value="{{ org.organizationId }}">
                                                        {{ org.organizationName }}
                                                    </option>
                                                }
                                            </select>
                                        </label>
                                        <p class="ngBold c-margin-bottom--quarter">Select Report to Run</p>
                                        @for (report of invoiceReports; track report) {
                                            <s25-ng-report [report]="report" [context]="reportContext"></s25-ng-report>
                                        }
                                    }
                                </div>
                            </div>
                        }
                        @default {
                            @for (report of objectReports; track report) {
                                <s25-ng-report [report]="report" [context]="reportContext"></s25-ng-report>
                            }
                        }
                    }
                </div>
            </div>
        </div>
    `,
    styles: `
        .moreActionsPrint {
            padding-bottom: 1em;

            .moreActionsReports {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
                max-height: 200px;
                overflow: auto;
                padding-bottom: 1em;
                column-gap: 3em;

                a:hover,
                a:focus {
                    text-decoration: none;
                }

                .reservationsContainer,
                .invoiceContainer {
                    display: grid;
                    grid-template-columns: 1fr 25rem;
                    width: 100%;
                }

                .reservationsContainer {
                    grid-template-columns: 1fr 30rem;
                }
            }

            .reservationsContainer {
                s25-ng-report {
                    margin-left: 2em;
                    display: block;
                }
            }
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PrintObjectReportComponent implements OnInit {
    @Input({ required: true }) itemId: number;
    @Input({ required: true }) itemTypeId: Item.Id;
    @Input() reports: Report.SimpleObject[] = [];

    hasReservationOption: boolean;
    hasInvoiceOption: boolean;
    itemTypeName: string;

    objectReports: Report.SimpleObject[] = [];
    invoiceReports: Report.SimpleObject[] = [];
    reservationReports: Report.SimpleObject[] = [];

    invoiceId = signal<number>(null);
    reservationId = signal<number>(null);

    reportType = signal<ReportTypes>("event");
    reportTypes: { value: ReportTypes; label: string }[] = [];
    reportContext: Context = {};
    documentByReportId: Map<number, DocumentListItem> = new Map();
    documentReports: Map<ScopeId, DocumentListItem[]> = new Map();

    organizations = signal<Organization[]>([]);
    organization = signal<number>(undefined);

    async getEventData() {
        const evData = await EventMicroService.getEventDetailById([this.itemId], "T", "organizations");
        this.organizations.update((orgs) => {
            return [...orgs, ...evData.content?.expandedInfo?.organizations];
        });
        this.cd.detectChanges();
    }

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.itemTypeName = S25Const.itemId2Display[this.itemTypeId]?.singular;
        switch (this.itemTypeId) {
            //Historically, events and locations are the only object with obj specific reports
            case Item.Ids.Event:
                //Event object reports are event based, reservation based, or invoice based
                await this.getDocumentReports();
                await this.completeReportsList();
                this.reportTypes = [{ value: "event", label: "Event Based" }];
                if (this.hasReservationOption)
                    this.reportTypes.push({ value: "reservation", label: "Reservation Based" });
                if (this.hasInvoiceOption) this.reportTypes.push({ value: "invoice", label: "Invoice Based" });
                break;
            case Item.Ids.Location:
                //Locations have only ever had the daily room sheet JReport -147
                this.addObjectReports(this.reports);
                break;
        }
        this.reportContext = { num_parm1: this.itemId };
        this.cd.detectChanges();
    }

    async onReportTypeChange(newValue: ReportTypes) {
        if (newValue === "invoice" && !this.organizations()?.length) await this.getEventData();

        this.reportType.set(newValue);
        this.cd.detectChanges();
    }

    async getDocumentReports() {
        const [contracts, orgContracts, rsrvContracts, invoiceDocuments] = await Promise.all([
            DocumentService.getDocumentList("event"),
            DocumentService.getDocumentList("organization"),
            DocumentService.getDocumentList("reservation"),
            DocumentService.getDocumentList("invoice"),
        ]);

        this.documentReports.set("event", contracts || []);
        this.documentReports.set("organization", orgContracts || []);
        this.documentReports.set("reservation", rsrvContracts || []);
        this.documentReports.set("invoice", invoiceDocuments || []);

        for (const contract of this.documentReports.get("event"))
            this.documentByReportId.set(contract.reportId, contract);
        for (const contract of this.documentReports.get("organization"))
            this.documentByReportId.set(contract.reportId, contract);
        for (const contract of this.documentReports.get("reservation"))
            this.documentByReportId.set(contract.reportId, contract);
        for (const doc of this.documentReports.get("invoice")) this.documentByReportId.set(doc.reportId, doc);
    }

    @Bind
    async completeReportsList() {
        const len = this.reports.length;
        for (let i = len - 1; i >= 0; i--) {
            const report = this.reports[i];
            const rptId = +report.rpt_id;
            this.addReportsFromShell(rptId, report);

            switch (+report.rpt_use) {
                case Report.Use.Confirmation:
                    report.rpt_name = "Default Confirmation: " + report.rpt_name;
                    break;
                case Report.Use.Invoice:
                    report.rpt_name = "Default Invoice: " + report.rpt_name;
                    break;
            }

            if (report.rpt_engine === "JR" && report.object_type === ObjectType.PaymentDocument) {
                this.addInvoiceReports([report]);
            } else if (report.rpt_engine === "DM") {
                const document = this.documentByReportId.get(report.rpt_id);
                if (!document) continue;

                const docReport = {
                    ...report,
                    rpt_name: document.itemName,
                    document_id: document.itemId,
                    optUUID: document.itemId,
                    type: document.type,
                };
                switch (document.type) {
                    case "event":
                    case "organization":
                        this.addObjectReports([docReport]);
                        break;
                    case "reservation":
                        this.addReservationReports([docReport]);
                        break;
                    case "invoice":
                        this.addInvoiceReports([docReport]);
                        break;
                }
            } else {
                this.addObjectReports([report]);
            }
        }

        this.objectReports.sort(this.sortReports);
        this.reservationReports.sort(this.sortReports);
        this.invoiceReports.sort(this.sortReports);
    }

    //report list is sorted by rpt_use then rpt_name, higher rpt_use first
    sortReports(a: Report.SimpleObject, b: Report.SimpleObject) {
        if (a?.rpt_use === b?.rpt_use) return a?.rpt_name?.localeCompare(b?.rpt_name);
        return b?.rpt_use - a?.rpt_use;
    }

    addReportsFromShell(rptId: number, report: Report.SimpleObject) {
        const reportTypes: { [key: number]: ScopeId } = {
            [Report.Reports.EventShell.id]: "event",
            [Report.Reports.OrganizationShell.id]: "organization",
            [Report.Reports.ReservationShell.id]: "reservation",
            [Report.Reports.PaymentShell.id]: "invoice",
        };

        const objectTypes: { [key: number]: ObjectType } = {
            [Report.Reports.EventShell.id]: ObjectType.EventDocument,
            [Report.Reports.OrganizationShell.id]: ObjectType.OrganizationDocument,
            [Report.Reports.ReservationShell.id]: ObjectType.ReservationDocument,
            [Report.Reports.PaymentShell.id]: ObjectType.PaymentDocument,
        };

        const type: ScopeId = reportTypes[rptId];
        const objectType: ObjectType = objectTypes[rptId];

        if (type && objectType) {
            const reports = this.documentReports.get(type)?.map((c) => ({
                ...report,
                rpt_id: c.reportId || rptId,
                rpt_name: `${type.charAt(0).toUpperCase() + type.slice(1)} Document: ${c.itemName}`,
                document_id: c.itemId,
                optUUID: c.itemId,
                object_type: objectType,
            }));

            switch (type) {
                case "event":
                case "organization":
                    this.addObjectReports(reports);
                    break;
                case "reservation":
                    this.addReservationReports(reports);
                    break;
                case "invoice":
                    this.addInvoiceReports(reports);
                    break;
            }
        }
    }

    addObjectReports(reports: Report.SimpleObject[]) {
        this.objectReports.push(...reports);
    }

    addReservationReports(reports: Report.SimpleObject[]) {
        this.hasReservationOption = true;
        this.reservationReports.push(
            ...reports.map((report) => {
                return {
                    ...report,
                    type: Doc.scope.reservation.id,
                };
            }),
        );
    }

    addInvoiceReports(reports: Report.SimpleObject[]) {
        this.hasInvoiceOption = true;
        this.invoiceReports.push(
            ...reports.map((report) => {
                return {
                    ...report,
                    type: Doc.scope.invoice.id,
                };
            }),
        );
    }

    reservationChosen(reservationId: number) {
        this.reportContext.char_parm4 = reservationId?.toString();
        this.reservationId.set(reservationId);
    }

    invoiceSelected(invoiceId: number) {
        this.reportContext.num_parm2 = invoiceId?.toString();
        this.invoiceId.set(invoiceId);
    }

    setOrganization(eventId: number) {
        this.organization.set(eventId);
        this.reportContext.num_parm4 = eventId?.toString();
    }

    Event = Item.Ids.Event;
}

type ReportTypes = "reservation" | "event" | "invoice";
