import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { ReportService } from "../../../../services/report.service";
import { Invoice } from "../../../../pojo/Pricing";
import { OrganizationI } from "../../../../pojo/OrganizationI";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { TelemetryService } from "../../../../services/telemetry.service";
import { EventService } from "../../../../services/event.service";
import { Report as Reports } from "../../../../pojo/Report";

@TypeManagerDecorator("s25-ng-pricing-org-summary")
@Component({
    selector: "s25-ng-pricing-org-summary",
    template: ` @if (init) {
        <!-- Print Invoice button for use in different views -->
        <ng-template #printInvoiceButton>
            @if (paymentsMode) {
                <select
                    class="cn-form__control c-margin-top--quarter"
                    [class.c-margin-bottom--quarter]="paymentsMode && freshbooksTab"
                    [class.c-margin-left--half]="freshbooksTab"
                    [(ngModel)]="selectedInvoice"
                    (ngModelChange)="detectChanges()"
                >
                    @if (!selectedInvoice) {
                        <option [value]="undefined">Select Invoice</option>
                    }
                    @for (invoice of invoicesForPrint; track invoice) {
                        <option [ngValue]="invoice">
                            {{ invoice.billDefn.billName }}
                        </option>
                    }
                </select>
            }
            @if (modelBean.allOrgs?.length > 1 && ((paymentsMode && selectedInvoice) || !paymentsMode)) {
                <div [class.c-margin-left--half]="!paymentsMode || (paymentsMode && freshbooksTab)">
                    <label>
                        Organization:
                        <select
                            class="cn-form__control c-margin-top--quarter invoice-org-select c-margin-bottom--quarter"
                            [(ngModel)]="selectedOrg"
                            (ngModelChange)="detectChanges()"
                        >
                            <option [value]="undefined">All Organizations</option>
                            @for (org of modelBean.allOrgs; track org) {
                                <option [ngValue]="org">
                                    {{ org.organization_name }}
                                </option>
                            }
                        </select>
                    </label>
                </div>
            }
            <s25-ng-button
                [type]="'primary'"
                [disabled]="!defaultInvoiceReport || (paymentsMode && !selectedInvoice)"
                [disabledReason]="!defaultInvoiceReport ? printInvoiceDisabledReason : 'No invoice selected'"
                (click)="printInvoice()"
                [class.c-margin-left--half]="!paymentsMode || (paymentsMode && freshbooksTab)"
            >
                @if (!paymentsMode && modelBean.relatedEventIds?.length) {
                    Print Related Events Invoice
                } @else {
                    Print Default Invoice
                }
            </s25-ng-button>
        </ng-template>

        @if ((!paymentsMode && !modelBean.evBillId) || (paymentsMode && freshbooksTab)) {
            <table class="tblOrgSummary ngTblOrgSummary">
                <tbody>
                    <tr>
                        @if (modelBean.hasFreshbooks && !summaryView) {
                            <th>Organizations Invoiced</th>
                            <td class="hide-on-mobile"></td>
                            <th>Organizations Not Invoiced</th>
                            <td class="hide-on-mobile"></td>
                            <th>Organizations Without Charge</th>
                            <td class="hide-on-mobile"></td>
                        }
                        <th>Print Invoice</th>
                    </tr>
                    <tr class="summary-data-row">
                        @if (modelBean.hasFreshbooks) {
                            <td class="tblOrgSummaryContent">
                                @if (invoiceOrgItems.length === 0) {
                                    <span>none</span>
                                }
                                <div class="org-list-wrapper" tabindex="0" aria-label="Organizations Invoiced">
                                    @for (org of invoiceOrgItems; track org.organization_id; let isLast = $last) {
                                        <span
                                            tabindex="0"
                                            (keydown.enter)="scrollTo(org.organization_id)"
                                            [attr.aria-label]="org.organization_name"
                                        >
                                            <a (click)="scrollTo(org.organization_id)"
                                                >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</a
                                            >
                                        </span>
                                    }
                                </div>
                            </td>
                            <td class="hide-on-mobile"></td>
                            <td class="tblOrgSummaryContent">
                                @if (billedOrgItems.length === 0) {
                                    <span>none</span>
                                }
                                <div class="org-list-wrapper" aria-label="Organizations Not Invoiced">
                                    @for (org of billedOrgItems; track org; let isLast = $last) {
                                        <span
                                            tabindex="0"
                                            (keydown.enter)="scrollTo(org.organization_id)"
                                            [attr.aria-label]="org.organization_name"
                                        >
                                            <a (click)="scrollTo(org.organization_id)"
                                                >{{ org.organization_name }}{{ !isLast ? ", " : "" }}</a
                                            >
                                        </span>
                                    }
                                </div>
                            </td>
                            <td class="hide-on-mobile"></td>
                            <td class="tblOrgSummaryContent">
                                @if (noChargeOrgItems.length === 0) {
                                    <span>none</span>
                                }
                                <div class="org-list-wrapper" tabindex="0" aria-label="Organizations Without Charge">
                                    @for (org of noChargeOrgItems; track org; let isLast = $last) {
                                        <span tabindex="0" [attr.aria-label]="org.organization_name">
                                            {{ org.organization_name }}{{ !isLast ? ", " : "" }}
                                        </span>
                                    }
                                </div>
                            </td>
                            <td class="hide-on-mobile"></td>
                        }
                        <td class="tblOrgSummaryContent">
                            <!--                            <s25-loading-inline [model]="{}"></s25-loading-inline>-->
                            <ng-container *ngTemplateOutlet="printInvoiceButton"></ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        } @else {
            <label>
                Print Invoice:
                <div class="print-wrapper">
                    <ng-container *ngTemplateOutlet="printInvoiceButton"></ng-container>
                </div>
            </label>
        }
    }`,
    styles: `
        .tblOrgSummary.ngTblOrgSummary {
            width: 98%;

            a {
                color: #2573a7 !important;
            }

            .tblOrgSummaryContent:has(select) {
                padding: 6px;
            }

            .tblOrgSummaryContent select {
                display: block;
            }

            s25-loading-inline {
                margin: unset;
            }

            .org-list-wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            @media screen and (max-width: 1100px) {
                .hide-on-mobile {
                    display: none;
                }

                tbody {
                    display: grid;
                    grid-auto-flow: column;
                }

                tr {
                    display: grid;
                    gap: 10px;
                }

                .summary-data-row {
                    grid-template-rows: repeat(4, 1fr);
                }
            }
        }

        .print-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            button {
                position: relative;
                top: 2px;
            }

            .invoice-org-select {
                display: block;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOrgSummaryComponent implements OnInit {
    @Input() modelBean: PricingOrgSummary;
    @Input() summaryView: boolean;
    @Input() paymentsMode: boolean;
    @Input() freshbooksTab: boolean = false;

    @Output() signalScroll: EventEmitter<string> = new EventEmitter<string>();

    init: boolean = false;
    invoiceOrgItems: OrganizationI[];
    billedOrgItems: OrganizationI[];
    noChargeOrgItems: OrganizationI[];
    selectedInvoice: BillingWithEventId;
    invoicesForPrint: BillingWithEventId[];
    selectedOrg: OrganizationI; // selected organization for invoice printing

    defaultInvoiceReport: Reports.SimpleObject;
    printInvoiceDisabledReason = "No default invoice configured on event type";

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.invoiceOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return this.modelBean.invoiceOrgs.indexOf(org.organization_id) > -1;
        });

        this.billedOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return (
                this.modelBean.billedOrgs.indexOf(org.organization_id) > -1 &&
                this.modelBean.invoiceOrgs.indexOf(org.organization_id) === -1
            );
        });

        this.noChargeOrgItems = this.modelBean.allOrgs.filter((org: OrganizationI) => {
            return this.modelBean.billedOrgs.indexOf(org.organization_id) === -1;
        });

        const reports = await EventService.getEventReports(this.modelBean.eventId);
        if (!this.paymentsMode) {
            if (this.modelBean.relatedEventIds?.length) {
                this.defaultInvoiceReport = reports.find(
                    (report) => report.rpt_id === Reports.Reports.InvoiceRelated.id,
                );
                this.printInvoiceDisabledReason = `"Event Invoice (Related)" is not associated with this event type`;
            } else {
                this.defaultInvoiceReport = reports.find((report) => report.rpt_use === Reports.Use.Invoice);
            }
        } else {
            // -998 indicates the invoice summary, will potentially include summary report in the future
            this.invoicesForPrint = this.modelBean.invoices.filter((invoice: Invoice) => invoice.id !== -998);
            this.defaultInvoiceReport = reports.find((report) => report.rpt_use === Reports.Use.Payment);
        }

        this.init = true;

        this.cd.detectChanges();
    }

    scrollTo(orgId: number | string) {
        this.signalScroll.emit(`#pricing_org_list_${this.modelBean.eventId}_${orgId}`);
    }

    async printInvoice() {
        TelemetryService.sendWithSub("Pricing", "Event", "PrintInvoice");
        await ReportService.invoiceReport(
            this.modelBean.eventId,
            !this.paymentsMode ? null : this.selectedInvoice.id,
            this.defaultInvoiceReport,
            this.selectedOrg?.organization_id,
        );
        this.cd.detectChanges();
    }

    detectChanges() {
        this.cd.detectChanges();
    }
}

type BillingWithEventId = Invoice & { eventId?: number };

export type PricingOrgSummary = {
    billedOrgs: number[];
    invoiceOrgs: number[];
    allOrgs: OrganizationI[];
    eventId: number;
    relatedEventIds: number[];
    hasFreshbooks: boolean;
    evBillId: number;
    locatorMap: { [key: number]: string };
    invoices: Invoice[];
};
