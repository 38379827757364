import { Doc } from "../system-settings/document-management/s25.document.const";
import { Item } from "../../pojo/Item";
import { VariableOptionType, VariableWithOptions } from "../s25-dropdown/single-select/s25.bpe.vars.dropdown.component";

export namespace BpeVarsConst {
    const scope = {
        event: Doc.scope.event.id,
        organization: Doc.scope.organization.id,
        reservation: Doc.scope.reservation.id,
        invoice: Doc.scope.invoice.id,
    };

    function indexedVar(options: {
        val: string;
        label?: string;
        id: string;
        prop?: string;
        type?: VariableOptionType;
        data?: any;
    }) {
        const { val, label, id, prop, type, data } = options;
        const indexed = val + ".$1";
        return {
            val: indexed,
            txt: label || "",
            options: [{ type: type || "custom", id, substitute: "$1", prop: prop || "val", data }],
        };
    }

    function contactRoleVar(val: string, label: string) {
        return indexedVar({ val, label, id: "contactRole", type: "roleDropdown", data: { type: Item.Ids.Event } });
    }

    function invoicePaymentVar(val: string, label: string) {
        const paymentType = [
            { val: "Deposit", txt: "Deposit" },
            { val: "Balance", txt: "Balance" },
            { val: "Misc", txt: "Misc" },
        ];
        return {
            val,
            txt: label,
            options: [{ type: "dropdown", id: "type", options: paymentType, substitute: "$1", prop: "itemId" }],
        };
    }

    function resourceByVar(by: "ID" | "Cat", val: string, label: string) {
        const base = `ws.rsrv.${val}-${by}`;
        return {
            txt: label,
            options: [
                { type: "checkbox", id: "includeAll", label: "Include All" },
                {
                    type: "searchCriteriaItems",
                    id: "ids",
                    substitute: "$ids",
                    data: { criterion: by === "ID" ? "resources" : "resourceCategories" },
                },
            ],
            vars: [
                { var: `${base}.ALL`, options: { includeAll: true } },
                { var: `${base}.$ids`, options: { includeAll: false } },
            ],
        };
    }

    const occurrenceDateVariables = [
        {
            val: "dates.rsrvStartDate",
            txt: "Setup Date",
            dataType: "format",
            width: 30,
            wsVal: "reservation_start_dt",
        },
        {
            val: "dates.rsrvPreEventDate",
            txt: "Pre-Event Date",
            dataType: "format",
            width: 30,
            wsVal: "pre_event_dt",
        },
        {
            val: "dates.rsrvEventStartDate",
            txt: "Event Start Date",
            dataType: "format",
            width: 30,
            wsVal: "event_start_dt",
        },
        {
            val: "dates.rsrvEventEndDate",
            txt: "Event End Date",
            dataType: "format",
            width: 30,
            wsVal: "event_end_dt",
        },
        {
            val: "dates.rsrvPostEventDate",
            txt: "Post-Event Date",
            dataType: "format",
            width: 30,
            wsVal: "post_event_dt",
        },
        {
            val: "dates.rsrvEndDate",
            txt: "Takedown Date",
            dataType: "format",
            width: 30,
            wsVal: "reservation_end_dt",
        },
        { val: "durations.setup", txt: "Setup Duration", width: 20 },
        { val: "durations.preEvent", txt: "Pre-Event Duration", width: 20 },
        { val: "durations.event", txt: "Event Duration", width: 20 },
        { val: "durations.postEvent", txt: "Post-Event Duration", width: 20 },
        { val: "durations.takedown", txt: "Takedown Duration", width: 20 },
        { val: "durations.preToPost", txt: "Pre-Event to Post-Event Duration", width: 20 },
        { val: "durations.setupToTakedown", txt: "Setup to Takedown Duration", width: 20 },
    ];

    const occurrencePricingVariables = [
        { val: "occurrenceTotalListPrice", txt: "Total List Price", width: 10 },
        { val: "occurrenceTotalCharge", txt: "Total Charge", width: 10 },
        { val: "occurrenceTotalAdjustments", txt: "Total Adjustments", width: 10 },
        { val: "occurrenceLocationsTotal", txt: "Locations Total", width: 10 },
        { val: "occurrenceLocationsTotalListPrice", txt: "Locations Total List Price", width: 10 },
        { val: "occurrenceResourcesTotal", txt: "Resources Total", width: 10 },
        { val: "occurrenceResourcesTotalListPrice", txt: "Resources Total List Price", width: 10 },
    ];

    const occurrenceLocationPricingVariables = [
        { val: "locationOccurrenceTotal", txt: "Location Total", width: 10 },
        { val: "locationOccurrenceListPrice", txt: "Location List Price", width: 10 },
    ];

    const occurrenceResourcePricingVariables = [
        { val: "resourceOccurrenceTotal", txt: "Resource Total", width: 10 },
        { val: "resourceOccurrenceListPrice", txt: "Resource List Price", width: 10 },
    ];

    const occurrenceVariables = [
        { val: "rsrvStateName", txt: "State", width: 10 },
        { val: "profileName", txt: "Segment", width: 20, wsVal: "profile_name" },
        ...occurrenceDateVariables,
        ...occurrencePricingVariables,
    ];

    const dateFormats = [
        { val: "short", txt: "M/d/yy, h:mm a", ex: "6/15/20, 9:03 AM" },
        { val: "medium", txt: "MMM d, y, h:mm:ss a", ex: "Jun 15, 2020, 9:03:01 AM" },
        { val: "long", txt: "MMMM d, y, h:mm:ss a z", ex: "June 15, 2020 at 9:03:01 AM GMT+1" },
        {
            val: "full",
            txt: "EEEE, MMMM d, y, h:mm:ss a zzzz",
            ex: "Monday, June 15, 2020 at 9:03:01 AM GMT+01:00",
        },
        { val: "shortDate", txt: "M/d/yy", ex: "6/15/2020" },
        { val: "mediumDate", txt: "MMM d, y", ex: "Jun 15, 2020" },
        { val: "longDate", txt: "MMMM d, y", ex: "June 15, 2020" },
        { val: "fullDate", txt: "EEEE, MMMM d, y", ex: "Monday, June 15, 2020" },
        { val: "shortTime", txt: "h:mm a", ex: "9:03 AM" },
        { val: "mediumTime", txt: "h:mm:ss a", ex: "9:03:01 AM" },
        { val: "longTime", txt: "h:mm:ss a z", ex: "9:03:01 AM GMT+1" },
        { val: "fullTime", txt: "h:mm:ss a zzzz", ex: "9:03:01 AM GMT+01:00" },
    ];

    const paymentType = [
        { val: "deposit", txt: "Deposit" },
        { val: "final", txt: "Balance" },
        { val: "misc", txt: "Misc" },
    ];

    export const organizationRole = {
        options: [
            { type: "toggle", id: "specific", hidden: true },
            { type: "custom", id: "org", hidden: true, substitute: "$org", prop: "itemId" },
            {
                type: "roleDropdown",
                id: "role",
                substitute: "$role",
                prop: "val",
                data: { type: Item.Ids.Organization },
            },
        ],
        vars: [
            { var: "primaryOrganization.contacts.$role.itemName", options: { specific: false } },
            { var: "organizations.$org.contacts.$role.itemName", options: { specific: true } },
        ],
    } as VariableWithOptions;

    export const attribute = indexedVar({ val: "customAttributes", id: "attribute" });
    export const orgAttribute = indexedVar({ val: "ws.org.CustomAttribute", id: "attribute" });

    export const Items = {
        dates: [
            { val: "dates.creationDate", txt: "Creation Date", hasFormat: true },
            { val: "dates.currentLocalDate", txt: "Current Local Date", hasFormat: true },
            { val: "dates.lastModDate", txt: "Last Edit Date", hasFormat: true },
            { val: "dates.eventSetupDate", txt: "Setup Date", hasFormat: true },
            { val: "dates.preEventDate", txt: "Pre-Event Date", hasFormat: true },
            { val: "dates.eventStartDate", txt: "Event Start Date", hasFormat: true },
            { val: "dates.eventEndDate", txt: "Event End Date", hasFormat: true },
            { val: "dates.postEventDate", txt: "Post-Event Date", hasFormat: true },
            { val: "dates.eventTakedownDate", txt: "Takedown Date", hasFormat: true },
        ],
        ws: [
            { val: "ws.PageNum", txt: "Page Number" },
            { val: "ws.PrimaryOrgAdminAddr", txt: "Primary Org Administrative Address" },
            { val: "ws.PrimaryOrgBillingAddr", txt: "Primary Org Billing Address" },
        ],
        pricing: [
            {
                val: "grandTotal",
                txt: "Grand Total",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.EventPricingGrandTotal", options: { related: false } },
                    { var: "ws.RelatedEventPricingGrandTotal", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "ws.EventPricingDate",
                txt: "Pricing Date",
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            {
                val: "ws.TaxTotalType.$data",
                txt: "Tax Total (specific)",
                options: [
                    {
                        type: "searchCriteriaItem",
                        id: "taxSchedule",
                        substitute: "$data",
                        prop: "itemId",
                        data: { criterion: "taxSchedules" },
                    },
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.TaxTotalType.$data", options: { related: false } },
                    { var: "ws.RelatedEventTaxTotalType.$data", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "taxTotal",
                txt: "Tax Total",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.TaxTotal", options: { related: false } },
                    { var: "ws.RelatedEventTaxTotal", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "resourceTotal",
                txt: "Resource Total Charge",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.RsrcChargeTotal", options: { related: false } },
                    { var: "ws.RelatedEventRsrcChargeTotal", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "locationTotal",
                txt: "Location Total Charge",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.LocationChargeTotal", options: { related: false } },
                    { var: "ws.RelatedEventLocationChargeTotal", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
        ],
        invoice: [
            { val: "ws.InvoiceNumber", txt: "Invoice Number" },
            { val: "ws.InvoiceName", txt: "Invoice Name" },
            { val: "ws.InvoicePricingDate", txt: "Invoice Date", hasFormat: true },
            { val: "ws.InvoicePmtReceivedTable", txt: "Payments Received Table" },
            { val: "ws.InvoicePmtDueTable", txt: "Payments Due Table" },
        ],
        invoiceTotals: [
            { val: "ws.InvoiceTotalListPrice", txt: "Total List Price" },
            { val: "ws.InvoiceTotalLineItemAdj", txt: "Total Line Item Adjustment" },
            { val: "ws.InvoiceTotalTaxableAmt", txt: "Total Taxable Amount" },
            {
                val: "ws.InvoiceTotalTaxType.$data",
                txt: "Total Tax (specific)",
                options: [
                    {
                        type: "searchCriteriaItem",
                        id: "taxSchedule",
                        substitute: "$data",
                        prop: "itemId",
                        data: { criterion: "taxSchedules" },
                    },
                ],
            },
            { val: "ws.InvoiceTotalTax", txt: "Total Tax" },
            { val: "ws.InvoiceTotalCharge", txt: "Total Charge" },
            { val: "ws.InvoiceTotalAdjustments", txt: "Total Adjustments" },
            { val: "ws.InvoiceGrandTotal", txt: "Grand Total" },
            { val: "ws.InvoiceOutstandingBalance", txt: "Outstanding Balance" },
            { val: "ws.InvoiceTotalLocationCharge", txt: "Total Location Charge" },
            { val: "ws.InvoiceTotalResourceCharge", txt: "Total Resource Charge" },
        ],
        invoicePayment: [
            invoicePaymentVar("ws.InvoicePmt$1ReceivedAmt", "Amount Received"),
            invoicePaymentVar("ws.InvoicePmt$1DueAmt", "Amount Due"),
            invoicePaymentVar("ws.InvoicePmt$1ReceivedDate", "Received Date"),
            invoicePaymentVar("ws.InvoicePmt$1DueDate", "Due Date"),
            invoicePaymentVar("ws.InvoicePmt$1DueDescription", "Due Description"),
            invoicePaymentVar("ws.InvoicePmt$1ReceivedDescription", "Received Description"),
        ],
        wsTables: [
            {
                val: "requirements",
                txt: "Requirements",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization, scope.reservation],
                    },
                ],
                vars: [
                    { var: "ws.ServicesTable", options: { related: false } },
                    { var: "ws.RelatedEventServicesTable", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            { val: "ws.EventOrganizationListTable", txt: "Organizations" },
            { val: "ws.EventConfirmationTextTable", txt: "Confirmation Texts" },
            {
                val: "comments",
                txt: "Comments",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.EventCommentsTable", options: { related: false } },
                    { var: "ws.RelatedEventCommentsTable", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            {
                val: "attributes",
                txt: "Custom Attributes",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                ],
                vars: [
                    { var: "ws.EventCustomAttributesTable", options: { related: false } },
                    { var: "ws.RelatedEventCustomAttributesTable", options: { related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            { val: "ws.RelatedEventListTable", txt: "Related Events", scopes: [scope.event, scope.organization] },
            {
                val: "attendees",
                txt: "Attendees",
                options: [{ type: "checkbox", id: "byStatus", label: "Sort by Status" }],
                vars: [
                    { var: "ws.OccurrenceAttendeeTable", options: { byStatus: false } },
                    { var: "ws.OccurrenceAttendeeByStatusTable", options: { byStatus: true } },
                ],
            },
            {
                val: "rsrvTimes",
                txt: "Reservations Times",
                options: [
                    { type: "checkbox", id: "formal", label: "Formal Name" },
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization, scope.reservation],
                    },
                ],
                vars: [
                    { var: "ws.rsrv.TimesLocationTable", options: { formal: false, related: false } },
                    { var: "ws.rsrv.TimesFormalTable", options: { formal: true, related: false } },
                    { var: "ws.rsrv.RelatedTimesLocationTable", options: { formal: false, related: true } },
                    { var: "ws.rsrv.RelatedTimesFormalTable", options: { formal: true, related: true } },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            {
                val: "rsrvLayouts",
                txt: "Reservations With Layouts",
                options: [
                    { type: "checkbox", id: "formal", label: "Formal Name" },
                    { type: "checkbox", id: "format", label: "Format As Table" },
                ],
                vars: [
                    { var: "ws.rsrv.LocationNameLayout_Inst", options: { formal: false, format: false } },
                    { var: "ws.rsrv.FormalNameLayout_Inst", options: { formal: true, format: false } },
                    { var: "ws.rsrv.LocationNameLayoutInst", options: { formal: false, format: true } },
                    { var: "ws.rsrv.FormalNameLayoutInst", options: { formal: true, format: true } },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            {
                val: "occurrences",
                txt: "Occurrences",
                options: [
                    { type: "checkbox", id: "formal", label: "Formal Name" },
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization, scope.reservation],
                    },
                    { type: "checkbox", id: "hideTime", label: "Hide Times" },
                ],
                vars: [
                    { var: "ws.SpaceShortResourceOccurrenceTable", options: {} },
                    { var: "ws.SpaceLongResourceOccurrenceTable", options: { formal: true } },
                    {
                        var: "ws.SpaceLongResourceRelatedOccurrenceTable",
                        options: { formal: true, related: true },
                    },
                    {
                        var: "ws.SpaceLongResourceOccurrenceNoRsrvTimeTable",
                        options: { formal: true, hideTime: true },
                    },
                    {
                        var: "ws.SpaceLongResourceRelatedOccurrenceNoRsrvTimeTable",
                        options: { formal: true, hideTime: true, related: true },
                    },
                    { var: "ws.SpaceShortResourceRelatedOccurrenceTable", options: { related: true } },
                    { var: "ws.SpaceShortResourceOccurrenceNoRsrvTimeTable", options: { hideTime: true } },
                    {
                        var: "ws.SpaceShortResourceRelatedOccurrenceNoRsrvTimeTable",
                        options: { hideTime: true, related: true },
                    },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            {
                val: "occurrencesPricing",
                txt: "Occurrence Pricing",
                options: [
                    { type: "checkbox", id: "formal", label: "Formal Name" },
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization, scope.reservation],
                    },
                    { type: "checkbox", id: "hideTime", label: "Hide Times" },
                ],
                vars: [
                    { var: "ws.SpaceShortResourceOccurrencePricingTable", options: {} },
                    { var: "ws.SpaceLongResourceOccurrencePricingTable", options: { formal: true } },
                    {
                        var: "ws.SpaceLongResourceRelatedOccurrencePricingTable",
                        options: { formal: true, related: true },
                    },
                    {
                        var: "ws.SpaceLongResourceOccurrenceNoRsrvTimePricingTable",
                        options: { formal: true, hideTime: true },
                    },
                    {
                        var: "ws.SpaceLongResourceRelatedOccurrenceNoRsrvTimePricingTable",
                        options: { formal: true, hideTime: true, related: true },
                    },
                    { var: "ws.SpaceShortResourceRelatedOccurrencePricingTable", options: { related: true } },
                    { var: "ws.SpaceShortResourceOccurrenceNoRsrvTimePricingTable", options: { hideTime: true } },
                    {
                        var: "ws.SpaceShortResourceRelatedOccurrenceNoRsrvTimePricingTable",
                        options: { hideTime: true, related: true },
                    },
                ],
                scopes: [scope.event, scope.organization, scope.reservation, scope.invoice],
            },
            {
                val: "occurrencesDetails",
                txt: "Occurrence Details",
                options: [
                    { type: "checkbox", id: "formal", label: "Formal Name" },
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                    { type: "checkbox", id: "hideTime", label: "Hide Times" },
                ],
                vars: [
                    { var: "ws.SpaceLongResourceOccurrenceTimeListTable", options: { formal: true } },
                    {
                        var: "ws.SpaceLongResourceRelatedOccurrenceTimeListTable",
                        options: { formal: true, related: true },
                    },
                    {
                        var: "ws.SpaceLongResourceOccurrenceNoRsrvTimeListTable",
                        options: { formal: true, hideTime: true },
                    },
                    {
                        var: "ws.SpaceLongResourceRelatedOccurrenceNoRsrvTimeListTable",
                        options: { formal: true, hideTime: true, related: true },
                    },
                    { var: "ws.SpaceShortResourceOccurrenceTimeListTable", options: {} },
                    { var: "ws.SpaceShortResourceRelatedOccurrenceTimeListTable", options: { related: true } },
                    { var: "ws.SpaceShortResourceOccurrenceNoRsrvTimeListTable", options: { hideTime: true } },
                    {
                        var: "ws.SpaceShortResourceRelatedOccurrenceNoRsrvTimeListTable",
                        options: { hideTime: true, related: true },
                    },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "ws.AcademicOccurrenceSummaryTable",
                txt: "Occurrences (Academic)",
                scopes: [scope.event, scope.organization],
            },
            {
                val: "billingDetails",
                txt: "Billing Details",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                    { type: "checkbox", id: "zeros", label: "Include Zeros" },
                    { type: "checkbox", id: "locations", label: "Only Locations" },
                    { type: "checkbox", id: "resources", label: "Only Resources" },
                ],
                vars: [
                    { var: "ws.EventBillingDetailsTable", options: { zeros: false, related: false } },
                    { var: "ws.EventBillingDetailsZeroTable", options: { zeros: true, related: false } },
                    { var: "ws.RelatedEventBillingDetailsTable", options: { zeros: false, related: true } },
                    { var: "ws.RelatedEventBillingDetailsZeroTable", options: { zeros: true, related: true } },
                    { var: "ws.EventLocationsBillingDetailsTable", options: { locations: true } },
                    { var: "ws.EventLocationsBillingDetailsZeroTable", options: { locations: true, zeros: true } },
                    { var: "ws.RelatedEventLocationsBillingDetailsTable", options: { locations: true, related: true } },
                    {
                        var: "ws.RelatedEventLocationsBillingDetailsZeroTable",
                        options: { locations: true, zeros: true, related: true },
                    },
                    { var: "ws.EventResourcesBillingDetailsTable", options: { resources: true } },
                    { var: "ws.EventResourcesBillingDetailsZeroTable", options: { resources: true, zeros: true } },
                    { var: "ws.RelatedEventResourcesBillingDetailsTable", options: { resources: true, related: true } },
                    {
                        var: "ws.RelatedEventResourcesBillingDetailsZeroTable",
                        options: { resources: true, zeros: true, related: true },
                    },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "billingHours",
                txt: "Billing Hours",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                    { type: "checkbox", id: "locations", label: "Only Locations" },
                    { type: "checkbox", id: "resources", label: "Only Resources" },
                ],
                vars: [
                    { var: "ws.EventBillingDetailsShowHoursTable", options: { related: false } },
                    { var: "ws.RelatedEventBillingDetailsShowHoursTable", options: { related: true } },
                    { var: "ws.EventLocationsBillingDetailsShowHoursTable", options: { locations: true } },
                    {
                        var: "ws.RelatedEventLocationsBillingDetailsShowHoursTable",
                        options: { locations: true, related: true },
                    },
                    { var: "ws.EventResourcesBillingDetailsShowHoursTable", options: { resources: true } },
                    {
                        var: "ws.RelatedEventResourcesBillingDetailsShowHoursTable",
                        options: { resources: true, related: true },
                    },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            {
                val: "invoiceDetails",
                txt: "Invoice Details",
                options: [
                    {
                        type: "checkbox",
                        id: "related",
                        label: "Related Events",
                        scopes: [scope.event, scope.organization],
                    },
                    { type: "checkbox", id: "zeros", label: "Include Zeros" },
                    { type: "checkbox", id: "locations", label: "Only Locations" },
                    { type: "checkbox", id: "resources", label: "Only Resources" },
                ],
                vars: [
                    { var: "ws.InvoiceDetailsTable", options: { zeros: false, related: false } },
                    { var: "ws.InvoiceDetailsZeroTable", options: { zeros: true, related: false } },
                    { var: "ws.RelatedInvoiceDetailsTable", options: { zeros: false, related: true } },
                    { var: "ws.RelatedInvoiceDetailsZeroTable", options: { zeros: true, related: true } },
                    { var: "ws.InvoiceLocationsDetailsTable", options: { locations: true } },
                    { var: "ws.InvoiceLocationsDetailsZeroTable", options: { locations: true, zeros: true } },
                    { var: "ws.RelatedInvoiceLocationsDetailsTable", options: { locations: true, related: true } },
                    {
                        var: "ws.RelatedInvoiceLocationsDetailsZeroTable",
                        options: { locations: true, zeros: true, related: true },
                    },
                    { var: "ws.InvoiceResourcesDetailsTable", options: { resources: true } },
                    { var: "ws.InvoiceResourcesDetailsZeroTable", options: { resources: true, zeros: true } },
                    { var: "ws.RelatedInvoiceResourcesDetailsTable", options: { resources: true, related: true } },
                    {
                        var: "ws.RelatedInvoiceResourcesDetailsZeroTable",
                        options: { resources: true, zeros: true, related: true },
                    },
                ],
                scopes: [scope.event, scope.organization, scope.invoice],
            },
        ],
        wsOrg: [
            { val: "ws.EventOrgAccountNumber", txt: "Account Number" },
            {
                val: "ws.EventOrgAdminAddress",
                txt: "Admin Address",
                options: [{ type: "checkbox", id: "flat", label: "Flat" }],
                vars: [
                    { var: "ws.EventOrgAdminAddress", options: { flat: false } },
                    { var: "ws.EventOrgAdminFlatAddress", options: { flat: true } },
                ],
            },
            {
                val: "ws.EventOrgBillingAddress",
                txt: "Billing Address",
                options: [{ type: "checkbox", id: "flat", label: "Flat" }],
                vars: [
                    { var: "ws.EventOrgBillingAddress", options: { flat: false } },
                    { var: "ws.EventOrgBillingFlatAddress", options: { flat: true } },
                ],
            },
            {
                val: "ws.EventOrgInvoiceAddress",
                txt: "Invoice Address",
                options: [{ type: "checkbox", id: "flat", label: "Flat" }],
                vars: [
                    { var: "ws.EventOrgInvoiceAddress", options: { flat: false } },
                    { var: "ws.EventOrgInvoiceFlatAddress", options: { flat: true } },
                ],
            },
            { val: "ws.EventOrgName", txt: "Organization Name" },
            { val: "ws.EventOrgTitle", txt: "Organization Title" },
        ],
        wsRsv: [
            { val: "ws.rsrv.r25:reservation_id", txt: "Reservation Id" },
            { val: "ws.rsrv.r25:reservation_state", txt: "Reservation State" },
            { val: "ws.rsrv.r25:profile_id", txt: "Segment Id" },
            { val: "ws.rsrv.r25:profile_name", txt: "Segment Name" },
            { val: "ws.rsrv.r25:profile_description", txt: "Segment Description" },
            { val: "ws.rsrv.r25:profile_comments", txt: "Segment Comments" },
            { val: "ws.rsrv.r25:expected_count", txt: "Expected Count" },
            { val: "ws.rsrv.r25:registered_count", txt: "Registered Count" },
            { val: "ws.rsrv.r25:last_mod_user", txt: "Last Mod User" },
            { val: "ws.rsrv.r25:comments", txt: "Reservation Comments" },
            { val: "ws.rsrv.r25:attendee_count", txt: "Reservation Attendee Count" },
        ],
        rsvDates: [
            { val: "ws.rsrv.r25:reservation_start_dt", txt: "Reservation Start Date", hasFormat: true },
            { val: "ws.rsrv.r25:pre_event_dt", txt: "Pre Event Date", hasFormat: true },
            { val: "ws.rsrv.r25:event_start_dt", txt: "Event Start Date", hasFormat: true },
            { val: "ws.rsrv.r25:event_end_dt", txt: "Event End Date", hasFormat: true },
            { val: "ws.rsrv.r25:post_event_dt", txt: "Post Event Date", hasFormat: true },
            { val: "ws.rsrv.r25:reservation_end_dt", txt: "Reservation End Date", hasFormat: true },
            { val: "ws.rsrv.r25:last_mod_dt", txt: "Last Mod Dt", hasFormat: true },
        ],
        todos: [
            { val: "todo.comment", txt: "ToDo Comment" },
            { val: "todo.dueDate", txt: "Due Date" },
            { val: "todo.assignToEmail", txt: "Assigned To Email" },
            { val: "todo.assignByEmail", txt: "Assigned By Email" },
            { val: "todo.status", txt: "ToDo Status" },
            { val: "todo.subType", txt: "ToDo Type" },
        ],
        resById: [
            resourceByVar("ID", "RsrcName_Inst", "Name | Instructions"),
            resourceByVar("ID", "RsrcNameQty_Inst", "Name, Quantity | Instructions"),
            resourceByVar("ID", "RsrcName_Qty_Inst", "Name | Quantity | Instructions"),
            resourceByVar("ID", "RsrcQtyName_Inst", "Quantity, Name | Instructions"),
            resourceByVar("ID", "RsrcQtyNameInst", "Quantity, Name, Instructions"),
            resourceByVar("ID", "RsrcNameQty", "Name, Quantity"),
            resourceByVar("ID", "RsrcQtyName", "Quantity, Name"),
        ],
        resByCat: [
            resourceByVar("Cat", "RsrcName_Inst", "Name | Instructions"),
            resourceByVar("Cat", "RsrcNameQty_Inst", "Name, Quantity | Instructions"),
            resourceByVar("Cat", "RsrcName_Qty_Inst", "Name | Quantity | Instructions"),
            resourceByVar("Cat", "RsrcQtyName_Inst", "Quantity, Name | Instructions"),
            resourceByVar("Cat", "RsrcQtyNameInst", "Quantity, Name, Instructions"),
            resourceByVar("Cat", "RsrcNameQty", "Name, Quantity"),
            resourceByVar("Cat", "RsrcQtyName", "Quantity, Name"),
        ],
        dateFormats: dateFormats,
        tableDocumentDateFormats: [
            ...dateFormats.filter(
                (format) => !/^(full|long)Time$/.test(format.val), // Reports does not support fullTime and longTime
            ),
            { val: "abbrevDate", txt: "Dow, M/d", ex: "Mon, 6/15" },
        ],
        tableDateFormats: [
            { val: "dateString", txt: "Date String", ex: "" },
            { val: "timeString", txt: "Time String", ex: "" },
            { val: "dateTimeString", txt: "Date and Time String", ex: "" },
            ...dateFormats,
        ],
        roles: [
            contactRoleVar("roleContactId", "Contact Id"),
            contactRoleVar("roleContactName", "Full Name"),
            contactRoleVar("roleContactFirst", "First Name"),
            contactRoleVar("roleContactLast", "Last Name"),
            contactRoleVar("roleContactMiddle", "Middle Name"),
            contactRoleVar("roleContactEmail", "Email"),
            contactRoleVar("roleContactAddr", "Address"),
            contactRoleVar("roleContactPhone", "Phone"),
            contactRoleVar("roleContactFax", "Fax"),
        ],
        orgRoles: [
            { val: "role", txt: "Contact Role" },
            { val: "itemId", txt: "Contact Id" },
            { val: "itemName", txt: "Contact Name" },
            { val: "itemTitle", txt: "Contact Title" },
            { val: "email", txt: "Contact Email" },
            { val: "address", txt: "Contact Address" },
            { val: "phone", txt: "Contact Phone" },
            { val: "fax", txt: "Contact Fax" },
        ],
        tables: [
            { val: "none", txt: "None" },
            { val: "occurrences", txt: "Occurrences", hasData: true, dataColumnLabel: "Format" },
            { val: "locations", txt: "Locations" },
            { val: "resources", txt: "Resources" },
            { val: "requirements", txt: "Requirements" },
            { val: "customAttributesList", txt: "Custom Attributes" },
            { val: "contacts", txt: "Contacts" },
            { val: "categories", txt: "Categories" },
            // { val: "tasks", txt: "Tasks", hasData: true, dataColumnLabel: 'Format' }, // Leave out tasks until workflow is done
            { val: "relationships", txt: "Related Events", hasData: true, dataColumnLabel: "Format" },
            { val: "billItems", txt: "Bill Items", hasData: true, dataColumnLabel: "Data" },
            { val: "segments", txt: "Segments", hasData: true, dataColumnLabel: "Format" },
            { val: "organizationsList", txt: "Organizations" },
        ],
        documentTables: [
            { val: "none", txt: "None" },
            { val: "occurrences", txt: "Occurrences", hasData: true, dataColumnLabel: "Format" },
            // { val: "locations", txt: "Locations" }, // Currently not available in document management
            // { val: "resources", txt: "Resources" }, // Currently not available in document management
            { val: "requirements", txt: "Requirements" },
            { val: "customAttributesList", txt: "Custom Attributes" },
            { val: "contacts", txt: "Contacts" },
            { val: "categories", txt: "Categories" },
            {
                val: "billItems",
                txt: "Bill Items",
                hasData: true,
                dataColumnLabel: "Data",
                scopes: [scope.event, scope.organization, scope.invoice],
            },
            { val: "relationships", txt: "Related Events", hasData: true, dataColumnLabel: "Format" },
            { val: "segments", txt: "Segments", hasData: true, dataColumnLabel: "Format" },
            { val: "organizationsList", txt: "Organizations" },
        ],
        occurrences: [...occurrenceVariables],
        occurrencesResources: [
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            { val: "instructions", txt: "Instructions", width: 30 },
            { val: "quantity", txt: "Quantity", width: 10 },
            ...occurrenceVariables,
            ...occurrenceResourcePricingVariables,
        ],
        occurrencesResourcesPref: [
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            { val: "quantity", txt: "Quantity", width: 10 },
            ...occurrenceVariables,
            ...occurrenceResourcePricingVariables,
        ],
        occurrencesLocations: [
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            { val: "space.formal_name", txt: "Formal Name", width: 20, wsVal: "formal_name" },
            { val: "instructions", txt: "Instructions", width: 30 },
            { val: "attendance", txt: "Attendance", width: 10 },
            { val: "layout_name", txt: "Layout", width: 20 },
            { val: "selected_layout_capacity", txt: "Layout Capacity", width: 10 },
            { val: "default_layout_capacity", txt: "Layout Default Capacity", width: 10 },
            { val: "space.max_capacity", txt: "Max Capacity", width: 10, wsVal: "max_capacity" },
            { val: "space.partition_name", txt: "Partition", width: 20, wsVal: "partition_name" },
            ...occurrenceVariables,
            ...occurrenceLocationPricingVariables,
        ],
        occurrencesLocationsPref: [
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            ...occurrenceVariables,
            ...occurrenceLocationPricingVariables,
        ],
        occurrencesLocationsResources: [
            { val: "type", txt: "Type", width: 10 },
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            { val: "space.formal_name", txt: "Formal Name", width: 20, wsVal: "formal_name" },
            { val: "instructions", txt: "Instructions", width: 30 },
            { val: "quantity", txt: "Quantity", width: 10 },
            { val: "attendance", txt: "Attendance", width: 10 },
            { val: "layout_name", txt: "Layout", width: 20 },
            { val: "selected_layout_capacity", txt: "Layout Capacity", width: 10 },
            { val: "default_layout_capacity", txt: "Layout Default Capacity", width: 10 },
            { val: "space.max_capacity", txt: "Max Capacity", width: 10, wsVal: "max_capacity" },
            { val: "space.partition_name", txt: "Partition", width: 20, wsVal: "partition_name" },
            ...occurrenceVariables,
            ...occurrenceLocationPricingVariables,
            ...occurrenceResourcePricingVariables,
        ],
        occurrencesLocationsResourcesPref: [
            { val: "type", txt: "Type", width: 10 },
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            { val: "quantity", txt: "Quantity", width: 10 },
            ...occurrenceVariables,
            ...occurrenceLocationPricingVariables,
            ...occurrenceResourcePricingVariables,
        ],
        locations: [
            { val: "itemId", txt: "ID", width: 10 },
            { val: "itemName", txt: "Name", width: 30 },
            { val: "itemFormalName", txt: "Formal Name", width: 20 },
            { val: "instructions", txt: "Instructions", width: 30 },
            { val: "attendance", txt: "Attendance", width: 10 },
            { val: "layout", txt: "Layout", width: 20 },
            { val: "layoutCapacity", txt: "Layout Capacity", width: 10 },
            { val: "layoutDefaultCapacity", txt: "Layout Default Capacity", width: 10 },
            { val: "maxCapacity", txt: "Max Capacity", width: 10 },
            { val: "partitionName", txt: "Partition", width: 20 },
            { val: "profileName", txt: "Segment", width: 20 },
        ],
        resources: [
            { val: "itemId", txt: "ID", width: 10 },
            { val: "itemName", txt: "Name", width: 30 },
            { val: "instructions", txt: "Instructions", width: 30 },
            { val: "quantity", txt: "Quantity", width: 10 },
            { val: "profileName", txt: "Segment", width: 20 },
        ],
        requirements: [
            { val: "itemId", txt: "ID", width: 10, wsVal: "requirement_id" },
            { val: "itemName", txt: "Name", width: 30, wsVal: "requirement_name" },
            { val: "isActive", txt: "Is Active", width: 10 },
            { val: "comment", txt: "Comment", width: 30, wsVal: "req_comment" },
            { val: "count", txt: "Quantity", width: 10, wsVal: "requirement_count" },
        ],
        tasks: [
            { val: "id", txt: "ID", width: 10 },
            { val: "name", txt: "Name", width: 30 },
            { val: "type", txt: "Type", width: 20 },
            { val: "state", txt: "Status", width: 20 },
            { val: "flagged", txt: "Flagged", width: 10 },
            { val: "comment", txt: "Comment", width: 30 },
            { val: "assignedTo", txt: "Assigned To", width: 20 },
            { val: "respondByDate", txt: "Respond By", dataType: "format", width: 30 },
            { val: "firstDate", txt: "First Date", dataType: "format", width: 30 },
        ],
        billItems: [
            { val: "name", txt: "Item", width: 20, wsVal: "bill_item_name" },
            { val: "listPrice", txt: "List Price", width: 10, wsVal: "list_price" },
            { val: "adjustmentAmount", txt: "Adjustment Amount", width: 10, wsVal: "adjustment_amount" },
            { val: "adjustmentName", txt: "Adjustment Name", width: 10, wsVal: "adjustment_name" },
            { val: "price", txt: "Price", width: 10, wsVal: "taxable_amt" },
            { val: "totalTax", txt: "Taxes", width: 10, wsVal: "total_tax" },
            {
                val: "totalTaxType.$data",
                txt: "Total Tax (specific)",
                header: "Total Tax ($label)",
                width: 20,
                wsVal: "total_tax_type.$data",
                criterion: "taxSchedules",
                dataType: "searchCriteriaItem",
            },
            { val: "totalCharge", txt: "Total", width: 10, wsVal: "total_charge" },
            { val: "totalCount", txt: "Count", width: 10, wsVal: "total_count" },
            { val: "chargeTo", txt: "Charge To", width: 20, wsVal: "charge_to_name" },
            { val: "rateName", txt: "Rate Schedule", width: 20, wsVal: "rate_name" },
            { val: "rateGroup", txt: "Rate Group", width: 20, wsVal: "rate_group_name" },
            { val: "rateDescription", txt: "Rate Description", width: 20, wsVal: "rate_description" },
            { val: "creditAccount", txt: "Credit Account", width: 30, wsVal: "credit_account_number" },
            { val: "debitAccount", txt: "Debit Account", width: 30, wsVal: "debit_account_number" },
            { val: "type", txt: "Type", width: 20, wsVal: "bill_item_type_name" },
        ],
        relationships: [
            { val: "id", txt: "ID", width: 10, wsVal: "content_event_id" },
            { val: "name", txt: "Event Name", width: 30, wsVal: "content_event_name" },
            { val: "title", txt: "Event Title", width: 20, wsVal: "content_event_title" },
            { val: "startDate", txt: "Start Date", dataType: "format", width: 30, wsVal: "content_start_date" },
            { val: "endDate", txt: "End Date", dataType: "format", width: 30, wsVal: "content_end_date" },
        ],
        categories: [
            { val: "id", txt: "ID", width: 10, wsVal: "category_id" },
            { val: "name", txt: "Name", width: 30, wsVal: "category_name" },
            { val: "isActive", txt: "Is Active", width: 10 },
        ],
        customAttributesList: [
            { val: "id", txt: "ID", width: 10, wsVal: "attribute_id" },
            { val: "name", txt: "Name", width: 30, wsVal: "attribute_name" },
            { val: "isActive", txt: "Is Active", width: 10 },
            { val: "type", txt: "Type", width: 10, wsVal: "attribute_type_name" },
            { val: "value", txt: "Value", width: 30, wsVal: "attribute_value" },
        ],
        contacts: [
            { val: "role", txt: "Contact Role", width: 20, wsVal: "role_name" },
            { val: "roleId", txt: "Contact Role ID", width: 10, wsVal: "role_id" },
            { val: "name", txt: "Full Name", width: 30, wsVal: "contact_name" },
            { val: "firstName", txt: "First Name", width: 20, wsVal: "contact_first_name" },
            { val: "middleName", txt: "Middle Name", width: 20, wsVal: "contact_middle_name" },
            { val: "lastName", txt: "Last Name", width: 20, wsVal: "contact_last_name" },
            { val: "email", txt: "Email", width: 30 },
            { val: "address", txt: "Address", width: 40, wsVal: "formatted_address" },
            { val: "phone", txt: "Phone", width: 20 },
            { val: "fax", txt: "Fax", width: 20 },
        ],
        segments: [
            { val: "name", txt: "Name", width: 20, wsVal: "profile_name" },
            { val: "comments", txt: "Comments", width: 30, wsVal: "profile_comments" },
            { val: "expected", txt: "Expected", width: 10, wsVal: "expected_count" },
            { val: "registered", txt: "Registered", width: 10, wsVal: "registered_count" },
            { val: "setupDuration", txt: "Setup Duration", width: 20 },
            { val: "preEventDuration", txt: "Pre-Event Duration", width: 20 },
            { val: "eventDuration", txt: "Event Duration", width: 20 },
            { val: "postEventDuration", txt: "Post-Event Duration", width: 20 },
            { val: "takedownDuration", txt: "Takedown Duration", width: 20 },
            { val: "preToPostDuration", txt: "Pre-Event to Post-Event Duration", width: 20 },
            { val: "setupToTakedownDuration", txt: "Setup to Takedown Duration", width: 20 },
            { val: "startDate", txt: "Start Date", dataType: "format", width: 30, wsVal: "init_start_dt" },
            { val: "endDate", txt: "End Date", dataType: "format", width: 30, wsVal: "init_end_dt" },
        ],
        organizationsList: [
            { val: "id", txt: "ID", width: 10, wsVal: "organization_id" },
            { val: "name", txt: "Name", width: 30, wsVal: "organization_name" },
            { val: "title", txt: "Title", width: 20, wsVal: "organization_title" },
            { val: "rating", txt: "Rating", width: 10, wsVal: "organization_rating" },
            { val: "accountNumber", txt: "Account Number", width: 20, wsVal: "account_number" },
            { val: "type", txt: "Type", width: 20, wsVal: "organization_type_name" },
            { val: "isPrimary", txt: "Is Primary", width: 10 },
        ],
        event: [
            { val: "eventId", txt: "Event ID", noPre: true },
            { val: "eventLink", txt: "Event Link", noPre: true },
            { val: "eventUrl", txt: "Event URL", noPre: true },
            { val: "eventLocator", txt: "Event Reference", noPre: true },
            { val: "eventName", txt: "Event Name" },
            { val: "eventTitle", txt: "Event Title" },
            { val: "versionNumber", txt: "Version Number" },
            { val: "eventTypeId", txt: "Event Type ID" },
            { val: "eventTypeName", txt: "Event Type Name" },
            { val: "description", txt: "Event Description" },
            { val: "internalNote", txt: "Internal Notes" },
            { val: "confirmationNote", txt: "Confirmation Notes" },
            { val: "comment", txt: "Comments" },
            { val: "expectedCount", txt: "Expected Head Count" },
            { val: "registeredCount", txt: "Registered Head Count" },
            { val: "state", txt: "Event State ID" },
            { val: "stateName", txt: "Event State Name" },
            { val: "requestorEmail", txt: "Requestor Email" },
            { val: "schedulerEmail", txt: "Scheduler Email" },
            { val: "currentContactName", txt: "Current Contact Name" },

            { val: "primaryOrganization.itemId", txt: "Primary Org ID" },
            { val: "primaryOrganization.itemName", txt: "Primary Org Name" },
            { val: "primaryOrganization.itemTitle", txt: "Primary Org Title" },
            { val: "primaryOrganization.type", txt: "Primary Org Type" },
            { val: "primaryOrganization.rating", txt: "Primary Org Rating" },
            { val: "primaryOrganization.accountNumber", txt: "Primary Org Account Number" },

            { val: "dates.creationDateString", txt: "Event Creation Date" },
            { val: "dates.creationDateTimeString", txt: "Event Creation Date and Time" },
            { val: "dates.creationTimeString", txt: "Event Creation Time" },

            { val: "dates.currentLocalDateString", txt: "Current Local Date" },
            { val: "dates.currentLocalDateTimeString", txt: "Current Local Date and Time" },
            { val: "dates.currentLocalTimeString", txt: "Current Local Time" },

            { val: "dates.lastModDateString", txt: "Last Edit Date" },
            { val: "dates.lastModDateTimeString", txt: "Last Edit Date and Time" },
            { val: "dates.lastModTimeString", txt: "Last Edit Time" },

            { val: "dates.eventSetupDateString", txt: "Setup Date" },
            { val: "dates.eventSetupDateTimeString", txt: "Setup Date and Time" },
            { val: "dates.eventSetupTimeString", txt: "Setup Time" },

            { val: "dates.preEventDateString", txt: "Pre-Event Date" },
            { val: "dates.preEventDateTimeString", txt: "Pre-Event Date and Time" },
            { val: "dates.preEventTimeString", txt: "Pre-Event Time" },

            { val: "dates.eventStartDateString", txt: "Start Date" },
            { val: "dates.eventStartDateTimeString", txt: "Start Date and Time" },
            { val: "dates.eventStartTimeString", txt: "Start Time" },

            { val: "dates.eventEndDateString", txt: "End Date" },
            { val: "dates.eventEndDateTimeString", txt: "End Date and Time" },
            { val: "dates.eventEndTimeString", txt: "End Time" },

            { val: "dates.postEventDateString", txt: "Post-Event Date" },
            { val: "dates.postEventDateTimeString", txt: "Post-Event Date and Time" },
            { val: "dates.postEventTimeString", txt: "Post-Event Time" },

            { val: "dates.eventTakedownDateString", txt: "Takedown Date" },
            { val: "dates.eventTakedownDateTimeString", txt: "Takedown Date and Time" },
            { val: "dates.eventTakedownTimeString", txt: "Takedown Time" },

            { val: "durations.setup", txt: "Setup Duration" },
            { val: "durations.preEvent", txt: "Pre-Event Duration" },
            { val: "durations.event", txt: "Event Duration" },
            { val: "durations.postEvent", txt: "Post-Event Duration" },
            { val: "durations.takedown", txt: "Takedown Duration" },
            { val: "durations.preToPost", txt: "Pre-Event to Post-Event Duration" },
            { val: "durations.setupToTakedown", txt: "Setup to Takedown Duration" },

            { val: "occurrencesString", txt: "Occurrence Table" },
            { val: "occurrencesStringCSV", txt: "Occurrence List" },
            { val: "occurrencesWithLocationsString", txt: "Occurrence and Location Table" },
            { val: "occurrencesWithResourcesString", txt: "Occurrence and Resource Table" },
            { val: "occurrencesWithLocationsAndResourcesString", txt: "Occurrence Location Resource Table" },

            { val: "locationsString", txt: "Location Table" },
            { val: "locationsStringCSV", txt: "Location List" },
            { val: "resourcesString", txt: "Resource Table" },
            { val: "resourcesStringCSV", txt: "Resource List" },

            { val: "prefOccurrencesWithLocationsString", txt: "Pref Occurrence and Location Table" },
            { val: "prefOccurrencesWithResourcesString", txt: "Pref Occurrence and Resource Table" },
            {
                val: "prefOccurrencesWithLocationsAndResourcesString",
                txt: "Pref Occurrence Location Resource Table",
            },

            { val: "prefLocationsString", txt: "Pref Location Table" },
            { val: "prefLocationsStringCSV", txt: "Pref Location List" },

            { val: "prefResourcesString", txt: "Pref Resource Table" },
            { val: "prefResourcesStringCSV", txt: "Pref Resource List" },

            { val: "currentGroupId", txt: "Current Group ID" },
            { val: "template", txt: "Current Template Name" },
            { val: "scenario", txt: "Triggering Scenario Name" },
            { val: "homeUrl", txt: "Home URL", noPre: true },
        ],
        eventListing: [
            { isGroup: true, groupName: "General" },
            { val: "event_name", txt: "Name", grp: "Event Details", width: 3 },
            { val: "event_title", txt: "Title", grp: "Event Details", width: 2 },
            { val: "alien_uid", txt: "Alien UID", grp: "Event Details", width: 3 },
            { val: "event_priority", txt: "Priority", grp: "Event Details", width: 1 },
            { val: "favorite", txt: "Favorite", grp: "Event Details", width: 1 },
            { val: "start_date", txt: "Start Date", grp: "Event Details", width: 2 },
            { val: "start_time", txt: "Start Time", grp: "Event Details", width: 1 },
            { val: "end_date", txt: "End Date", grp: "Event Details", width: 2 },
            { val: "end_time", txt: "End Time", grp: "Event Details", width: 1 },
            { val: "occurrence_count", txt: "Occurrence Count", grp: "Event Details", width: 1 },
            { val: "event_type_name", txt: "Event Type", grp: "Event Details", width: 2 },
            { val: "state_name", txt: "State", grp: "Event Details", width: 2 },
            { val: "event_locator", txt: "Locator", grp: "Event Details", width: 2 },
            { val: "cabinet_name", txt: "Cabinet", grp: "Event Details", width: 2 },
            { val: "last_mod_user", txt: "Last Modified By", grp: "Event Details", width: 2 },
            { val: "last_mod_dt", txt: "Last Modified Date", grp: "Event Details", width: 3 },
            { val: "creation_dt", txt: "Creation Date", grp: "Event Details", width: 3 },
            { val: "owner", txt: "Event Owner", grp: "Event Details", width: 2 },
            { val: "confirmation_text", txt: "Confirmation Text", grp: "Event Details", width: 2 },
            { val: "description", txt: "Description", grp: "Event Details", width: 2 },
            { val: "internal_notes", txt: "Internal Notes", grp: "Event Details", width: 2 },
            {
                val: "attribute_value.$data",
                txt: "Attribute Value",
                header: "$label",
                grp: "Event Details",
                criterion: "eventCustomAttributes",
                dataType: "searchCriteriaItem",
                width: 2,
            },

            { isGroup: true, groupName: "Comma Separated Lists" },
            { val: "category_name", txt: "Categories", grp: "Comma Separated Lists", width: 3 },
            { val: "space_name", txt: "Locations", grp: "Comma Separated Lists", width: 3 },
            { val: "formal_name", txt: "Locations Formal", grp: "Comma Separated Lists", width: 3 },
            { val: "location_preference", txt: "Location Requests", grp: "Comma Separated Lists", width: 3 },
            { val: "building_name", txt: "Buildings", grp: "Comma Separated Lists", width: 3 },
            { val: "resource_name", txt: "Resources", grp: "Comma Separated Lists", width: 3 },
            { val: "resource_name_qty", txt: "Resources With Quantity", grp: "Comma Separated Lists", width: 3 },
            { val: "resource_preference", txt: "Resource Requests", grp: "Comma Separated Lists", width: 3 },

            { isGroup: true, groupName: "Primary Organization" },
            { val: "organization_name", txt: "Primary Org Name", grp: "Primary Organization", width: 2 },
            { val: "organization_title", txt: "Primary Org Title", grp: "Primary Organization", width: 2 },
            { val: "account_number", txt: "Primary Org Account", grp: "Primary Organization", width: 2 },
            { val: "organization_rating", txt: "Primary Org Rating", grp: "Primary Organization", width: 2 },
            { val: "organization_type_name", txt: "Primary Org Type", grp: "Primary Organization", width: 2 },

            { isGroup: true, groupName: "Contact Roles" },
            {
                val: "role_contact_name.$data",
                txt: "Contact Name",
                header: "$label Name",
                grp: "Contact Roles",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            {
                val: "role_formatted_address.$data",
                txt: "Contact Address",
                header: "$label Address",
                grp: "Contact Roles",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 3,
            },
            {
                val: "role_email.$data",
                txt: "Contact Email",
                header: "$label Email",
                grp: "Contact Roles",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 3,
            },
            {
                val: "role_phone.$data",
                txt: "Contact Phone",
                header: "$label Phone",
                grp: "Contact Roles",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            {
                val: "role_fax.$data",
                txt: "Contact Fax",
                header: "$label Fax",
                grp: "Contact Roles",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },

            { isGroup: true, groupName: "Pricing" },
            { val: "total_list_price", txt: "List Price", grp: "Pricing", width: 1 },
            { val: "total_line_item_adj", txt: "Line Adjustments", grp: "Pricing", width: 1 },
            { val: "total_taxable_amt", txt: "Taxable Amount", grp: "Pricing", width: 1 },
            {
                val: "total_tax_type.$data",
                txt: "Total Tax (specific)",
                header: "Total Tax ($label)",
                grp: "Pricing",
                width: 2,
                criterion: "taxSchedules",
                dataType: "searchCriteriaItem",
            },
            { val: "total_tax", txt: "Taxes", grp: "Pricing", width: 1 },
            { val: "total_charge", txt: "Charges", grp: "Pricing", width: 1 },
            { val: "total_event_adj", txt: "Adjustments", grp: "Pricing", width: 1 },
            { val: "grand_total", txt: "Grand Total", grp: "Pricing", width: 1 },
        ],
        reservationListing: [
            { val: "date", txt: "Day", width: 2 },

            { isGroup: true, groupName: "Reservation Dates" },
            { val: "reservation_start_dt", txt: "Setup", grp: "Reservation Dates", width: 2 },
            { val: "pre_event_dt", txt: "Pre-Event", grp: "Reservation Dates", width: 2 },
            { val: "event_start_dt", txt: "Event Start", grp: "Reservation Dates", width: 2 },
            { val: "event_end_dt", txt: "Event End", grp: "Reservation Dates", width: 2 },
            { val: "post_event_dt", txt: "Post-Event", grp: "Reservation Dates", width: 2 },
            { val: "reservation_end_dt", txt: "Takedown", grp: "Reservation Dates", width: 2 },

            { isGroup: true, groupName: "Reservation Details" },
            { val: "reservation_state", txt: "Reservation State", grp: "Reservation Details", width: 2 },
            { val: "space_name", txt: "Location", grp: "Reservation Details", width: 2 },
            { val: "formal_name", txt: "Formal Name", grp: "Reservation Details", width: 2 },
            { val: "layout_name", txt: "Layout", grp: "Reservation Details", width: 2 },
            { val: "building_name", txt: "Building Name", grp: "Reservation Details", width: 2 },
            { val: "expected_count", txt: "Exp. Head Count", grp: "Reservation Details", width: 1 },
            { val: "registered_count", txt: "Reg. Head Count", grp: "Reservation Details", width: 1 },
            { val: "actual_count", txt: "Act. Head Count", grp: "Reservation Details", width: 1 },
            { val: "attendee_count", txt: "Attendee Count", grp: "Reservation Details", width: 1 },
            { val: "max_capacity", txt: "Capacity", grp: "Reservation Details", width: 1 },
            { val: "partition_name", txt: "Partition", grp: "Reservation Details", width: 2 },
            { val: "default_layout_name", txt: "Default Layout", grp: "Reservation Details", width: 2 },
            { val: "default_layout_capacity", txt: "Default Layout Capacity", grp: "Reservation Details", width: 1 },
            { val: "selected_layout_capacity", txt: "Layout Capacity", grp: "Reservation Details", width: 1 },
            { val: "space_instructions", txt: "Location Instructions", grp: "Reservation Details", width: 3 },
            { val: "resource_name", txt: "Resource List", grp: "Reservation Details", width: 3 },
            { val: "resource_name_qty", txt: "Resource Quantity List", grp: "Reservation Details", width: 3 },
            { val: "comments", txt: "Comments", grp: "Reservation Details", width: 3 },
            { val: "last_mod_user", txt: "Last Modified By", grp: "Reservation Details", width: 2 },
            { val: "last_mod_dt", txt: "Last Modified Date", grp: "Reservation Details", width: 3 },

            { isGroup: true, groupName: "Segment Details" },
            { val: "profile_name", txt: "Segment Name", grp: "Segment Details", width: 3 },
            { val: "profile_description", txt: "Segment Description", grp: "Segment Details", width: 3 },
            { val: "profile_comments", txt: "Segment Comments", grp: "Segment Details", width: 3 },

            { isGroup: true, groupName: "Event Details" },
            { val: "favorite", txt: "Is Favorite", grp: "Event Details", width: 1 },
            { val: "event_name", txt: "Event Name", grp: "Event Details", width: 3 },
            { val: "event_title", txt: "Event Title", grp: "Event Details", width: 3 },
            { val: "event_locator", txt: "Reference #", grp: "Event Details", width: 2 },
            { val: "event_type_name", txt: "Event Type", grp: "Event Details", width: 2 },
            { val: "state_name", txt: "Event State", grp: "Event Details", width: 2 },
            { val: "event_creation_dt", txt: "Event Creation Date", grp: "Event Details", width: 3 },
            { val: "organization_name", txt: "Primary Organization", grp: "Event Details", width: 2 },
            { val: "organization_type_name", txt: "Primary Organization Type", grp: "Event Details", width: 2 },

            {
                val: "role_contact_name.$data",
                txt: "Contact Name",
                header: "$label Name",
                grp: "Event Details",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            {
                val: "role_formatted_address.$data",
                txt: "Contact Address",
                header: "$label Address",
                grp: "Event Details",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 3,
            },
            {
                val: "role_email.$data",
                txt: "Contact Email",
                header: "$label Email",
                grp: "Event Details",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 3,
            },
            {
                val: "role_phone.$data",
                txt: "Contact Phone",
                header: "$label Phone",
                grp: "Event Details",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            {
                val: "role_fax.$data",
                txt: "Contact Fax",
                header: "$label Fax",
                grp: "Event Details",
                criterion: "eventRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },

            { val: "confirmation_text", txt: "Confirmation Text", grp: "Event Details", width: 3 },
            { val: "description", txt: "Description", grp: "Event Details", width: 3 },
            { val: "internal_notes", txt: "Internal Notes", grp: "Event Details", width: 3 },

            {
                val: "attribute_value.$data",
                txt: "Attribute Value",
                header: "$label",
                grp: "Event Details",
                criterion: "eventCustomAttributes",
                dataType: "searchCriteriaItem",
                width: 2,
            },
        ],
        locationListing: [
            { isGroup: true, groupName: "General" },
            { val: "space_name", txt: "Name", grp: "General", width: 3 },
            { val: "formal_name", txt: "Formal Name", grp: "General", width: 3 },
            { val: "favorite", txt: "Is Favorite", grp: "General", width: 1 },
            { val: "partition_name", txt: "Partition", grp: "General", width: 2 },
            { val: "building_name", txt: "Building", grp: "General", width: 2 },
            { val: "fill_ratio", txt: "Fill Ratio", grp: "General", width: 1 },
            { val: "always_shared", txt: "Always Shared", grp: "General", width: 1 },
            { val: "max_capacity", txt: "Max Capacity", grp: "General", width: 1 },
            { val: "comments", txt: "Comments", grp: "General", width: 3 },
            { val: "instructions", txt: "Instructions", grp: "General", width: 3 },
            {
                val: "attribute_value.$data",
                txt: "Attribute Value",
                header: "$label",
                grp: "General",
                criterion: "locationCustomAttributes",
                dataType: "searchCriteriaItem",
                width: 2,
            },

            { val: "last_mod_user", txt: "Last Modified By", grp: "General", width: 2 },
            { val: "last_mod_dt", txt: "Last Modified Date", grp: "General", width: 3 },

            { isGroup: true, groupName: "Comma Separated Lists" },
            { val: "layout_name", txt: "Layouts", grp: "Comma Separated Lists", width: 3 },
            { val: "layout_name_capacity", txt: "Layouts With Capacity", grp: "Comma Separated Lists", width: 3 },
            { val: "feature_name", txt: "Features", grp: "Comma Separated Lists", width: 3 },
            { val: "feature_name_qty", txt: "Features With Quantity", grp: "Comma Separated Lists", width: 3 },
            { val: "category_name", txt: "Categories", grp: "Comma Separated Lists", width: 3 },
            {
                val: "related_space_name.$data",
                txt: "Related Locations",
                header: "$label",
                grp: "Comma Separated Lists",
                width: 3,
                dataType: "options",
                options: [
                    { itemId: 1, itemName: "Subdivision Of" },
                    { itemId: 2, itemName: "Close To" },
                    { itemId: 3, itemName: "Blocked By" },
                    { itemId: 4, itemName: "Also Assigns" },
                    { itemId: 5, itemName: "Divides Into" },
                ],
            },

            { isGroup: true, groupName: "Default Layout" },
            { val: "default_layout_name", txt: "Default Layout", grp: "Default Layout", width: 2 },
            { val: "default_layout_capacity", txt: "Default Capacity", grp: "Default Layout", width: 1 },
            { val: "default_layout_setup_tm", txt: "Default Setup", grp: "Default Layout", width: 2 },
            { val: "default_layout_tdown_tm", txt: "Default Takedown", grp: "Default Layout", width: 2 },
            { val: "default_layout_photo", txt: "Default Photo", grp: "Default Layout", width: 2 },
            { val: "default_layout_diagram", txt: "Default Diagram", grp: "Default Layout", width: 2 },
            { val: "default_layout_description", txt: "Default Description", grp: "Default Layout", width: 3 },
            { val: "default_layout_instruction", txt: "Default Instructions", grp: "Default Layout", width: 3 },

            { isGroup: true, groupName: "Express Scheduling" },
            { val: "direct_schedule_enabled", txt: "Express Enabled", grp: "Express Scheduling", width: 1 },
            { val: "direct_schedule_type_name", txt: "Express Event Type", grp: "Express Scheduling", width: 2 },
            { val: "direct_schedule_duration", txt: "Express Max Duration", grp: "Express Scheduling", width: 2 },

            { isGroup: true, groupName: "Location Scheduler" },
            { val: "scheduler_name", txt: "Scheduler Name", grp: "Location Scheduler", width: 2 },
            { val: "scheduler_title", txt: "Scheduler Title", grp: "Location Scheduler", width: 2 },
            { val: "scheduler_email", txt: "Scheduler Email", grp: "Location Scheduler", width: 2 },
            { val: "scheduler_phone", txt: "Scheduler Phone", grp: "Location Scheduler", width: 2 },
        ],
        resourceListing: [
            { isGroup: true, groupName: "General" },
            { val: "resource_name", txt: "Name", grp: "General", width: 3 },
            { val: "favorite", txt: "Is Favorite", grp: "General", width: 1 },
            { val: "comments", txt: "Comments", grp: "General", width: 3 },
            { val: "instructions", txt: "Instructions", grp: "General", width: 3 },
            {
                val: "attribute_value.$data",
                txt: "Attribute Value",
                header: "$label",
                grp: "General",
                criterion: "resourceCustomAttributes",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            { val: "stock_level", txt: "Stock Level", grp: "General", width: 2 },
            { val: "last_mod_user", txt: "Last Modified By", grp: "General", width: 2 },
            { val: "last_mod_dt", txt: "Last Modified Date", grp: "General", width: 3 },

            { isGroup: true, groupName: "Comma Separated Lists" },
            { val: "category_name", txt: "Categories", grp: "Comma Separated Lists", width: 3 },
            {
                val: "related_resource_name.$data",
                txt: "Related Resources",
                header: "$label",
                grp: "Comma Separated Lists",
                width: 3,
                dataType: "options",
                options: [
                    { itemId: 8, itemName: "Substitute With" },
                    { itemId: 9, itemName: "Also Assign" },
                ],
            },
        ],
        organizationListing: [
            { isGroup: true, groupName: "General" },
            { val: "organization_name", txt: "Name", grp: "General", width: 3 },
            { val: "organization_title", txt: "Title", grp: "General", width: 3 },
            { val: "type_name", txt: "Type", grp: "General", width: 2 },
            { val: "favorite", txt: "Is Favorite", grp: "General", width: 1 },
            { val: "admin_formatted_address", txt: "Admin Address", grp: "General", width: 3 },
            { val: "admin_phone", txt: "Admin Phone", grp: "General", width: 2 },
            { val: "admin_fax", txt: "Admin Fax", grp: "General", width: 2 },
            { val: "billing_formatted_address", txt: "Billing Address", grp: "General", width: 3 },
            { val: "billing_phone", txt: "Billing Phone", grp: "General", width: 2 },
            { val: "billing_fax", txt: "Billing Fax", grp: "General", width: 2 },
            { val: "organization_number", txt: "Number", grp: "General", width: 2 },
            { val: "rating_name", txt: "Rating", grp: "General", width: 2 },
            { val: "comments", txt: "Comments", grp: "General", width: 3 },
            {
                val: "attribute_value.$data",
                txt: "Attribute Value",
                header: "$label",
                grp: "General",
                criterion: "organizationCustomAttributes",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            { val: "last_mod_user", txt: "Last Modified By", grp: "General", width: 2 },
            { val: "last_mod_dt", txt: "Last Modified Date", grp: "General", width: 3 },

            { isGroup: true, groupName: "Comma Separated Lists" },
            { val: "category_name", txt: "Categories", grp: "Comma Separated Lists", width: 3 },

            { isGroup: true, groupName: "Contacts" },
            {
                val: "role_contact_name.$data",
                txt: "Contact Name",
                header: "$label Name",
                grp: "Contacts",
                criterion: "organizationRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            {
                val: "role_contact_formatted_address.$data",
                txt: "Contact Address",
                header: "$label Address",
                grp: "Contacts",
                criterion: "organizationRoles",
                dataType: "searchCriteriaItem",
                width: 3,
            },
            {
                val: "role_contact_email.$data",
                txt: "Contact Email",
                header: "$label Email",
                grp: "Contacts",
                criterion: "organizationRoles",
                dataType: "searchCriteriaItem",
                width: 3,
            },
            {
                val: "role_contact_phone.$data",
                txt: "Contact Phone",
                header: "$label Phone",
                grp: "Contacts",
                criterion: "organizationRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
            {
                val: "role_contact_fax.$data",
                txt: "Contact Fax",
                header: "$label Fax",
                grp: "Contacts",
                criterion: "organizationRoles",
                dataType: "searchCriteriaItem",
                width: 2,
            },
        ],
        invoicePaymentSummary: [
            { isGroup: true, groupName: "General" },
            { val: "billName", txt: "Invoice Name", grp: "General", width: 3 },
            { val: "organizationName", txt: "Organization", grp: "General", width: 3 },
            { val: "invoiceId", txt: "Invoice Number", grp: "General", width: 2 },
            { val: "eventName", txt: "Event Name", grp: "General", width: 3 },
            { val: "eventReference", txt: "Event Reference", grp: "General", width: 2 },
            { val: "organizationAcctCode", txt: "Account Code", grp: "General", width: 2 },
            { val: "rsrvStartDt", txt: "Start Date", grp: "General", width: 2 },
            { val: "rsrvEndDt", txt: "End Date", grp: "General", width: 2 },
            { val: "billDate", txt: "Pricing Date", grp: "General", width: 2 },
            {
                val: "eventAttributeValue.$data",
                txt: "Event Attribute",
                header: `Event Attribute ($label)`,
                grp: "General",
                width: 2,
                criterion: "eventCustomAttributes",
                dataType: "searchCriteriaItem",
            },
            {
                val: "organizationAttributeValue.$data",
                txt: "Organization Attribute",
                header: `Organization Attribute ($label)`,
                grp: "General",
                width: 2,
                criterion: "organizationCustomAttributes",
                dataType: "searchCriteriaItem",
            },
            { isGroup: true, groupName: "Pricing" },
            { val: "grandTotal", txt: "Grand Total Charge", grp: "Pricing", width: 2 },
            { val: "totalPaymentsReceived", txt: "Total Payments Received", grp: "Pricing", width: 2 },
            { val: "outstandingBalance", txt: "Outstanding Balance", grp: "Pricing", width: 2 },
            { val: "listPrice", txt: "Total List Price", grp: "Pricing", width: 2 },
            { val: "itemAdjustment", txt: "Total Item Adjustment", grp: "Pricing", width: 2 },
            { val: "taxableAmount", txt: "Total Taxable Amount", grp: "Pricing", width: 2 },
            {
                val: "totalTaxType.$data",
                txt: "Total Tax (specific)",
                header: "Total Tax ($label)",
                grp: "Pricing",
                width: 2,
                criterion: "taxSchedules",
                dataType: "searchCriteriaItem",
            },
            { val: "totalTax", txt: "Total Tax", grp: "General", width: 2 },
            { val: "invoiceAdjustment", txt: "Total Invoice Adjustments", grp: "Pricing", width: 2 },
            { isGroup: true, groupName: "Payments" },
            {
                val: "$data.paymentStatus",
                txt: "Payment Status",
                header: "$label Pmt Status",
                grp: "Payments",
                width: 2,
                dataType: "options",
                options: paymentType,
            },
            {
                val: "$data.dueDate",
                txt: "Due Date",
                header: "$label Due Date",
                grp: "Payments",
                width: 2,
                dataType: "options",
                options: paymentType,
            },
            {
                val: "$data.paymentSuccess",
                txt: "Payment Success",
                header: "$label Pmt Success",
                grp: "Payments",
                width: 2,
                dataType: "options",
                options: paymentType,
            },
            {
                val: "$data.paymentAmount",
                txt: "Payment Amount",
                header: "$label Amount",
                grp: "Payments",
                width: 2,
                dataType: "options",
                options: paymentType,
            },
            {
                val: "$data.notes",
                txt: "Notes",
                header: "$label Notes",
                grp: "Payments",
                width: 3,
                dataType: "options",
                options: paymentType,
            },
        ],
        invoicePaymentDetails: [
            { isGroup: true, groupName: "General" },
            { val: "itemName", txt: "Bill Item Name", grp: "General", width: 3 },
            { val: "itemType", txt: "Bill Item Type", grp: "General", width: 2 },
            { val: "billName", txt: "Invoice Name", grp: "General", width: 3 },
            { val: "organizationName", txt: "Organization", grp: "General", width: 3 },
            { val: "invoiceId", txt: "Invoice Number", grp: "General", width: 2 },
            { val: "eventName", txt: "Event Name", grp: "General", width: 3 },
            { val: "eventReference", txt: "Event Reference", grp: "General", width: 2 },
            { val: "organizationAcctCode", txt: "Account Code", grp: "General", width: 2 },
            {
                val: "eventAttributeValue.$data",
                txt: "Event Attribute",
                header: `Event Attribute ($label)`,
                grp: "General",
                width: 2,
                criterion: "eventCustomAttributes",
                dataType: "searchCriteriaItem",
            },
            {
                val: "organizationAttributeValue.$data",
                txt: "Organization Attribute",
                header: `Organization Attribute ($label)`,
                grp: "General",
                width: 2,
                criterion: "organizationCustomAttributes",
                dataType: "searchCriteriaItem",
            },
            {
                val: "locationAttributeValue.$data",
                txt: "Location Attribute",
                header: `Location Attribute ($label)`,
                grp: "General",
                width: 2,
                criterion: "locationCustomAttributes",
                dataType: "searchCriteriaItem",
            },
            {
                val: "resourceAttributeValue.$data",
                txt: "Resource Attribute",
                header: `Resource Attribute ($label)`,
                grp: "General",
                width: 2,
                criterion: "resourceCustomAttributes",
                dataType: "searchCriteriaItem",
            },
            { isGroup: true, groupName: "Pricing" },
            { val: "listPrice", txt: "List Price", grp: "Pricing", width: 2 },
            { val: "itemAdjustment", txt: "Adjustment Amount", grp: "Pricing", width: 2 },
            { val: "adjustmentName", txt: "Adjustment Name", grp: "Pricing", width: 2 },
            { val: "taxableAmount", txt: "Taxable Amount", grp: "Pricing", width: 2 },
            {
                val: "totalTaxType.$data",
                txt: "Total Tax (specific)",
                header: `Total Tax ($label)`,
                grp: "Pricing",
                width: 2,
                criterion: "taxSchedules",
                dataType: "searchCriteriaItem",
            },
            { val: "totalTax", txt: "Total Tax", grp: "Pricing", width: 2 },
            { val: "totalCharge", txt: "Total Charge", grp: "Pricing", width: 2 },
            { val: "totalCount", txt: "Resource/Req Quantity", grp: "Pricing", width: 2 },
            { val: "profileName", txt: "Rsrv/Profile Name", grp: "Pricing", width: 3 },
            { val: "rateName", txt: "Rate Name", grp: "Pricing", width: 3 },
            { val: "rateDescription", txt: "Rate Description", grp: "Pricing", width: 2 },
            { val: "rateGroupName", txt: "Rate Group Name", grp: "Pricing", width: 3 },
            { val: "debitAccount", txt: "Debit Account Number", grp: "Pricing", width: 2 },
            { val: "creditAccount", txt: "Credit Account Number", grp: "Pricing", width: 2 },
        ],
    };
}
