import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25EventPreferencesComponent } from "./s25-event-preferences/s25.event.preferences.component";
import { OccSliderComponent } from "./s25-event-occurrences/occ-slider/occ-slider.component";
import { EvOccMenuComponent } from "./s25-event-occurrences/ev-occ-menu/ev-occ-menu.component";
import { S25EventColanderComponent } from "./s25-event-colander/s25-event-colander.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25RadioModule } from "../s25-radio/s25.radio.module";
import { PopoverModule } from "../s25-popover/popover.module";

@NgModule({
    declarations: [S25EventPreferencesComponent, OccSliderComponent, EvOccMenuComponent, S25EventColanderComponent],
    providers: [S25EventPreferencesComponent, OccSliderComponent, EvOccMenuComponent, S25EventColanderComponent],
    imports: [
        CommonModule,
        S25MultiselectModule,
        S25LoadingInlineModule,
        S25EditableModule,
        S25IconModule,
        S25CheckboxModule,
        S25RadioModule,
        PopoverModule,
    ],
    exports: [S25EventPreferencesComponent, OccSliderComponent, EvOccMenuComponent, S25EventColanderComponent],
})
export class S25EventDetailsModule {}
