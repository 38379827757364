import { Proto } from "../../../pojo/Proto";
import { S25Util } from "../../../util/s25-util";
import { SpaceService, S25WsSpace } from "../../../services/space.service";
import { ResourceService } from "../../../services/resource.service";
import { S25Profile } from "../ProfileI";
import { ReservationService } from "../../../services/reservation.service";
import { EventMircotI, ExpandedInfoI, S25Event } from "../EventMicroI";
import { Event } from "../../../pojo/Event";
import { S25Reservation, S25ObjectReservation, S25RsReservation, S25RmReservation, ObjectType } from "../ReservationI";
import { Task } from "../../../pojo/Task";
import { WSReservations } from "../EventMicroI";
import { AvailWSResponse } from "../../../services/resource.space.avail.service";
import Approval = Event.Workflow.Task;
import { S25Const } from "../../../util/s25-const";
import { Fls } from "../../../pojo/Fls";
import Conflict = AvailWSResponse.Conflict;
import WSSpaceAvail = AvailWSResponse.WSSpaceAvail;

function wsConvertState(reservation_state: 99 | 1): S25Reservation["state"] {
    return reservation_state === 1 ? "active" : "cancelled";
}
export class S25ReservationUtil {
    public static setFromWS(profile: S25Profile, expandedInfo: ExpandedInfoI, approval?: Approval): S25Reservation[] {
        let wsRsrv: WSReservations[] = (profile?.reservations as WSReservations[]) || [];
        wsRsrv = wsRsrv.sort((a, b) => S25Util.date.diffMinutes(a.evStartDt, b.evEndDt)); // data order by rsrvId

        let S25Rsrvs: S25Reservation[] = [];

        wsRsrv.forEach((r: any) => {
            let locations: S25ObjectReservation[] = [];
            let resources: S25ObjectReservation[] = [];

            if (r.spaces) {
                let reserved: S25RmReservation[] = [];
                let draft: S25RmReservation[] = [];
                let requested: S25RmReservation[] = [];
                if (r.spaces[0].reserved)
                    reserved = this.setLocations(r.spaces[0].reserved, expandedInfo?.spaces) as S25RmReservation[];
                if (r.spaces[0].draft)
                    draft = this.setLocations(r.spaces[0].draft, expandedInfo?.spaces) as S25RmReservation[];
                if (r.spaces[0].requested) {
                    const requestLocations =
                        r.spaces[0].requested.filter((item: any) => item.status === Task.States.InProgress) || [];
                    requested = this.setLocations(requestLocations, expandedInfo?.spaces) as S25RmReservation[];
                }
                locations.push({
                    requested: requested,
                    draft: draft,
                    reserved: reserved,
                });
            }

            if (r.resources) {
                let reserved: S25RsReservation[] = [];
                let draft: S25RsReservation[] = [];
                let requested: S25RsReservation[] = [];
                if (r.resources[0].reserved)
                    reserved = this.setResources(
                        r.resources[0].reserved,
                        expandedInfo?.resources,
                    ) as S25RsReservation[];
                if (r.resources[0].draft)
                    draft = this.setResources(r.resources[0].draft, expandedInfo?.resources) as S25RsReservation[];
                if (r.resources[0].requested) {
                    const requestresources =
                        r.resources[0].requested.filter((item: any) => item.status === Task.States.InProgress) || [];
                    requested = this.setResources(requestresources, expandedInfo?.resources) as S25RsReservation[];
                }
                resources.push({
                    requested: requested,
                    draft: draft,
                    reserved: reserved,
                });
            }

            let addTime = profile.occurrenceDefn.addedTime;
            let preEventStart: Date;
            let postEventEnd: Date;
            let setupStart: Date;
            let takeDownEnd: Date;
            let eventStart: Date = S25Util.date.parse(S25Util.date.dropTZString(r.evStartDt));
            let eventEnd: Date = S25Util.date.parse(S25Util.date.dropTZString(r.evEndDt));

            if (addTime?.preEvent) {
                preEventStart = S25Util.date.parse(
                    S25Util.date.addMinutes(eventStart, -1 * S25Util.ISODurationToMinutes(addTime.preEvent)),
                );
            }

            if (addTime?.postEvent) {
                postEventEnd = S25Util.date.parse(
                    S25Util.date.addMinutes(eventEnd, S25Util.ISODurationToMinutes(addTime.postEvent)),
                );
            }

            if (addTime?.setup) {
                if (preEventStart) {
                    setupStart = S25Util.date.parse(
                        S25Util.date.addMinutes(preEventStart, -1 * S25Util.ISODurationToMinutes(addTime.setup)),
                    );
                } else {
                    setupStart = S25Util.date.parse(
                        S25Util.date.addMinutes(eventStart, -1 * S25Util.ISODurationToMinutes(addTime.setup)),
                    );
                }
            }

            if (addTime?.takedown) {
                if (postEventEnd) {
                    takeDownEnd = S25Util.date.parse(
                        S25Util.date.addMinutes(postEventEnd, S25Util.ISODurationToMinutes(addTime.takedown)),
                    );
                } else {
                    takeDownEnd = S25Util.date.parse(
                        S25Util.date.addMinutes(eventEnd, S25Util.ISODurationToMinutes(addTime.takedown)),
                    );
                }
            }

            S25Rsrvs.push({
                itemId: r.rsrvId,
                locations: locations as S25RmReservation[],
                resources: resources as S25RsReservation[],
                comments: r?.comments || "",
                state: wsConvertState(r.state),
                eventStart: eventStart,
                eventEnd: eventEnd,
                preEventStart: preEventStart,
                postEventEnd: postEventEnd,
                setupStart: setupStart,
                takeDownEnd: takeDownEnd,
                profileId: profile.profileId, // for seperate view
                profileName: profile.name, // for seperate view
            } as S25Reservation);
        });
        return S25Rsrvs;
    }

    public static setState(rsrv: S25Reservation, state: S25Reservation["state"]) {
        rsrv.state = state;
        return rsrv;
    }

    public static getReservationName(rsrv: S25Reservation) {
        return rsrv.itemName || "rsrv_" + rsrv.itemId;
    }

    public static async createReservation(
        startDt: Date,
        endDt: Date,
        profileId?: number,
        profileName?: string,
    ): Promise<S25Reservation> {
        const item = await ReservationService.generateReservationIds(1);
        return {
            itemId: item?.idSet[0].rsrvId,
            state: "active",
            eventStart: startDt,
            eventEnd: endDt,
            locations: [],
            resources: [],
            profileId: profileId,
            profileName: profileName,
        } as S25Reservation;
    }

    public static async duplicateReservations(
        reservations: S25Reservation[],
        profileId: number,
    ): Promise<S25Reservation[]> {
        const items = await ReservationService.generateReservationIds(reservations.length);
        return reservations.map(
            (r, i) =>
                ({
                    ...r,
                    itemId: items?.idSet[1].rsrvId,
                    profileId: profileId,
                    profileName: "",
                }) as S25Reservation,
        );
    }

    public static addLocations(rsrv: S25Reservation, rsrvLocations: S25RmReservation[]) {
        let addedLocations = rsrvLocations;
        let notAdded: S25RmReservation[] = [];
        let requested: S25RmReservation[] = [];
        // TODO: check for and avoid duplicates maybe event check availability here?

        [].concat(rsrv.locations, addedLocations);
        return { added: addedLocations, notAdded: notAdded, requested: requested };
    }

    public static removeLocationsById(rsrv: S25Reservation, rmIds: number[]) {
        let removed = rmIds;
        let notRemoved: S25RmReservation[] = [];
        let requested: S25RmReservation[] = [];
        const filteredLocations = rsrv.locations.map((location: S25RsReservation) => ({
            ...location,
            requested: location.requested.filter((item: any) => !rmIds.includes(item.itemId)),
            draft: location.draft.filter((item: any) => !rmIds.includes(item.itemId)),
            reserved: location.reserved.filter((item: any) => !rmIds.includes(item.itemId)),
        }));
        rsrv.locations = filteredLocations;
        return rsrv;
    }

    public static removeResourceById(rsrv: S25Reservation, rsIds: number[]) {
        let removed = rsIds;
        const filteredResources = rsrv.resources.map((resource: S25RsReservation) => ({
            ...resource,
            requested: resource.requested.filter((item: any) => !rsIds.includes(item.itemId)),
            draft: resource.draft.filter((item: any) => !rsIds.includes(item.itemId)),
            reserved: resource.reserved.filter((item: any) => !rsIds.includes(item.itemId)),
        }));
        rsrv.resources = filteredResources;
        return rsrv;
    }
    public static removeLocations(rsrv: S25Reservation, rsrvLocations: S25Reservation[]) {
        let removed = rsrvLocations;
        let notRemoved: S25RmReservation[] = [];
        let requested: S25RmReservation[] = [];
        //TODO: not yet implemented
        return S25ReservationUtil.removeLocationsById(
            rsrv,
            rsrv.locations.map((l) => l.itemId),
        );
    }

    public static getLocations(rsrv: S25Reservation) {
        return rsrv.locations || [];
    }

    //     public static getName(spaceId:number, list:any, name: string) {
    //           let findName = S25Util.array.getByProp(list, name, spaceId);
    //           return findName;
    //      }

    public static setLocations(rsrv: S25RmReservation, locationsList: ExpandedInfoI) {
        let locations: S25RmReservation[] = [];
        if (!locationsList) return false;
        [].concat(rsrv).forEach((s: ExpandedInfoI) => {
            let findName = locationsList.find(function (i: ExpandedInfoI) {
                return i.spaceId === s.spaceId;
            });
            // 3 = denied or cancelled
            if (s.status !== 3) {
                //TODO: use a type for denied/cancelled
                locations.push({
                    itemId: s.spaceId,
                    itemName: findName?.spaceName,
                    instructions: s.instructions || "",
                    isShare: s.share,
                    rating: s.rating,
                    attendance: s.attendance,
                    layout: {
                        itemId: parseInt(s.layoutId), //Capacity in the layout may be smaller than the max capacity for the room
                        roomCapacity: findName?.maxCapacity || "", //Max capacity for the room
                    },
                } as S25RmReservation);
            }
            // });
        });
        return locations;
    }

    public static setResources(rsrv: S25RsReservation, resourcesList: ExpandedInfoI) {
        let resources: S25RsReservation[] = [];
        if (!resourcesList) return false;
        [].concat(rsrv).forEach((res: ExpandedInfoI) => {
            let findRes = resourcesList.find(function (i: ExpandedInfoI) {
                return i.resourceId === res.resourceId;
            });
            // 3 = denied or cancelled
            if (res.status !== 3) {
                resources.push({
                    itemId: res.resourceId,
                    itemName: findRes?.resourceName,
                    quantity: res?.quantity,
                    instructions: res?.instructions,
                    currentStockLevel: findRes?.currentStockLevel,
                });
            }
        });
        return resources;
    }

    public static async checkObjectsDatesAvailability(
        eventId: number,
        profileId: number,
        dates: { startDt: Date; endDt: Date }[],
        locations: S25ObjectReservation[],
        resources: S25ObjectReservation[],
    ) {
        let promiseArr = [];
        let locationArr: S25RmReservation[] = []; //locations[0] || [];
        let resourceArr: S25RsReservation[] = []; //resources[0] || [];
        let locationsIds: number[] = [];

        if (locations.length > 0) {
            locationArr = [].concat(locations[0]?.draft, locations[0]?.requested, locations[0]?.reserved);
            locationsIds = S25Util.toItemIds(locationArr); // sapceId in array [1, 2.3...]
        }

        if (resources.length > 0)
            resourceArr = [].concat(resources[0]?.draft, resources[0]?.requested, resources[0]?.reserved);

        if (locationArr.length > 0) {
            promiseArr.push(SpaceService.getSpaceDatesAvailability(locationsIds, dates, eventId, profileId));
        }

        if (resourceArr.length > 0) {
            promiseArr.push(ResourceService.getResourceDatesAvailability(resourceArr, dates, eventId, profileId));
        }

        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                return resp;
            });
        }
    }

    public static async getObjectRsrvId(event: S25Event, rsrvId: number, objectId: number, itemTypeId: number) {
        let find;
        const reservation = await this.getReservationsRsrvId(event, rsrvId);
        if (reservation) {
            if (itemTypeId === 6) {
                const resourcesFlat = this.getObjectsFlat(reservation.resources[0]);
                find = resourcesFlat.find((r: S25RsReservation) => r.itemId === objectId);
            } else {
                const locationsFlat = this.getObjectsFlat(reservation.locations[0]);
                find = locationsFlat.find((l: S25RmReservation) => l.itemId === objectId);
            }
            return find;
        } else {
            return undefined;
        }
    }

    public static getReservationsRsrvId(event: any, rsrvId: number) {
        for (const profile of event.profile) {
            for (const rsrv of profile.reservations) {
                if ((rsrv.itemId as any) === rsrvId) {
                    return rsrv;
                }
            }
        }
        return undefined;
    }

    public static getObjectsFlat(rsrv: S25RmReservation | S25RsReservation): S25RmReservation[] | S25RsReservation[] {
        if (!rsrv) return [];
        let items = new Set();
        rsrv?.draft?.forEach((r) => items.add(r));
        rsrv?.reserved?.forEach((r) => items.add(r));
        rsrv?.requested?.forEach((r) => items.add(r));
        return Array.from(items);
    }

    public static getReservationsStartEndDates(rsrvs: S25Reservation[]) {
        let dates = [];
        for (const r of rsrvs) {
            if (r.state === "active") {
                dates.push({
                    startDt:
                        S25Util.date.parse(r.setupStart) ||
                        S25Util.date.parse(r.preEventStart) ||
                        S25Util.date.parse(r.eventStart),
                    endDt:
                        S25Util.date.parse(r.takeDownEnd) ||
                        S25Util.date.parse(r.postEventEnd) ||
                        S25Util.date.parse(r.eventEnd),
                });
            }
        }
        return dates;
    }

    public static checkObjHasPerm(occList: S25Reservation[]) {
        // user doesn't have object rights, mircro event service expendedInfo locations/resrouces doesn't included in the list
        const findHasNoPerm = occList.filter(
            (item) =>
                item.locations.some((location) =>
                    location.reserved.some(
                        (reservation) =>
                            S25Util.isPrivate(reservation.itemName) ||
                            reservation?.assignBuffer?.assignBufferPerm === "noRequest",
                    ),
                ) ||
                item.resources.some((resource) =>
                    resource.reserved.some((reservation) => S25Util.isPrivate(reservation.itemName)),
                ),
        );

        return findHasNoPerm.length <= 0;
    }

    public static async setObjDetails(occList: S25Reservation[]) {
        let locationArr: any[] = [];
        let resrouceArr: any[] = [];
        let locDetails: any[] = [];
        let resDetails: any[] = [];
        let locationIds: any[] = [];
        let resourceIds: any[] = [];

        for (const occ of occList) {
            if (occ.locations.length > 0) {
                locationArr = this.getObjectsFlat(occ.locations[0]).map((l) => l.itemId);
                locationIds = locationIds.concat(locationArr);
            }
            if (occ.resources.length > 0) {
                resrouceArr = this.getObjectsFlat(occ.resources[0]).map((r) => r.itemId);
                resourceIds = resourceIds.concat(resrouceArr);
            }
        }

        if (locationArr.length > 0) {
            locDetails = await SpaceService.getSpacesIncludes(S25Util.array.unique(locationIds), ["layouts"]);
        }

        if (resrouceArr.length > 0) {
            resDetails = await ResourceService.getResourcesMinimal(S25Util.array.unique(resourceIds));
        }

        for (const occ of occList) {
            this.getObjectsFlat(occ.locations[0]).forEach((loc: any) => {
                const spDetails = locDetails.find((details: S25WsSpace) => {
                    return details.space_id === loc.itemId;
                });
                if (!loc.itemName) {
                    loc.itemName = spDetails?.space_name || S25Const.private;
                }

                if (spDetails && spDetails?.assign_buffer) {
                    loc.assignBuffer = {
                        assignBufferDuration: spDetails?.assign_buffer?.assign_buffer_duration,
                        assignBufferPerm: spDetails?.assign_buffer?.assign_buffer_perm,
                    };
                }

                S25Util.isPrivate(loc.itemName) ? (loc.hasPerm = false) : (loc.hasPerm = true);
                if (spDetails?.layout) {
                    const usedLayout = spDetails.layout.find((l: S25WsSpace) => l.layout_id === loc.layout.itemId);
                    loc.layout.itemName = usedLayout?.layout_name;
                    loc.layout.capacity = usedLayout?.capacity || loc?.layout?.roomCapacity || "";
                    loc.locationLayout = spDetails?.layout;
                }
            });

            this.getObjectsFlat(occ.resources[0]).forEach((res) => {
                const findDetails =
                    resDetails &&
                    resDetails.find((details: any) => {
                        return details.resource_id === res.itemId;
                    });

                if (!res.itemName) {
                    if (findDetails) {
                        res.itemName = findDetails?.resource_name;
                    } else {
                        res.itemName = S25Const.private;
                    }
                }
                res.hasPerm = !S25Util.isPrivate(res.itemName);
            });
        }
    }

    public static async conflictOverride(data: WSSpaceAvail, fls: Fls) {
        return data.dates.some((date: any) => {
            if (!date.conflict) return false;
            return date.conflict.some((conflict: Conflict) => {
                switch (conflict.conflict_type) {
                    case "rsrv":
                        return true;
                    case "ap":
                        return true;
                    case "hours":
                        return fls.SPACE_HOURS === "F";
                    case "blackout":
                        return fls.SPACE_BLACK === "F";
                    case "block":
                        return fls.SPACE_BLOCKED === "F";
                    default:
                        return true;
                }
            });
        });
    }
}
