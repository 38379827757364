import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    signal,
    ViewChild,
    ViewRef,
} from "@angular/core";
import { EventService } from "../../services/event.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PricingService } from "../../services/pricing.service";
import { RateService } from "../../services/rate.service";
import { TaxesService } from "../../services/taxes.service";
import { LangService } from "../../services/lang.service";
import { UserprefService } from "../../services/userpref.service";
import { FreshbooksService } from "../../services/freshbooks.service";
import { FlsService } from "../../services/fls.service";
import { OlsService } from "../../services/ols.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25PricingOrgsComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.orgs.component";
import { S25PricingTotalsComponent } from "./pricing-org-table-components/s25-pricing-totals/s25.pricing.totals.component";
import {
    AccountOccurrenceSubtotal,
    AccountProfileSubTotal,
    EventPricingData,
    Invoice,
    FBInvoiceData,
    LineItem,
    PricingModel,
    PricingOrg,
    PricingOrgData,
    InvoiceSummaryData,
    TotalsModel,
    lineItemType,
} from "../../pojo/Pricing";
import { Bind } from "../../decorators/bind.decorator";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { Event } from "../../pojo/Event";
import { OptBean } from "../s25-opt/s25.opt.component";
import { StateService } from "../../services/state.service";
import { PricingOrgSummary } from "./pricing-org-table-components/s25-pricing-org-summary/s25.pricing.org.summary.component";
import { PreferenceService } from "../../services/preference.service";
import { PaymentSummaryData } from "./s25-payments/s25.payments.component";
import { BalanceUpdateService } from "./pricing-org-table-components/s25-pricing-organization/balance.update.service";
import { TelemetryService } from "../../services/telemetry.service";
import { AccessLevels, isMinFls } from "../../pojo/Fls";
import Occurrence = Event.Occurrence;
import { S25PricingUtil } from "./s25.pricing.util";

@TypeManagerDecorator("s25-ng-pricing")
@Component({
    selector: "s25-ng-pricing",
    template: `@if (init) {
        <div class="pricing_wrapper">
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (pricingInit) {
                <div [class.no-sidebar]="!modelBean.canCreatePricingSet">
                    @if (modelBean.canCreatePricingSet) {
                        <div class="sidebar-menu ngStickyPosition hide-on-mobile" [class.closed]="!sidebarOpen()">
                            <s25-ng-icon [type]="'doubleArrowLeft'" (click)="pivotSidebar()"></s25-ng-icon>
                            <ul [class.hidden]="!sidebarOpen()">
                                @for (set of modelBean.pricingSets; track set.id) {
                                    <s25-popover
                                        [modelBean]="{
                                            popoverTemplate: 'Invoices are now accessed in Payment Mode',
                                        }"
                                        [openTrigger]="'click'"
                                        [closeTrigger]="'click'"
                                        [disabled]="paymentsMode"
                                    >
                                        <li
                                            [class.active]="set.id === modelBean.chosenPricingSet.id"
                                            (click)="togglePricingSet(set)"
                                            (keydown.enter)="togglePricingSet(set)"
                                            tabindex="0"
                                        >
                                            <a>{{ set.billDefn.billName }}</a>
                                        </li>
                                    </s25-popover>
                                }
                            </ul>
                        </div>
                    }
                    <div
                        class="pricing-opt-wrapper"
                        [ngClass]="{ 'summary-view': summaryView, 'no-edit': !canEditPricing, pivot: !sidebarOpen() }"
                    >
                        @if (canEditPricing) {
                            <s25-ng-info-message [isPopover]="true" [class.close]="!sidebarOpen()">
                                {{ modeInfoMsg }}
                            </s25-ng-info-message>
                            <label>
                                Pricing Mode
                                <s25-toggle-button
                                    [modelValue]="paymentsMode"
                                    [falseLabel]="'Standard'"
                                    [trueLabel]="'Payment'"
                                    (click)="toggleMode($event)"
                                ></s25-toggle-button>
                            </label>
                        }
                        <s25-ng-opt [modelBean]="optBean"></s25-ng-opt>
                    </div>
                    <!-- Standard Pricing Summary tab -->
                    @if (!paymentsMode) {
                        <div class="pricing_header" [class.pivot]="!sidebarOpen()">
                            <span>{{ lang.summary }}</span>
                        </div>
                        <div class="pricing_date" [class.pivot]="!sidebarOpen()">
                            <span class="ngBold">{{ lang.pricing_date }}</span>
                            @if (!canEditPricing) {
                                <span class="c-margin-left--half">{{
                                    datePickerBean.date | dateFormat: datePickerBean.dateFormat
                                }}</span>
                            } @else {
                                <div class="date-picker-wrapper c-margin-left--half">
                                    <s25-datepicker
                                        [(modelValue)]="datePickerBean"
                                        (modelValueChange)="updateDate()"
                                    ></s25-datepicker>
                                </div>
                            }
                            <div class="c-margin-top--half">
                                <s25-ng-pricing-org-summary
                                    [modelBean]="orgSummaryBean"
                                    [summaryView]="summaryView"
                                    (signalScroll)="scrollToOrg($event)"
                                ></s25-ng-pricing-org-summary>
                            </div>
                        </div>
                    }
                    <div
                        class="invoice-control-wrapper"
                        [ngClass]="{
                            'c-margin-bottom--single':
                                optBean.combineRelatedEvents || summaryView || eventPricingData.noOccs,
                            'payments-mode': paymentsMode,
                            pivot: !sidebarOpen(),
                        }"
                    >
                        @if (canEditPricing && evBillId && !invoiceLocked) {
                            <label>
                                Pricing Date:
                                <s25-datepicker
                                    [(modelValue)]="datePickerBean"
                                    (modelValueChange)="updateDate()"
                                ></s25-datepicker
                            ></label>
                        } @else if (evBillId) {
                            <!-- invoice view, invoice locked or insufficient edit perms --->
                            <label>
                                Pricing Date:
                                <p class="c-margin-top--half c-margin-left--quarter">
                                    {{ datePickerBean.date | dateFormat: datePickerBean.dateFormat }}
                                </p>
                            </label>
                        }

                        @if (paymentsMode) {
                            <s25-ng-pricing-org-summary
                                [modelBean]="orgSummaryBean"
                                [paymentsMode]="paymentsMode"
                            ></s25-ng-pricing-org-summary>
                        }
                        @if (canEditPricing) {
                            @if (eventsNeedingRefresh?.length > 0 && !invoiceLocked) {
                                <div>
                                    <div class="ngBold ngRed">
                                        The following events have been recently edited and may require a pricing
                                        refresh:
                                    </div>
                                    @for (event of eventsNeedingRefresh; track event) {
                                        <div>
                                            @if (!event.hideRefresh) {
                                                <button
                                                    (click)="refreshPricing(event)"
                                                    class="aw-button aw-button--primary"
                                                >
                                                    Refresh {{ event.event_name }}
                                                </button>
                                            }
                                            @if (event.loading) {
                                                <span>{{ event.loading }}</span>
                                            }
                                            @if (event.loadingSuccess) {
                                                <span>{{ event.loadingSuccess }}</span>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            @if (canEditPricing && paymentsMode && !summaryView) {
                                <div class="invoice-lock-wrapper">
                                    <s25-ng-info-message [isPopover]="true">{{
                                        invoiceLockInfoMsg
                                    }}</s25-ng-info-message>
                                    <label>
                                        Invoice Lock
                                        <s25-toggle-button
                                            [modelValue]="invoiceLocked"
                                            [falseLabel]="'Off'"
                                            [trueLabel]="'On'"
                                            (modelValueChange)="toggleLockInvoice($event)"
                                        ></s25-toggle-button>
                                    </label>
                                    <s25-loading-inline [model]="{ text: 'Saving...' }"></s25-loading-inline>
                                </div>
                            }
                            @if (!optBean.combineRelatedEvents && !summaryView && !invoiceLocked) {
                                <div class="update_rate_groups">
                                    <label for="allRateGroups"
                                        >Update All Items to Rate Group:
                                        <div class="rate_group c-margin-top--quarter">
                                            <s25-ng-pricing-rate-group
                                                [modelBean]="eventRateGroupModel"
                                                (updateRateGroup)="updateAllRateGroups($event)"
                                            ></s25-ng-pricing-rate-group>
                                        </div>
                                    </label>
                                </div>
                            }
                            @if (eventsNeedingRefresh.length === 0 && !invoiceLocked) {
                                <div class="invoice-buttons">
                                    <button (click)="refreshPricing(thisEvent)" class="aw-button aw-button--outline">
                                        Refresh this Event's Pricing
                                    </button>
                                </div>
                            }
                            @if (modelBean.chosenPricingSet.id > 0 && !setDeleteDisabled) {
                                <div class="invoice-buttons">
                                    <button
                                        class="aw-button aw-button--danger--outline"
                                        [disabled]="showSetDeleteWarning"
                                        (click)="modelBean.deletePricingSet()"
                                    >
                                        Delete Invoice: {{ modelBean.chosenPricingSet.billDefn.billName }}
                                    </button>
                                </div>
                            }
                            @if (paymentsMode && modelBean.canCreatePricingSet) {
                                <div class="invoice-buttons">
                                    <button
                                        class="aw-button aw-button--outline"
                                        [disabled]="showSetDeleteWarning"
                                        (click)="modelBean.openPricingSetModal()"
                                    >
                                        Create Invoice
                                    </button>
                                </div>
                            }
                        }
                    </div>
                    @if (showSetDeleteWarning) {
                        <div class="warning-message">
                            At least one invoice is associated with this pricing set. Please confirm you'd like to
                            delete it.
                            <div class="button-container">
                                <button class="aw-button aw-button--outline" (click)="this.modelBean.cancelDelete()">
                                    Cancel
                                </button>
                                <button
                                    class="aw-button aw-button--danger--outline"
                                    (click)="this.modelBean.deletePricingSet()"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    }
                    @if (thisEvent.loading) {
                        <div class="c-margin-left--single">{{ thisEvent.loading }}</div>
                    }
                    <s25-ng-modal #pricingSetModal [title]="'Create Invoice'" [size]="'xl'">
                        <ng-template #s25ModalBody>
                            <s25-ng-pricing-set
                                [modelBean]="modelBean"
                                [optBean]="optBean"
                                (close)="refresh()"
                            ></s25-ng-pricing-set>
                        </ng-template>
                    </s25-ng-modal>
                    @if (!summaryView || (summaryView && invoiceTotals)) {
                        <div [class.pivot]="!sidebarOpen()">
                            <s25-ng-pricing-orgs
                                [orgLists]="orgLists"
                                [optBean]="optBean"
                                [summaryView]="summaryView"
                                [noOccs]="eventPricingData.noOccs"
                                [freshbooksOrgSummary]="orgSummaryBean"
                                [paymentsMode]="paymentsMode"
                                [invoiceLocked]="invoiceLocked"
                                (refreshPricing)="refresh($event)"
                                (refreshOpt)="optRefresh($event)"
                                (manualPaymentCreated)="disableDeleteSet($event)"
                                (navToInvoice)="togglePricingSet({ id: $event })"
                            ></s25-ng-pricing-orgs>
                            <div class="pricing_header totals">{{ lang.totals }}</div>
                            <s25-ng-pricing-totals
                                [modelBean]="pricingTotalsModel"
                                [summaryView]="summaryView"
                                [invoiceData]="invoiceTotals"
                                [paymentData]="paymentSummaryDataByOrgId"
                            ></s25-ng-pricing-totals>
                        </div>
                    } @else {
                        <div class="noResults">No Invoices Found</div>
                    }
                </div>
            }
        </div>
        <s25-ng-modal #confirmToggleModal [title]="'Confirm Mode Change'" [type]="'custom'" [size]="'md'">
            <ng-template #s25ModalBody>
                <modal-convert-invoice
                    [modeInfoMessage]="modeInfoMsg"
                    [showInvoiceConvertData]="promptForInvoiceConversion"
                    [createStandardInvoice]="createStandardInvoice"
                    [combineRelatedEvents]="optBean.combineRelatedEvents"
                    [eventId]="+itemId"
                    [selectionsValidated]="standardInvoiceValidated"
                    [showErrors]="showStandardInvoiceErrors"
                    [dateFormat]="modelBean.dateFormat"
                    (updateMappingSelections)="eventPricingData = $event"
                ></modal-convert-invoice>
            </ng-template>
            <ng-template #s25ModalFooter>
                <div class="modal-create-invoice-buttons">
                    <s25-ng-button [type]="'primary'" [onClick]="onCreateStandardInvoice"> Confirm </s25-ng-button>
                    <button
                        class="aw-button aw-button--outline"
                        (click)="this.showStandardInvoiceErrors.set(false); toggleModal.close()"
                    >
                        Cancel
                    </button>
                </div>
            </ng-template>
        </s25-ng-modal>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BalanceUpdateService],
})
export class S25PricingComponent implements OnInit {
    @Input() itemId: number | string;

    @ViewChild(S25PricingOrgsComponent) orgsLists: S25PricingOrgsComponent;
    @ViewChild(S25PricingTotalsComponent) totalsTable: S25PricingTotalsComponent;
    @ViewChild("pricingSetModal") pricingSetModal: S25ModalComponent;
    @ViewChild("confirmToggleModal") toggleModal: S25ModalComponent;

    init = false;
    pricingInit = false;
    modelBean: PricingModel;
    optBean: OptBean = {} as OptBean;
    evBillId: number;
    orgLists: PricingOrg[];
    commonListBean: any;
    thisEvent: { event_id: number; loading?: string };
    eventIds: number[];
    eventsNeedingRefresh: { hideRefresh: boolean; event_name: string; loading: boolean; loadingSuccess: boolean }[] =
        [];
    dateTimeFormat: string;
    lang: any;
    canEditPricing: boolean;
    datePickerBean: any;
    orgSummaryBean: PricingOrgSummary;
    eventRateGroupModel: PricingOrgData;
    pricingTotalsList: any;
    pricingTotalsModel: TotalsModel;
    eventPricingData: EventPricingData;
    fbInvoiceData: FBInvoiceData;
    showSetDeleteWarning: boolean;
    setDeleteDisabled: boolean;
    selectedView: OptBean["selectedView"];
    idToBillName: { [key: string]: Set<string> } = { eventType: new Set() };
    paymentsMode: boolean;
    sidebarOpen = signal<boolean>(true);
    modeInfoMsg: string =
        "Payment mode allows payment management and occurrence-based invoicing and reporting. Adjustments will only be reflected in the selected mode, and changing modes could result in billing discrepancies.";
    invoiceLockInfoMsg: string =
        'Invoice lock prevents adjustments, adding/removing occurrences, formula updates, reflection of reservation changes to associated occurrences, and changing the "Charge To" organization or Rate Group.';
    summaryView: boolean;
    invoiceLocked: boolean;
    paymentSummaryDataByOrgId: {
        [key: number]: PaymentSummaryData;
    };
    invoiceTotals: InvoiceSummaryData;
    invoiceIdByOrg: { [key: number]: string };
    createStandardInvoice = signal<boolean>(false);
    promptForInvoiceConversion: boolean;
    standardInvoiceValidated = signal<boolean>(true);
    showStandardInvoiceErrors = signal<boolean>(false);

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        if (!this.evBillId) {
            const urlArr = window.location.hash.split("/");
            const lastIndex: number | string = urlArr.at(-1);
            const location = urlArr.at(-2);
            const eventId = urlArr.at(-3);

            // when refreshing page, check url for data to persist current view - account for navigating from other sources (ie. search)
            if (
                S25Util.isDefined(lastIndex) &&
                !isNaN(+lastIndex) && // evBillId
                location === "pricing" && // is the pricing tab - necessary when navigating from search
                !isNaN(+eventId) &&
                eventId === this.itemId // make sure it's the same event, not a new one in which evBillId would not pertain
            ) {
                this.evBillId = +lastIndex;
                this.paymentsMode = true;
            }
        }

        this.init = true;
        this.cd.detectChanges();

        S25LoadingApi.init(this.elementRef.nativeElement);

        this.modelBean = {
            defaultPricingSet: {
                id: -999,
                billDefn: {
                    billName: "Standard Pricing",
                },
            },
            invoiceSummary: {
                id: -998,
                billDefn: {
                    billName: "Invoice Activity",
                },
            },
            canCreatePricingSet: false,
            openPricingSetModal: () => {
                this.modelBean.pricingSet = {
                    itemId: +this.itemId,
                };
                this.pricingSetModal.open();
            },
            getPricingSets: async () => {
                try {
                    let eventIds =
                        (this.optBean?.combineRelatedEvents && (await EventService.getRelatedEventIds(+this.itemId))) ||
                        (await jSith.when([]));

                    eventIds.push(this.itemId);
                    eventIds = S25Util.array.unique(eventIds);

                    const pricingSets = await PricingService.getPricingSetsForEvents(eventIds);

                    const setBillingItems = S25Util.array.uniqueByProp(pricingSets?.data?.items ?? [], "id");
                    this.paymentSummaryDataByOrgId = {};
                    if ((this.summaryView || this.evBillId) && setBillingItems?.length) {
                        const rateData: any = pricingSets.expandedInfo;
                        const { rateGroups, rateSchedules } = rateData;
                        const invoiceData = {
                            lineItems: {},
                            subtotals: {},
                            rateGroups,
                            rateSchedules,
                        };

                        setBillingItems.reduce((map: { [key: string]: Set<string> }, set: Invoice) => {
                            if (set.id === this.evBillId) {
                                this.setDeleteDisabled = set.payments?.length > 0;
                                this.invoiceLocked = !!set.billDefn.completed;
                                this.invoiceIdByOrg = set.billing.subtotals[0].account?.reduce(
                                    (map: { [key: number]: string }, orgData) => (
                                        (map[orgData.chargeToId] = orgData.invoiceId), map
                                    ),
                                    {},
                                );
                            }

                            if (this.summaryView) S25PricingUtil.getSummaryData(set, invoiceData);

                            set.payments?.forEach((payment) => {
                                if (!this.paymentSummaryDataByOrgId[payment.organizationId]) {
                                    this.paymentSummaryDataByOrgId[payment.organizationId] = [];
                                }
                                this.paymentSummaryDataByOrgId[payment.organizationId].push({
                                    ...payment,
                                    invoiceId: set.id,
                                    invoiceName: set.billDefn.billName,
                                });
                            });

                            set.billDefn.reservations.forEach((res) => {
                                if (!map[res.rsrv_id]) map[res.rsrv_id] = new Set();
                                map[res.rsrv_id].add(set.billDefn.billName);
                            });

                            set.billDefn.requirements.forEach((req) => {
                                if (!map[req.requirementId]) map[req.requirementId] = new Set();
                                map[req.requirementId].add(set.billDefn.billName);
                            });

                            if (set.billDefn.includeEventType) map.eventType.add(set.billDefn.billName);

                            return map;
                        }, this.idToBillName);

                        if (this.summaryView) {
                            this.invoiceTotals = invoiceData;
                            S25PricingUtil.sortOccurrenceItems(this.invoiceTotals);
                        }
                    }

                    const standardInvoice = setBillingItems.find(
                        (set) => set.billDefn.billName === "Invoice (Standard)",
                    );
                    this.modelBean.pricingSets = [
                        this.paymentsMode ? this.modelBean.invoiceSummary : this.modelBean.defaultPricingSet,
                        ...(standardInvoice ? [standardInvoice] : []),
                        ...(setBillingItems?.filter((set) => set.id !== standardInvoice?.id) ?? []),
                    ];
                } catch (error) {
                    S25Util.showError(error);
                }
            },
            selectPricingSet: async () => {
                this.evBillId =
                    this.modelBean.chosenPricingSet.id === this.modelBean.defaultPricingSet.id ||
                    this.modelBean.chosenPricingSet.id === this.modelBean.invoiceSummary.id ||
                    !this.paymentsMode
                        ? null
                        : this.modelBean.chosenPricingSet.id;

                this.summaryView = this.modelBean.chosenPricingSet.id === this.modelBean.invoiceSummary.id;
                S25LoadingApi.init(this.elementRef.nativeElement);
                this.pricingInit = false;
                this.orgLists = [];
                this.cd.detectChanges();
                this.optBean.colChooseBean = this.getColChooseBean();
                await this.initPricing();
                this.pricingInit = true;
                this.cd.detectChanges();
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                window.scrollTo({ top: 0, behavior: "smooth" });
                StateService.go(
                    ".",
                    {
                        evBillId: this.evBillId ? this.evBillId : this.paymentsMode ? "summary" : "",
                    },
                    {},
                );
            },
            deletePricingSet: async () => {
                if (this.setDeleteDisabled) return;

                if (this.fbInvoiceData?.customBillInvoices?.length > 0 && !this.showSetDeleteWarning) {
                    this.showSetDeleteWarning = true;
                    this.cd.detectChanges();
                    return;
                }
                try {
                    await PricingService.deletePricingSet(this.modelBean.chosenPricingSet.id);
                    this.idToBillName = { eventType: new Set() };
                    await this.modelBean.getPricingSets();
                    this.modelBean.chosenPricingSet = S25Util.deepCopy(this.modelBean.defaultPricingSet);
                    this.modelBean.selectPricingSet();
                    this.showSetDeleteWarning = false;
                } catch (error) {
                    S25Util.showError(error);
                }
            },
            cancelDelete: () => {
                this.showSetDeleteWarning = false;
                this.cd.detectChanges();
            },
        };

        this.itemId = +this.itemId;
        this.orgLists = this.orgLists || [];
        this.commonListBean = {
            comptype: "pricing",
            eventId: this.itemId,
            caption: "Pricing List",
        };

        this.optBean = Object.assign(this.optBean, {
            itemId: this.itemId,
            itemTypeId: 1,
            compsubject: "event",
            comptype: "pricing",
            helpTopic: "view_eventsingle_pricing",
            searchContext: "&pricing_event_id=" + this.itemId, //just used to make call unique to subject context if cached
            hasOpt: true,
            hasMoreActions: true,
            hasClose: true,
            hasRefresh: true,
            hasDatepicker: false,
            hasHideNoChargeItems: true,
            autoInit: false,
            hasBBStyle: false,
            refreshF: this.optRefresh,
            hasHelp: true,
            hasColumnChooser: true,
        });
        await this.initPricing();
        this.pricingInit = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    scrollToOrg(orgData: string) {
        this.orgsLists.scrollToOrg(orgData);
    }

    async refreshPricing(event: any) {
        event.hideRefresh = true;
        event.loading = "Refresh Processing...";
        event.loadingSuccess = "";

        this.cd.detectChanges();

        try {
            await EventService.updateEventPricing(event.event_id);

            event.loading = "";
            event.loadingSuccess = "Update complete, please refresh this page to see updated pricing";
            await this.refresh();
        } catch (error) {
            event.hideRefresh = false;
            event.loading = "";
            event.loadingSuccess = "";
            S25Util.showError(error);
        }
    }

    async getEventsNeedingRefresh() {
        if (
            S25Util.array.shallowIntersection(this.eventIds, EventService.getEventIdsNeedingRefreshLocal(), null).length
        ) {
            this.eventsNeedingRefresh = await EventService.getEventsNeedingRefresh(this.eventIds);
        }
    }

    async initPricing() {
        this.thisEvent = { event_id: +this.itemId };
        const [
            eventPricingData,
            rateGroups,
            taxesMap,
            lang,
            dateFormat,
            dateTimeFormat,
            timeFormat,
            hasFreshbooks,
            fbInvoiceData,
            fls,
            ols,
            paymentsModePref,
            convertStandardPref,
        ] = await Promise.all([
            this.evBillId
                ? PricingService.getPricingFromPricingSetId(+this.itemId, this.evBillId)
                : PricingService.getPricing(+this.itemId, this.optBean?.combineRelatedEvents),
            RateService.getRateGroups(),
            TaxesService.getTaxesMap(),
            LangService.getLang(),
            UserprefService.getS25Dateformat(),
            UserprefService.getS25DateTimeformat(),
            UserprefService.getS25Timeformat(),
            FreshbooksService.hasFreshbooksApiToken(),
            FreshbooksService.getS25Invoices(+this.itemId, this.evBillId),
            FlsService.getFls(),
            OlsService.getOls([+this.itemId], 1, "edit"),
            PreferenceService.getPreferences(["pricingMode"], "U"),
            PreferenceService.getPreferences(["createStandardInvoice"], "S"),
        ]);

        this.fbInvoiceData = fbInvoiceData?.invoice;
        this.paymentsMode ??= paymentsModePref?.pricingMode?.value === "payment"; // only set to pref default on initial nav to view, allow toggle when user opts to change modes
        this.summaryView = !this.evBillId && this.paymentsMode;
        if (this.summaryView) this.selectedView = "occurrence";

        let editAccess = ols[0]?.access_level;
        this.modelBean.canCreatePricingSet =
            !!this.evBillId || [1, 2, 3].indexOf(parseInt(eventPricingData?.state)) > -1;

        this.eventPricingData = eventPricingData;

        this.modelBean.canCreatePricingSet ? await this.modelBean.getPricingSets() : this.sidebarOpen.set(false);
        this.promptForInvoiceConversion =
            !this.paymentsMode && this.modelBean.pricingSets && !this.modelBean.pricingSets.slice(1).length;
        this.createStandardInvoice.set(S25Util.toBool(convertStandardPref?.createStandardInvoice?.value));

        if (this.evBillId) {
            this.modelBean.chosenPricingSet = this.modelBean.pricingSets.find((set: any) => set.id === this.evBillId);
            this.selectedView = null;
        } else {
            this.invoiceLocked = false;
            this.modelBean.chosenPricingSet = this.paymentsMode
                ? this.modelBean.invoiceSummary
                : this.modelBean.defaultPricingSet;
        }

        this.modelBean.dateTimeFormat = dateTimeFormat;
        this.modelBean.dateFormat = dateFormat;

        this.dateTimeFormat = dateTimeFormat;
        this.eventIds = eventPricingData?.eventIds;
        this.eventsNeedingRefresh = eventPricingData?.eventsNeedingRefresh;
        const relatedEventsNode = S25Util.propertyGet(eventPricingData, "content");
        //when combined, event data has no "content" tag bc all data has been merged into one "event" for combined pricing
        //so we always show the checkbox if combineRelatedEvents is true else users wont be able to uncheck it
        this.optBean.hasCombineRelatedEventsCheckbox =
            this.optBean.combineRelatedEvents || relatedEventsNode?.length > 0;
        this.optBean.selectedView = this.selectedView;
        this.optBean.colChooseBean = this.getColChooseBean();

        const billItems = S25Util.propertyGet(eventPricingData, "bill_item") || [];
        const allOrgs = S25Util.propertyGet(eventPricingData, "organization") || [];
        const billedOrgs = S25Util.propertyGetAllUnique(
            this.summaryView ? this.invoiceTotals?.lineItems : eventPricingData,
            this.summaryView ? "chargeToId" : "charge_to_id",
        )?.map(S25Util.toInt);

        const invoiceOrgs = S25Util.propertyGetAllUnique(fbInvoiceData, "organization_id")?.map(S25Util.toInt);

        if (this.summaryView && this.invoiceTotals) this.invoiceTotals.orgs = allOrgs;
        this.lang = lang?.div?.controls["s25-event_details"]?.text;
        this.canEditPricing =
            fls?.EVENT_BILLING === "F" && ["F", "C"].includes(editAccess) && ["F", "C", "R"].includes(fls?.CU_RATES);

        this.datePickerBean = {
            dateFormat: dateFormat,
            date:
                new Date(
                    S25Util.date.dropTZString(
                        S25Util.propertyGet(
                            S25Util.propertyGetParentWithChildValue(eventPricingData, "history_type_id", 4),
                            "history_dt",
                        ),
                    ),
                ) || new Date(),
            showToday: true,
        };

        this.orgSummaryBean = {
            billedOrgs: billedOrgs,
            invoiceOrgs: invoiceOrgs,
            allOrgs: allOrgs,
            eventId: +this.itemId,
            relatedEventIds: this.eventIds?.filter((id) => id !== this.itemId) ?? [],
            hasFreshbooks: hasFreshbooks,
            evBillId: this.evBillId,
            locatorMap: eventPricingData.locatorMap ?? {},
            invoices: this.modelBean.pricingSets,
        };

        this.eventRateGroupModel = {
            rateGroups: rateGroups,
            eventId: +this.itemId,
            canEdit: true,
            canEditPricing: this.canEditPricing,
            isEventLevel: true,
            evBillId: this.evBillId,
            fls: fls,
            eventData: eventPricingData,
        };

        jSith.forEach(allOrgs, (_, org) => {
            org.isBillingOrg = billedOrgs.indexOf(parseInt(org.organization_id)) > -1;
            // billedOrgs is set by standard event pricing line items, for summary view we aggregate invoice line items
            if (org.isBillingOrg) {
                let orgListData;
                if (this.summaryView && this.invoiceTotals) {
                    orgListData = {
                        rows:
                            Object.values(this.invoiceTotals?.lineItems)?.filter(
                                (lineItem: LineItem) =>
                                    lineItem.chargeToId === org.organization_id &&
                                    (this.optBean.combineRelatedEvents
                                        ? eventPricingData.eventIds?.includes(lineItem.eventId)
                                        : lineItem.eventId === eventPricingData.event_id),
                            ) ?? [],
                        orgId: org.organization_id,
                        rateGroups: rateGroups,
                        allOrgs: allOrgs,
                        rateSchedules: this.invoiceTotals?.rateSchedules,
                        subtotals: this.invoiceTotals?.subtotals[org.organization_id] ?? {},
                    };
                } else {
                    orgListData = S25PricingUtil.getPricingOrganizationListModelData(
                        eventPricingData,
                        +this.itemId,
                        parseInt(org.organization_id),
                        rateGroups,
                        taxesMap,
                        this.canEditPricing,
                    );
                }

                Object.assign(orgListData, {
                    combineRelatedEvents: !!this.optBean.combineRelatedEvents,
                    fls: fls,
                    dateFormat: dateFormat,
                    paymentSummaryData:
                        this.summaryView && this.invoiceTotals
                            ? this.paymentSummaryDataByOrgId[orgListData.orgId]
                            : null,
                    occSubtotals: {},
                    allOccurrences: {
                        dateFormat,
                        dateTimeFormat,
                        timeFormat,
                    },
                    idToBillName: this.idToBillName,
                    profileSubtotals: [],
                    hideNoChargeItems: this.optBean.hideNoChargeItems,
                    locatorMap: eventPricingData.locatorMap ?? {},
                });

                eventPricingData.occSubtotals?.forEach((item: AccountOccurrenceSubtotal) => {
                    if (item.chargeToId === org.organization_id) {
                        orgListData.occSubtotals[item.rsrvId] = item;
                    }
                });

                eventPricingData.occurrences?.forEach((item: Occurrence) => {
                    orgListData.allOccurrences[item.rsrvId] = item;
                });

                eventPricingData.profileSubtotals?.forEach((item: AccountProfileSubTotal) => {
                    if (item.chargeToId === org.organization_id) {
                        orgListData.profileSubtotals.push(item);
                    }
                });

                const orgInList = S25Util.array.getByProp(this.orgLists, "orgId", org.organization_id);
                const canEditInvoiceNum = isMinFls(fls?.MANAGE_PAY, AccessLevels.Read);

                if (orgInList) {
                    //organization list already exists, just reset its getdata function
                    //and set some other info, mainly subTotal sub-section so that grand totals are updated...
                    //and hasEventCharges so create invoice btn shows if charges were added
                    Object.assign(orgInList, {
                        data: orgListData,
                        rowFuncs: S25PricingUtil.getPricingOrganizationListFn(orgListData),
                        hasEventCharges:
                            S25PricingUtil.agg.totalCharge.total(
                                billItems.filter(S25PricingUtil.billItemOrgFilter(org.organization_id)),
                            ) > 0,
                        subTotal: S25PricingUtil.getSubTotalObject(org, orgListData?.allBillItems),
                        fbInvoiceData: fbInvoiceData?.invoice,
                        evBillId: this.evBillId,
                        canEditInvoiceNum: canEditInvoiceNum,
                        invoiceNum: this.evBillId ? this.invoiceIdByOrg[org.organization_id] ?? "" : null,
                    });
                } else {
                    //create organization lists
                    this.orgLists.push(
                        S25Util.merge(S25Util.deepCopy(this.commonListBean), {
                            hasFreshbooks: hasFreshbooks,
                            data: orgListData,
                            rowFuncs: S25PricingUtil.getPricingOrganizationListFn(orgListData),
                            orgId: org.organization_id,
                            orgName: (org.organization_name?.toString() || "").toUpperCase(),
                            hasEventCharges:
                                !this.summaryView &&
                                S25PricingUtil.agg.totalCharge.total(
                                    billItems.filter(S25PricingUtil.billItemOrgFilter(org.organization_id)),
                                ) > 0,
                            subTotal:
                                !this.summaryView && S25PricingUtil.getSubTotalObject(org, orgListData?.allBillItems),
                            total: !this.summaryView && S25PricingUtil.agg.totalCharge.total(orgListData?.allBillItems),
                            fbInvoiceData: fbInvoiceData?.invoice,
                            evBillId: this.evBillId,
                            canEdit: this.canEditPricing,
                            canEditInvoiceNum: canEditInvoiceNum,
                            invoiceNum: this.evBillId ? this.invoiceIdByOrg[org.organization_id] ?? "" : null,
                        }),
                    );
                }
            }
        });

        this.orgLists.sort(S25Util.shallowSort("orgName"));

        const pricingTotalsModel: TotalsModel = {
            evBillId: this.evBillId,
            taxesMap: taxesMap,
            grandTaxes: { tax: [] },
            rows: [],
            combineRelatedEvents: this.optBean.combineRelatedEvents,
        };

        jSith.forEach(this.orgLists, (_, org) => {
            //add flag on each org to indicate if related events are available
            if (!this.summaryView) {
                org.hasRelatedEvents = this.optBean.combineRelatedEvents || relatedEventsNode?.length;
                pricingTotalsModel.grandPriceList = (pricingTotalsModel.grandPriceList || 0) + org.subTotal.list_price; //accumulate list price from each org
                pricingTotalsModel.grandAdjustmentAmt =
                    (pricingTotalsModel.grandAdjustmentAmt || 0) + org.subTotal.adjustment_amt; //accumulate adj amt from each org
                pricingTotalsModel.rows.push(org.subTotal); //place org sub total row in array
            }
        });

        billItems?.forEach((item: any) => {
            if (parseInt(item.bill_item_type_id) === lineItemType.ADJUSTMENT) {
                pricingTotalsModel.grandAdjustmentAmt += parseFloat(item.total_charge);

                pricingTotalsModel.rows.push({
                    evBillId: this.evBillId,
                    isAdjustment: true,
                    adjustment_name: item.adjustment_name,
                    total_charge: item.total_charge,
                    charge_to_name: item.charge_to_name,
                    charge_to_id: item.charge_to_id,
                    adjustment_amt: item.adjustment_amt,
                    adjustment_percent: item.adjustment_percent,
                });
            }
        });

        pricingTotalsModel.grandTaxableAmt = S25PricingUtil.agg.adjustedPrice.subtotal(billItems);
        pricingTotalsModel.grandTotalCharge = S25PricingUtil.agg.totalCharge.total(billItems);
        pricingTotalsModel.grandTaxes.tax = S25PricingUtil.agg.taxes.totalByTaxId(billItems, taxesMap);
        this.pricingTotalsModel = pricingTotalsModel;
        if (this.pricingTotalsList) {
            this.pricingTotalsList.getdata = S25PricingUtil.getPricingTotalsListFn(pricingTotalsModel);
        } else {
            this.pricingTotalsList = S25Util.merge(
                S25Util.copy(this.commonListBean, {
                    getdata: S25PricingUtil.getPricingTotalsListFn(pricingTotalsModel),
                }),
            );
        }
    }
    @Bind
    async refresh(totalsRefreshData?: any) {
        if (!this.optBean.combineRelatedEvents && totalsRefreshData) {
            this.totalsTable.initTableConfig(totalsRefreshData);
            return;
        }

        this.init = false;
        this.pricingInit = false;
        this.orgLists = [];
        this.cd.detectChanges();

        await this.ngOnInit();

        setTimeout(() => {
            this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        });
    }

    @Bind
    async optRefresh(selectedView?: OptBean["selectedView"]) {
        this.selectedView = selectedView ?? this.orgsLists?.selectedView ?? this.selectedView;
        // const expandedTableMap = this.orgsLists?.orgTableExpandMap();

        S25LoadingApi.init(this.elementRef.nativeElement);

        this.optBean.colChooseBean = this.getColChooseBean();
        this.pricingInit = false;
        this.orgLists = [];
        this.cd.detectChanges();

        await this.initPricing();

        this.pricingInit = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);

        setTimeout(() => {
            this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            // if (expandedTableMap && this.orgsLists) {
            //     this.orgsLists.orgTableExpandMap.update(() => {
            //             return Object.fromEntries(Object.entries(expandedTableMap).map(([orgId, data]) => [orgId, { ...data }]))
            //         }
            //     );
            // }
            this.updateTableColumns();
        });
    }

    updateAllRateGroups(tableData: any) {
        this.orgsLists.refreshAllRateGroups(tableData);
    }

    getColChooseBean() {
        const colList = S25PricingUtil.PricingConst.cols
            .filter((col) => (!this.summaryView ? col.prefname !== "invoice" : true))
            .map((obj: any) => {
                obj.isVisible = 1;
                return obj;
            });

        if (!this.optBean || !S25Util.coalesce(this.optBean.combineRelatedEvents, false)) {
            S25Util.array.inplaceRemoveByProp(colList, "prefname", "reference"); //no reference column if not combining related events...
        }

        return {
            //get list of columns available, set all to visible (checked)
            colList: colList,
            updateCallback: () => {
                this.updateTableColumns();
            },
        };
    }

    @Bind
    updateTableColumns() {
        this.orgsLists?.updateColumns();
    }

    async updateDate() {
        const [_, error] = await S25Util.Maybe(
            PricingService.putPricingBillingDate(+this.itemId, this.datePickerBean, this.evBillId),
        );
        if (error) return S25Util.showError(error);
        return this.optRefresh();
    }

    disableDeleteSet(evBillId: number) {
        if (evBillId === this.evBillId) {
            this.setDeleteDisabled = true;
            this.cd.detectChanges();
        }
    }

    togglePricingSet(set: Invoice) {
        if (!this.paymentsMode) return;

        this.modelBean.chosenPricingSet = set;
        this.modelBean.selectPricingSet();
    }

    async toggleMode(event: Event) {
        TelemetryService.send("EventDetails", "PaymentMode");
        event.preventDefault();
        await this.toggleModal.open();
    }

    @Bind
    async onCreateStandardInvoice() {
        if (this.standardInvoiceValidated() || !this.promptForInvoiceConversion) {
            this.createStandardInvoice() &&
                this.promptForInvoiceConversion &&
                (await PricingService.postStandardInvoice(this.eventPricingData));
            this.paymentsMode = !this.paymentsMode;
            PreferenceService.setPreference("pricingMode", this.paymentsMode ? "payment" : "standard", "U");
            this.optBean.combineRelatedEvents = false;
            this.modelBean.chosenPricingSet = this.paymentsMode
                ? this.modelBean.invoiceSummary
                : this.modelBean.defaultPricingSet;
            this.sidebarOpen.set(true);
            await this.toggleModal.close();
            this.modelBean.selectPricingSet();
        } else {
            this.showStandardInvoiceErrors.set(true);
        }
    }

    pivotSidebar() {
        this.sidebarOpen.update((prev) => !prev);
        const icon = this.elementRef.nativeElement.querySelector(".sidebar-menu svg");

        if (this.sidebarOpen()) {
            this.renderer.removeClass(icon, "rotate");
        } else {
            this.renderer.addClass(icon, "rotate");
        }
    }

    async toggleLockInvoice(locked: boolean) {
        const lockWrapper = this.elementRef.nativeElement.querySelector(".invoice-lock-wrapper");
        lockWrapper && S25LoadingApi.init(lockWrapper);
        const [_, error] = await S25Util.Maybe(PricingService.putBillingCustom({ completed: locked }, this.evBillId));
        lockWrapper && S25LoadingApi.destroy(lockWrapper);
        if (error) return S25Util.showError(error);
        return this.refresh();
    }
}
